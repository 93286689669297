// UserIdentification.js
import React, { useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import posthog from "posthog-js";

const PostHogUserIdentification = () => {
  const { isLoaded, isSignedIn, user } = useUser();

  useEffect(() => {
    if (isLoaded && isSignedIn && user) {
      // Identify the user in PostHog
      posthog.identify(user.id, {
        email: user.primaryEmailAddress.emailAddress,
        name: `${user.firstName} ${user.lastName}`,
        // Add any other properties you want to track
      });
    } else if (isLoaded && !isSignedIn) {
      // User is signed out, reset PostHog identity
      posthog.reset();
    }
  }, [isLoaded, isSignedIn, user]);

  return null; // This component doesn't render anything
};

export default PostHogUserIdentification;
