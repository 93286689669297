/**
 * Processes the estimate data to collect descriptions for modules, assemblies, ariaItems, and customItems.
 *
 * @param {Object} estimate - The estimate data containing modules, assemblies, ariaItems, and customItems.
 * @returns {Object} An object containing arrays of items with descriptions and sources.
 */
export function quoteHelper(estimate) {
  const modules = [];
  const assemblies = [];
  const ariaItems = [];
  const customItems = [];

  /**
   * Helper function to prepend the quantity in parentheses.
   *
   * @param {string} description - The original description of the item.
   * @param {number} quantity - The quantity of the item.
   * @returns {string} - The modified description with quantity prefix.
   */
  const prependQuantity = (description, quantity) => {
    return `(${quantity}) ${description}`;
  };

  // Process Modules
  if (estimate.modules) {
    estimate.modules.forEach((module) => {
      const {
        id,
        description,
        moduleName,
        selectedOptions,
        container,
        rainProtection,
        permitted,
        quantity = 1, // Default to 1 if quantity is not provided
      } = module;

      // Add the module description with quantity
      modules.push({
        id,
        description: prependQuantity(
          description || moduleName || "Module",
          quantity
        ),
        source: "Module",
        visible: true,
      });

if (container) {
  modules.push({
    id: `${id}-container`,
    description: "All Cutting & Welding per Plans", // No quantity prefix
    source: "Module Extra",
    visible: true,
  });
}

if (rainProtection) {
  modules.push({
    id: `${id}-rainProtection`,
    description:
      "8 mil Plastic Heat Shrink Wrap for Protection During Shipping and Quality Assurance", // No quantity prefix
    source: "Module Extra",
    visible: true,
  });
}

if (permitted) {
  modules.push({
    id: `${id}-permitted`,
    description: "Permitting", // No quantity prefix
    source: "Module Extra",
    visible: true,
  });
}


      // Add selected options as separate items without quantity
      if (selectedOptions) {
        Object.values(selectedOptions).forEach((option) => {
          const optionDescription = `${option.category}: ${option.moduleOptionDescription}`;
          modules.push({
            id: `${id}-${option.id}`,
            description: optionDescription, // No quantity prefix
            source: "Module Option",
            visible: true,
          });
        });
      }
    });
  }

  // Process Assemblies
  if (estimate.assemblies) {
    estimate.assemblies.forEach((assembly) => {
      const { id, assemblyName, quantity = 1 } = assembly;
      assemblies.push({
        id,
        description: prependQuantity(assemblyName || "Assembly", quantity),
        source: "Assembly",
        visible: true,
      });

      // Optionally, process assembly items if needed
      // You can add similar logic here if you want to process items within assemblies
    });
  }

  // Process Aria Items
  if (estimate.ariaItems) {
    estimate.ariaItems.forEach((ariaItem) => {
      const { id, itemDescription, quantity = 1 } = ariaItem;
      ariaItems.push({
        id,
        description: prependQuantity(itemDescription || "Aria Item", quantity),
        source: "Aria Item",
        visible: true,
      });
    });
  }

  // Process Custom Items
  if (estimate.customItems) {
    estimate.customItems.forEach((customItem) => {
      const { id, itemDescription, quantity = 1 } = customItem;
      customItems.push({
        id,
        description: prependQuantity(
          customItem.itemDescription || "Custom Item",
          quantity
        ),
        source: "Custom Item",
        visible: true,
      });
    });
  }

  return {
    modules,
    assemblies,
    ariaItems,
    customItems,
  };
}
