export const getPercent = (left, right) => {
  if (left && right) {
    return left / right;
  }
  return 0;
};

export const formatPercent = (x) => parseFloat(x * 100).toFixed(0) + "%";

export const formatMoney = (amount) => {
  const number = parseFloat(amount);
  if (isNaN(number)) {
    return "$0.00";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const formatDate = (date, includeTime = false) => {
  // First, check if the date is valid
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) {
    // dateObj.getTime() will be NaN if dateObj is not a valid date
    return "";
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...(includeTime && {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }),
  };

  return new Intl.DateTimeFormat("en-US", options).format(dateObj);
};


export const parsePrice = (priceStr) => {
  // Check if priceStr is undefined or null and return 0 if so
  if (priceStr === undefined || priceStr === null) return 0;

  // Return the number directly if input is already a number
  if (typeof priceStr === "number") return priceStr;

  // Parse string to extract numeric value
  const numericValue = parseFloat(priceStr.replace(/[^0-9.-]+/g, ""));
  return isNaN(numericValue) ? 0 : numericValue; // Return 0 if the result is NaN
};





