export const calculateWallSF = (
  structureWidth,
  structureHeight,
  structureLength,
  longWallRemoved,
  endWallRemoved
) => {
  let wallSF =
    structureWidth * structureHeight * 2 +
    structureLength * structureHeight * 2;

  if (longWallRemoved) {
    wallSF -= structureLength * structureHeight;
  }

  if (endWallRemoved) {
    wallSF -= structureWidth * structureHeight;
  }

  return wallSF;
};

export const calculateFloorSF = (structureWidth, structureLength) => {
  return structureWidth * structureLength;
};

export const calculateCeilingSF = (structureWidth, structureLength) => {
  return structureWidth * structureLength;
};

export const calculateFramedLF = (
  structureLength,
  structureWidth,
  numberOfPartitionWalls,
  longWallRemoved,
  endWallRemoved
) => {
  let framedLF = structureLength * 3 + structureWidth * 2;
  framedLF += numberOfPartitionWalls * structureWidth;

  if (longWallRemoved) {
    framedLF -= structureLength;
  }

  if (endWallRemoved) {
    framedLF -= structureWidth;
  }

  return framedLF;
};

// Updated calculateCovebaseLF function
export const calculateCovebaseLF = (
  structureLength,
  structureWidth,
  numberOfPartitionWalls,
  longWallRemoved,
  endWallRemoved
) => {
  let covebaseLF = structureLength * 2 + structureWidth * 2;
  covebaseLF += numberOfPartitionWalls * structureWidth;

  if (longWallRemoved) {
    covebaseLF -= structureLength;
  }

  if (endWallRemoved) {
    covebaseLF -= structureWidth;
  }

  // Subtract one structure length as per requirement
  covebaseLF -= structureLength;

  return covebaseLF;
};

export const calculateLabor = (laborCostPerSF, sf) => {
  return laborCostPerSF * sf;
};
