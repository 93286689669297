import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Row,
  Col,
  message,
  InputNumber,
  Table,
  Modal,
  Badge,
} from "antd";
import { PlusOutlined, DeleteOutlined, StopOutlined } from "@ant-design/icons";
import { useAssemblies, useEstimates } from "../../../Contexts/useSpecificData";
import { updateById } from "../../../Functions/updateById";
import { formatMoney } from "../../../Formatters/helpers";
import { COLORS, TABLES } from "../../../constants";
import PriceTag from "../../../Styled/PriceTag";
import { calculateSaleAmount } from "../InternalQuote/calculateSaleAmount";

const EstimateAssemblies = ({ estimate }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [estimateAssemblies, setEstimateAssemblies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [quantities, setQuantities] = useState({});

  const { data: assemblies, loading: assembliesLoading } = useAssemblies();
  const { refresh: refetchEstimates } = useEstimates();

  useEffect(() => {
    if (estimate.assemblies) {
      setEstimateAssemblies(estimate.assemblies);
    }
  }, [estimate.assemblies]);

  const availableAssemblies = assemblies.filter(
    (assembly) =>
      !estimateAssemblies.find((item) => item.assemblyId === assembly.id)
  );

  const categories = [
    ...new Set(assemblies.map((assembly) => assembly.category)),
  ];

  const handleAdd = (assemblyId) => {
    const assembly = assemblies.find((item) => item.id === assemblyId);
    const newAssembly = {
      ...assembly,
      quantity: quantities[assemblyId],
      assemblyId: assembly.id,
    };
    const updatedAssemblies = [...estimateAssemblies, newAssembly];
    setEstimateAssemblies(updatedAssemblies);
    message.success(`Added ${assembly.assemblyName}`);
    handleSave(updatedAssemblies, false);
    setQuantities({ ...quantities, [assemblyId]: null });
  };

  const handleQuantityChange = (value, assemblyId) => {
    const updatedAssemblies = estimateAssemblies.map((item) =>
      item.assemblyId === assemblyId ? { ...item, quantity: value } : item
    );
    setEstimateAssemblies(updatedAssemblies);
    handleSave(updatedAssemblies, false);
  };

  const handleDelete = (assemblyId) => {
    const assembly = estimateAssemblies.find(
      (item) => item.assemblyId === assemblyId
    );
    Modal.confirm({
      title: `Are you sure you want to delete ${assembly.assemblyName}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        const updatedAssemblies = estimateAssemblies.filter(
          (item) => item.assemblyId !== assemblyId
        );
        setEstimateAssemblies(updatedAssemblies);
        handleSave(updatedAssemblies);
        message.success(`Removed ${assembly.assemblyName}`);
      },
    });
  };

  const handleSave = async (
    updatedAssemblies = estimateAssemblies,
    showMessage = true
  ) => {
    setLoading(true);
    try {
      await updateById(
        TABLES.ESTIMATES,
        { assemblies: updatedAssemblies },
        estimate.id
      );
      setLoading(false);
      if (showMessage) {
        message.success("Assemblies saved successfully!");
      }
      refetchEstimates(); // Refresh the estimates data
    } catch (error) {
      setLoading(false);
      message.error("Failed to save assemblies: " + error.message);
    }
  };

  const filteredAssemblies = availableAssemblies.filter((assembly) => {
    const matchesCategory =
      !searchTerm ||
      assembly.category.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSearchTerm =
      !searchTerm ||
      assembly.assemblyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      assembly.description.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearchTerm;
  });

  const modalColumns = [
    {
      title: "Assembly Name",
      dataIndex: "assemblyName",
      key: "assemblyName",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: categories.map((category) => ({
        text: category,
        value: category,
      })),
      onFilter: (value, record) => record.category === value,
    },
    {
      title: "Material Cost",
      dataIndex: "assemblyItems",
      key: "materialCost",
      render: (items) => {
        const materialCost = items
          ? items.reduce(
              (total, item) => total + (item.price || 0) * (item.quantity || 0),
              0
            )
          : 0;
        return materialCost > 0 ? (
          <PriceTag amount={materialCost} />
        ) : (
          <StopOutlined style={{ color: "red", fontSize: 16 }} />
        );
      },
    },
    {
      title: "Labor Cost",
      dataIndex: "laborPrice",
      key: "laborPriceValue",
      render: (text) =>
        text > 0 ? (
          <PriceTag amount={text} />
        ) : (
          <StopOutlined style={{ color: "red", fontSize: 16 }} />
        ),
    },

    {
      title: "Total Cost",
      key: "totalCost",
      render: (text, record) => {
        const materialCost = record.assemblyItems
          ? record.assemblyItems.reduce(
              (total, item) => total + (item.price || 0) * (item.quantity || 0),
              0
            )
          : 0;
        const totalCost = (record.laborPrice || 0) + materialCost;
        return totalCost > 0 ? (
          <PriceTag amount={totalCost} bold />
        ) : (
          <StopOutlined style={{ color: "red", fontSize: 16 }} />
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          value={quantities[record.id]}
          onChange={(value) =>
            setQuantities({ ...quantities, [record.id]: value })
          }
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleAdd(record.id)}
          disabled={!quantities[record.id]}
        >
          Add
        </Button>
      ),
    },
  ];

  // Calculate Total Costs
  const totalMaterialCost = estimateAssemblies.reduce((total, item) => {
    const materialCost = item.assemblyItems
      ? item.assemblyItems.reduce(
          (sum, ai) => sum + (ai.price || 0) * (ai.quantity || 0),
          0
        )
      : 0;
    return total + materialCost * item.quantity;
  }, 0);

  const totalLaborCost = estimateAssemblies.reduce((total, item) => {
    return total + (item.laborPrice || 0) * item.quantity;
  }, 0);

  const totalCost = totalMaterialCost + totalLaborCost;

  const getCategoryData = (category) => {
    return estimateAssemblies
      .filter((item) => item.category === category)
      .map((assembly) => {
        const materialCost = assembly.assemblyItems
          ? assembly.assemblyItems.reduce(
              (total, item) => total + (item.price || 0) * (item.quantity || 0),
              0
            )
          : 0;
        const laborCost = (assembly.laborPrice || 0) * assembly.quantity;
        const totalCost = materialCost + laborCost;
        return {
          key: assembly.assemblyId,
          assemblyName: assembly.assemblyName,
          description: assembly.description,
          quantity: assembly.quantity,
          laborPrice: assembly.laborPrice,
          materialCost,
          laborCost,
          totalCost,
          assemblyItems: assembly.assemblyItems || [],
        };
      });
  };

  const expandedRowRender = (record) => {
    const itemMaterialCost = record.assemblyItems
      ? record.assemblyItems.reduce(
          (total, item) => total + (item.price || 0) * (item.quantity || 0),
          0
        )
      : 0;

    const itemLaborCost = record.laborPrice * record.quantity || 0;
    const itemTotalCost = itemMaterialCost + itemLaborCost;

    const columns = [
      {
        title: "Item Number",
        dataIndex: "itemNumber",
        key: "itemNumber",
        className: "expanded-row",
      },
      {
        title: "Item Description",
        dataIndex: "itemDescription",
        key: "itemDescription",
        className: "expanded-row",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        className: "expanded-row",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        className: "expanded-row",
        render: (text) =>
          text > 0 ? (
            <PriceTag amount={text} />
          ) : (
            <StopOutlined style={{ color: "red", fontSize: 16 }} />
          ),
      },
      {
        title: "Total",
        key: "total",
        className: "expanded-row",
        render: (text, item) => {
          const total = (item.price || 0) * (item.quantity || 0);
          return total > 0 ? (
            <PriceTag amount={total} bold />
          ) : (
            <StopOutlined style={{ color: "red", fontSize: 16 }} />
          );
        },
      },
    ];

    return (
      <div style={{ marginBottom: 16 }}>
        <Table
          columns={columns}
          dataSource={record.assemblyItems}
          pagination={false}
          size="small"
          rowKey="id"
        />
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            marginTop: "8px",
          }}
        >
          <PriceTag amount={itemMaterialCost} />
          <PriceTag amount={itemLaborCost} />
          <PriceTag amount={itemTotalCost} bold />
        </div> */}
      </div>
    );
  };

  const categoryTables = categories.map((category) => {
    const categoryAssemblies = getCategoryData(category);
    if (categoryAssemblies.length === 0) {
      return null;
    }

    const categoryMaterialCost = categoryAssemblies.reduce(
      (total, item) => total + item.materialCost,
      0
    );

    const categoryLaborCost = categoryAssemblies.reduce(
      (total, item) => total + item.laborCost,
      0
    );

    const categoryTotalCost = categoryMaterialCost + categoryLaborCost;

    const columns = [
      {
        title: "Assembly Name",
        dataIndex: "assemblyName",
        key: "assemblyName",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Material Cost",
        dataIndex: "materialCost",
        key: "materialCost",
        render: (text) =>
          text > 0 ? (
            <PriceTag amount={text} />
          ) : (
            <StopOutlined style={{ color: "red", fontSize: 16 }} />
          ),
      },
      {
        title: "Labor Cost",
        dataIndex: "laborPrice",
        key: "laborPrice",
        render: (text, record) =>
          text > 0 ? (
            <PriceTag amount={record.laborPrice * record.quantity} />
          ) : (
            <StopOutlined style={{ color: "red", fontSize: 16 }} />
          ),
      },

      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record) => (
          <InputNumber
            min={1}
            value={record.quantity}
            onChange={(value) => handleQuantityChange(value, record.key)}
          />
        ),
      },
      {
        title: "Total Cost",
        dataIndex: "totalCost",
        key: "totalCost",
        render: (text) =>
          text > 0 ? (
            <PriceTag amount={text} bold />
          ) : (
            <StopOutlined style={{ color: "red", fontSize: 16 }} />
          ),
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.key)}
          >
            Delete
          </Button>
        ),
      },
    ];

    return (
      <div key={category} style={{ marginBottom: 24 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#fafafa",
            padding: "8px 16px",
            borderRadius: 4,
          }}
        >
          <Badge
            // color={COLORS.PRIMARY}
            count={categoryAssemblies.length}
            style={{ marginRight: 8 }}
          />
          <span style={{ fontSize: 16, fontWeight: 600 }}>{category}</span>
          <div
            style={{
              marginLeft: "auto",
              fontWeight: 600,
              display: "flex",
              gap: "8px",
            }}
          >
            {/* <PriceTag amount={categoryMaterialCost} />
            <PriceTag amount={categoryLaborCost} /> */}
            <PriceTag label={`Total ${category}`} amount={categoryTotalCost} />
          </div>
        </div>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => record.assemblyItems.length > 0,
          }}
          dataSource={categoryAssemblies}
          pagination={false}
          size="small"
        />
      </div>
    );
  });

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span style={{ fontSize: 18, fontWeight: 500 }}>
              Total Assemblies Cost:
            </span>
            <PriceTag label="Material" amount={totalMaterialCost} bold large />
            <PriceTag label="Labor" amount={totalLaborCost} bold large />
            <PriceTag label="Total" amount={totalCost} bold large />
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setVisible(true)}
          >
            Add Assembly
          </Button>
        </Col>
      </Row>

      {categoryTables}

      <Modal
        title="Select Assembly"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width="80%"
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
          <Col span={24}>
            <Input
              placeholder="Search Assemblies"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>

        <Table
          columns={modalColumns}
          size="small"
          dataSource={filteredAssemblies}
          rowKey="id"
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          loading={assembliesLoading}
        />
      </Modal>
    </div>
  );
};

export default EstimateAssemblies;
