import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import "./App.css";
import { ClerkProvider } from "@clerk/clerk-react";
import AppContent from "./AppContent";
import { antdTheme } from "./AntdTheme";
import { AriaDataProvider } from "./Contexts/AriaDataContext";
import { UsersDataProvider } from "./Contexts/usersContext";
import './dayjsSetup'
import { MediaQueryProvider } from "./Contexts/MediaQueryContext";
import { PostHogProvider } from "posthog-js/react";
import useTitleModifier from "./Mutations/useTitleModifier";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};


function App() {

  useTitleModifier();

  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <ClerkProvider
        publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
        appearance={{ baseTheme: [] }}
      >
        <ConfigProvider theme={antdTheme}>
          <UsersDataProvider>
            <AriaDataProvider>
              <Router>
                <MediaQueryProvider>
                  <AppContent />
                </MediaQueryProvider>
              </Router>
            </AriaDataProvider>
          </UsersDataProvider>
        </ConfigProvider>
      </ClerkProvider>
    </PostHogProvider>
  );
}

export default App;
