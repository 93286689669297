// Customers.js
import React, { useState } from "react";
import { Table, Row, Col, Button } from "antd";
import {
  useCustomers,
  useProjects,
  useEstimates,
} from "../../Contexts/useSpecificData";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import AddCustomerModal from "./AddCustomerModal";
import { PlusOutlined } from "@ant-design/icons";

function Customers() {
  const { data: customers, refresh: refreshCustomers } = useCustomers();
  const { data: projects } = useProjects();
  const { data: estimates } = useEstimates();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleAddSuccess = () => {
    refreshCustomers();
    setIsModalVisible(false);
    setSelectedCustomer(null);
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (text, record) => (
        <a
          onClick={() => {
            setSelectedCustomer(record);
            setIsModalVisible(true);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Contact Name",
      key: "contactName",
      render: (text, record) =>
        `${record.contactFirstName || ""} ${record.contactLastName || ""}`,
    },
    {
      title: "Email",
      dataIndex: "contactEmail",
      key: "contactEmail",
    },
    {
      title: "Phone Number",
      dataIndex: "contactPhoneNumber",
      key: "contactPhoneNumber",
    },
    {
      title: "Estimates",
      key: "estimates",
      dataIndex: "estimatesCount",
      render: (text, record) => record.estimatesCount || 0,
    },
    {
      title: "Projects",
      key: "projects",
      dataIndex: "projectsCount",
      render: (text, record) => record.projectsCount || 0,
    },
  ];

  // Sort customers by company name A-Z
  const sortedCustomers = customers.sort((a, b) =>
    (a.companyName || "").localeCompare(b.companyName || "")
  );

  // Filter customers based on search term
  const filteredCustomers = sortedCustomers.filter((customer) => {
    const fullName = `${customer.contactFirstName || ""} ${
      customer.contactLastName || ""
    }`.toLowerCase();
    const term = searchTerm.toLowerCase();
    return (
      (customer.companyName || "").toLowerCase().includes(term) ||
      fullName.includes(term) ||
      (customer.contactEmail || "").toLowerCase().includes(term) ||
      (customer.contactPhoneNumber || "").toLowerCase().includes(term)
    );
  });

  // Prepare the data source for the table
  const dataSource = filteredCustomers.map((customer) => {
    const estimatesCount = estimates
      ? estimates.filter((estimate) => estimate.customerId === customer.id)
          .length
      : 0;

    const projectsCount = projects
      ? projects.filter(
          (project) => project.companyName === customer.companyName
        ).length
      : 0;

    return {
      key: customer.id,
      ...customer,
      estimatesCount,
      projectsCount,
    };
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <HeaderText text={"Customers"} />
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <AddCustomerModal onAddSuccess={handleAddSuccess}>
          <Button type="primary" icon={<PlusOutlined />}>
            Add Customer
          </Button>
        </AddCustomerModal>
      </Col>
      <Col span={24}>
        <SearchInput
          placeholder="Search Customers"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  resultsLength={filteredCustomers.length}
        />
      </Col>
      <Col span={24}>
        <Table columns={columns} dataSource={dataSource} />
      </Col>

      {/* AddCustomerModal for Editing */}
      <AddCustomerModal
        customer={selectedCustomer}
        visible={isModalVisible}
        onAddSuccess={handleAddSuccess}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedCustomer(null);
        }}
      />
    </Row>
  );
}

export default Customers;
