import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import { TABLES } from "../constants"; // Adjust the import path as necessary

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/aria/getItems";

const AriaDataContext = createContext();

const useAriaData = () => useContext(AriaDataContext);

const AriaDataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});
  const socketRef = useRef(null); // Ref to store the WebSocket instance

  // Fetch data for a specific table
  const fetchData = useCallback(async (tableName, showLoading = true) => {
    if (showLoading) {
      setLoading((prevLoading) => ({ ...prevLoading, [tableName]: true }));
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/${tableName}`);

      const responseData = response.data || [];

      setData((prevData) => ({
        ...prevData,
        [tableName]: responseData,
      }));

      if (showLoading) {
        setLoading((prevLoading) => ({ ...prevLoading, [tableName]: false }));
      }
    } catch (error) {
      setError((prevError) => ({ ...prevError, [tableName]: error }));
      if (showLoading) {
        setLoading((prevLoading) => ({ ...prevLoading, [tableName]: false }));
      }
    }
  }, []);

  // Refresh data for a specific table or all tables
  const refreshData = useCallback(
    (tableName, showLoading = true) => {
      if (tableName) {
        fetchData(tableName, showLoading);
      } else {
        Object.values(TABLES).forEach((table) => fetchData(table, showLoading));
      }
    },
    [fetchData]
  );

  useEffect(() => {
    refreshData(); // Uses default showLoading = true
  }, [refreshData]);

  // Set up WebSocket connection with heartbeat and reconnection logic
  useEffect(() => {
    const websocketEndpoint = process.env.REACT_APP_WEBSOCKET_ENDPOINT;

    if (!websocketEndpoint) {
      console.error("WebSocket endpoint is not defined");
      return;
    }

    let heartbeatInterval;
    let reconnectTimeout;
    let isManualClose = false;

    const connect = () => {
      socketRef.current = new WebSocket(websocketEndpoint);

      socketRef.current.onopen = () => {
        console.log("WebSocket connection established");
        // Start heartbeat
        heartbeat();
      };

      socketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.action === "refresh") {
          console.log(`Received refresh action for table: ${message.table}`);
          refreshData(message.table, false); // Silent refresh without spinners
        } else if (message.type === "pong") {
          console.log("Received pong");
        }
      };

      socketRef.current.onclose = (event) => {
        console.log(
          `WebSocket connection closed: Code ${event.code}, Reason: ${event.reason}`
        );
        clearInterval(heartbeatInterval);
        if (!isManualClose) {
          // Attempt to reconnect after a delay
          reconnectTimeout = setTimeout(() => {
            console.log("Reconnecting WebSocket...");
            connect();
          }, 5000); // Reconnect after 5 seconds
        }
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        socketRef.current.close();
      };
    };

    const heartbeat = () => {
      clearInterval(heartbeatInterval);
      heartbeatInterval = setInterval(() => {
        if (
          socketRef.current &&
          socketRef.current.readyState === WebSocket.OPEN
        ) {
          socketRef.current.send(JSON.stringify({ action: "ping" }));
          console.log("Sent ping");
        }
      }, 300000); // Send ping every 5 minutes (300,000 milliseconds)
    };

    connect();

    // Clean up function
    return () => {
      isManualClose = true;
      clearInterval(heartbeatInterval);
      clearTimeout(reconnectTimeout);
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [refreshData]);

  return (
    <AriaDataContext.Provider value={{ data, loading, error, fetchData }}>
      {children}
    </AriaDataContext.Provider>
  );
};

export { useAriaData, AriaDataProvider };
