import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/aria/updateById";

/**
 * Adds or updates an item in the specified table.
 * @param {string} tableName The name of the table.
 * @param {Object} item The item details to be added or updated.
 * @param {string} [id] The ID of the item if it is an update operation.
 * @returns {Promise<any>}
 */
export async function updateById(tableName, item, id) {
  console.log("table name is", tableName);
  console.log("item is", item);
  console.log("id is", id);

  const url = `${API_BASE_URL}/${tableName}/${id}`;

  try {
    const response = await axios.put(url, item);
    return response.data;
  } catch (error) {
    throw new Error(
      `Error ${id ? "updating" : "adding"} item in table ${tableName}: ${
        error.response ? error.response.statusText : error.message
      }`
    );
  }
}
