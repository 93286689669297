// useTitleModifier.js
import { useEffect } from "react";

function useTitleModifier() {
  useEffect(() => {
    const isDev = process.env.REACT_APP_STAGE === "_dev";
    if (!isDev) return;

    const titleElement =
      document.querySelector("title") || document.createElement("title");
    if (!titleElement.parentNode) {
      document.head.appendChild(titleElement);
    }

    const observer = new MutationObserver(() => {
      if (document.title && !document.title.startsWith("[DEV] ")) {
        document.title = `[DEV] ${document.title}`;
      }
    });

    observer.observe(titleElement, { childList: true });

    // Initial check
    if (document.title && !document.title.startsWith("[DEV] ")) {
      document.title = `[DEV] ${document.title}`;
    }

    return () => {
      observer.disconnect();
    };
  }, []);
}

export default useTitleModifier;
