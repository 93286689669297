function getNextPurchaseOrderNumber(purchaseOrders) {
  // Define a regular expression to match the "A-1234" format
  const regex = /^A-(\d{4})$/;

  // Initialize a variable to store the highest number found
  let highestNumber = 0;

  // Iterate through the purchase orders
  purchaseOrders.forEach((po) => {
    const match = po.poNumber.match(regex);
    if (match) {
      const number = parseInt(match[1], 10); // Get the numeric part and convert to an integer
      if (number > highestNumber) {
        highestNumber = number; // Update the highest number if the current one is greater
      }
    }
  });

  // Increment the highest number by 1
  const nextNumber = highestNumber + 1;

  // Format the new number with leading zeros if necessary and return the new purchase order number
  return `A-${nextNumber.toString().padStart(4, "0")}`;
}

// Export the function so it can be used in other files
export default getNextPurchaseOrderNumber;
