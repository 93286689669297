// InvoicesDetail.jsx

import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Badge,
  Card,
  Empty,
  Tag,
  Statistic,
  Divider,
  Popconfirm,
  message,
  Descriptions,
} from "antd";
import {
  FilePdfFilled,
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { updateById } from "../../Functions/updateById";
import { getVendorName } from "../../Formatters/getVendorName";
import { getProjectName } from "../../Formatters/getProjectName";
import { getTotalFromPurchaseOrder } from "../../utils/purchaseOrderUtils";
import HeaderText from "../../Styled/HeaderText";
import { formatMoney } from "../../Formatters/helpers";
import { Loader } from "../../Styled/Loader";
import { getFirstNameFromEmail } from "../../Formatters/getNameFromEmail";
import InvoiceDrawer from "./InvoiceDrawer";
import { useParams, useNavigate } from "react-router-dom";
import AddInvoiceModal from "./AddInvoiceModal";
import { TABLES } from "../../constants";

const InvoicesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();

  const [poDetails, setPoDetails] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [specificInvoiceId, setSpecificInvoiceId] = useState(null);

  useEffect(() => {
    if (purchaseOrders) {
      const po = purchaseOrders.find((order) => order.id === id);
      if (po) {
        const updatedPO = { ...po, invoices: po.invoices || [] };
        setPoDetails(updatedPO);
      }
    }
  }, [purchaseOrders, id, navigate]);

  if (poLoading || !poDetails) {
    return <Loader />;
  }

  const purchaseOrderTotal = getTotalFromPurchaseOrder(poDetails);
  const invoiceTotal = poDetails.invoices.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );
  const differential = purchaseOrderTotal - invoiceTotal;

  const handleDeleteInvoice = async (invoiceId) => {
    const updatedInvoices = poDetails.invoices.filter(
      (invoice) => invoice.id !== invoiceId
    );
    const updatedPO = { ...poDetails, invoices: updatedInvoices };

    try {
      await updateById(TABLES.PURCHASE_ORDERS, updatedPO, poDetails.id);
      message.success("Invoice deleted successfully!");
      setPoDetails(updatedPO);
    } catch (error) {
      message.error("Error deleting invoice.");
    }
  };

  return (
    <div style={{ padding: "24px", background: "#fff", minHeight: "100vh" }}>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <HeaderText text={`Invoice Details - ${poDetails.poNumber}`} />
        </Col>

        <Col span={6}>
          <Statistic
            title="Purchase Order Total"
            value={formatMoney(purchaseOrderTotal)}
            precision={2}
            valueStyle={{ color: "green" }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Invoice Total"
            value={formatMoney(invoiceTotal)}
            precision={2}
            valueStyle={{
              color: invoiceTotal > purchaseOrderTotal ? "red" : "green",
            }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Differential"
            value={formatMoney(Math.abs(differential))}
            precision={2}
            valueStyle={{
              color:
                differential > 0 ? "green" : differential < 0 ? "red" : "black",
            }}
            prefix={
              differential > 0 ? (
                <ArrowUpOutlined />
              ) : differential < 0 ? (
                <ArrowDownOutlined />
              ) : null
            }
          />
        </Col>

        <Col span={24}>
          <Descriptions column={4} bordered size="small" layout="horizontal">
            <Descriptions.Item label="Vendor">
              {getVendorName(poDetails.vendor, vendors)}
            </Descriptions.Item>
            <Descriptions.Item label="Project">
              {getProjectName(poDetails.projectNumber, projects)}
            </Descriptions.Item>
            <Descriptions.Item label="Ordered Date">
              {new Date(poDetails.orderedDate).toLocaleDateString()}
            </Descriptions.Item>
            <Descriptions.Item label="Buyer">
              {getFirstNameFromEmail(poDetails.buyer)}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Divider style={{ margin: "24px 0" }} />

      <Row gutter={[16, 16]}>
        <Col
          span={24}
          style={{
            marginTop: 16,
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Badge offset={[8, 0]} count={poDetails?.invoices?.length || 0}>
            <HeaderText text="Invoices" />
          </Badge>

          <Button
            type="primary"
            icon={<FilePdfFilled />}
            onClick={() => setIsModalVisible(true)}
          >
            Add Invoice
          </Button>
        </Col>

        <Divider style={{ margin: "8px 0" }} />

        {poDetails.invoices && poDetails.invoices.length > 0 ? (
          <>
            {poDetails.invoices.map((invoice, index) => (
              <Col span={6} key={invoice.id}>
                <Card
                  className="box-shadow"
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Badge style={{ marginRight: 4 }} count={index + 1} />
                      Invoice {invoice.invoiceNumber}
                    </div>
                  }
                  actions={[
                    <Popconfirm
                      title="Are you sure you want to delete this invoice?"
                      onConfirm={() => handleDeleteInvoice(invoice.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger icon={<DeleteOutlined />}>
                        Delete
                      </Button>
                    </Popconfirm>,
                    <Button
                      type="primary"
                      icon={<FilePdfFilled />}
                      onClick={() => {
                        setSpecificInvoiceId(invoice.id);
                        setDrawerVisible(true);
                      }}
                      style={{ marginRight: 8 }}
                    >
                      Open PDF
                    </Button>,
                  ]}
                >
                  <p>
                    <strong>Due Date:</strong>{" "}
                    {new Date(invoice.dueDate).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Amount:</strong>{" "}
                    <Tag color="green">{formatMoney(invoice.amount)}</Tag>
                  </p>
                </Card>
              </Col>
            ))}
          </>
        ) : (
          <Col span={24} style={{ marginTop: 24 }}>
            <Empty description="No invoices uploaded yet." />
          </Col>
        )}
      </Row>

      <InvoiceDrawer
        purchaseOrder={poDetails}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        specificInvoiceId={specificInvoiceId}
      />

      {/* Use the new AddInvoiceModal component */}
      <AddInvoiceModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        purchaseOrder={poDetails}
        onInvoiceAdded={(updatedPO) => {
          setPoDetails(updatedPO);
        }}
      />
    </div>
  );
};

export default InvoicesDetail;
