import React, { useMemo } from "react";
import {
  Button,
  Row,
  Col,
  Tooltip,
  Modal,
  message,
  Tag,
  Card,
  Space,
  Table,
  Descriptions,
  Badge,
  Divider,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  StopOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import AddModuleModal from "./AddModuleModal";
import { updateById } from "../../../Functions/updateById";
import { TABLES } from "../../../constants";
import PriceTag from "../../../Styled/PriceTag";

function EstimateModules({ estimate, onAddSuccess }) {

  // Handler for deleting a module
  const handleDelete = (moduleId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this module?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const updatedModules = estimate.modules.filter(
            (module) => module.moduleId !== moduleId
          );

          const updatedEstimate = { modules: updatedModules };

          await updateById(TABLES.ESTIMATES, updatedEstimate, estimate.id);

          message.success("Module deleted successfully!");
          onAddSuccess(); // Refresh modules after deletion
        } catch (error) {
          message.error("Failed to delete module: " + error.message);
        }
      },
    });
  };

  // Function to calculate total costs per module
  const getTotalCost = (module) => {
    const categoryCosts = module.categoryCosts || {};
    const rainProtection = module.rainProtection || {};
    const permitted = module.permitted || {};
    const container = module.container || {};

    let totalMaterial = 0;
    let totalLabor = 0;

    // Aggregate category costs
    Object.keys(categoryCosts).forEach((key) => {
      const cost = categoryCosts[key];
      if (cost) {
        totalMaterial += parseFloat(cost.materialCost) || 0;
        totalLabor += parseFloat(cost.laborCost) || 0;
      }
    });

    // Aggregate rain protection costs
    if (rainProtection.material) {
      totalMaterial += parseFloat(rainProtection.material) || 0;
    }
    if (rainProtection.labor) {
      totalLabor += parseFloat(rainProtection.labor) || 0;
    }

    if (container.labor) {
      totalLabor += parseFloat(container.labor) || 0;
    }

    if (container.material) {
      totalMaterial += parseFloat(container.material) || 0;
    }

    // Aggregate permitted costs
    if (permitted.labor) {
      totalLabor += parseFloat(permitted.labor) || 0;
    }


    // Calculate Total Cost
    const total = totalMaterial + totalLabor;

    return {
      material: totalMaterial,
      labor: totalLabor,
      total: total,
    };
  };

  // Calculate total cost of all modules, broken down by category
  const totalCosts = useMemo(() => {
    return (estimate?.modules || []).reduce(
      (totals, module) => {
        const moduleCosts = getTotalCost(module);
        totals.material += moduleCosts.material;
        totals.labor += moduleCosts.labor;
        totals.total += moduleCosts.total;
        return totals;
      },
      { material: 0, labor: 0, total: 0 }
    );
  }, [estimate]);

  return (
    <>
      {/* Header Section: Total Cost and Add Module Button */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: 18, marginRight: 12, fontWeight: 500 }}>
              Total Modules Cost:
            </span>
            <PriceTag
              label="Material"
              amount={totalCosts.material}
              bold
              large
            />
            <PriceTag
              label="Labor"
              amount={totalCosts.labor}
              bold
              large
              style={{ marginLeft: 8 }}
            />
            <PriceTag
              label="Total"
              amount={totalCosts.total}
              bold
              large
              style={{ marginLeft: 8 }}
            />
          </div>
        </Col>
        <Col>
          <Button
            onClick={() => window.open("/estimating/module-options", "_blank")}
            style={{ marginRight: 8 }}
            icon={<SettingOutlined />}
          >
            Module Options
          </Button>

          <AddModuleModal estimate={estimate} onAddSuccess={onAddSuccess}>
            <Button type="primary" icon={<PlusOutlined />}>
              Add Module
            </Button>
          </AddModuleModal>
        </Col>
      </Row>

      {/* Modules List */}
      <div>
        {(estimate?.modules || []).map((record, index) => {
          const {
            moduleId,
            moduleName,
            description,
            container,
            partitionWalls,
            permitted,
            rainProtection,
            structureWidth,
            structureLength,
            structureHeight,
            longWallRemoved,
            endWallRemoved,
            selectedOptions,
            categoryCosts,
          } = record;

          const moduleTotalCost = getTotalCost(record).total;

          return (
            <Card
              key={moduleId}
              title={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Badge count={index + 1} style={{ marginRight: 8 }} />
                    <div style={{ fontWeight: 600 }}>{moduleName}</div>
                    {description && (
                      <div
                        style={{
                          opacity: 0.7,
                          fontStyle: "italic",
                          marginLeft: 8,
                        }}
                      >
                        - {description}
                      </div>
                    )}
                    <PriceTag
                      label="Total"

                      medium
                      style={{ marginLeft: 8 }}
                      amount={moduleTotalCost}
                    />
                  </div>

                  <div style={{ marginTop: 8 }}>
                    <Space size="middle">
                      {/* Edit Module Button */}
                      <AddModuleModal
                        estimate={estimate}
                        module={record}
                        onAddSuccess={onAddSuccess}
                      >
                        <Tooltip title="Edit Module">
                          <Button type="link" icon={<EditOutlined />} />
                        </Tooltip>
                      </AddModuleModal>

                      {/* Delete Module Button */}
                      <Tooltip title="Delete Module">
                        <Button
                          type="link"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => handleDelete(moduleId)}
                        />
                      </Tooltip>
                    </Space>
                  </div>
                </div>
              }
              style={{ marginBottom: 16 }}
            >
              <Descriptions bordered size="small" column={2}>
                <Descriptions.Item label="Container" span={2}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Badge
                      style={{ marginRight: 6 }}
                      count={container.quantity}
                    />
                    <span>
                      {container.itemDescription || (
                        <StopOutlined style={{ color: "red" }} />
                      )}
                    </span>
                    <PriceTag
                      style={{ marginLeft: 8 }}
                      label="Material"
                      amount={
                        (parseFloat(container?.price) || 0) *
                        (parseInt(container?.quantity, 10) || 0)
                      }
                    />
                    <PriceTag
                      amount={container?.labor || 0}
                      label="Labor"
                      style={{ marginLeft: 8 }}
                    />
                  </div>
                </Descriptions.Item>

                {/* Permitted Section */}
                <Descriptions.Item label="Permitted">
                  {permitted ? (
                    <>
                      <Tag
                        style={{ fontWeight: 600 }}
                        icon={<CheckCircleOutlined />}
                        color="success"
                      >
                        Yes
                      </Tag>
                      <PriceTag
                        amount={permitted.labor || 0}
                        label="Labor"
                        style={{ marginLeft: 8 }}
                      />
                    </>
                  ) : (
                    <Tag
                      style={{ fontWeight: 600 }}
                      icon={<CloseCircleOutlined />}
                      color="error"
                    >
                      No
                    </Tag>
                  )}
                </Descriptions.Item>

                {/* Rain Protection Section */}
                <Descriptions.Item label="Rain Protection">
                  {rainProtection ? (
                    <>
                      <Tag
                        style={{ fontWeight: 600 }}
                        icon={<CheckCircleOutlined />}
                        color="success"
                      >
                        Yes
                      </Tag>
                      <PriceTag
                        amount={rainProtection.material || 0}
                        label="Material"
                        style={{ marginLeft: 8 }}
                      />
                      <PriceTag
                        amount={rainProtection.labor || 0}
                        label="Labor"
                        style={{ marginLeft: 8 }}
                      />

                    </>
                  ) : (
                    <Tag
                      style={{ fontWeight: 600 }}
                      icon={<CloseCircleOutlined />}
                      color="error"
                    >
                      No
                    </Tag>
                  )}
                </Descriptions.Item>

                {/* Structure Dimensions */}
                <Descriptions.Item label="Structure Dimensions">
                  {`${structureLength}ft (L) x ${structureWidth}ft (W) x ${structureHeight}ft (H)`}
                </Descriptions.Item>

                {/* Partition Walls */}
                <Descriptions.Item label="Partition Walls">
                  {partitionWalls !== undefined ? (
                    partitionWalls
                  ) : (
                    <StopOutlined style={{ color: "red" }} />
                  )}
                </Descriptions.Item>

                {/* Long Wall Removed */}
                <Descriptions.Item label="Long Wall Removed">
                  {longWallRemoved ? (
                    <Tag
                      style={{ fontWeight: 600 }}
                      icon={<CheckCircleOutlined />}
                      color="success"
                    >
                      Yes
                    </Tag>
                  ) : (
                    <Tag
                      style={{ fontWeight: 600 }}
                      icon={<CloseCircleOutlined />}
                      color="error"
                    >
                      No
                    </Tag>
                  )}
                </Descriptions.Item>

                {/* End Wall Removed */}
                <Descriptions.Item label="End Wall Removed">
                  {endWallRemoved ? (
                    <Tag
                      style={{ fontWeight: 600 }}
                      icon={<CheckCircleOutlined />}
                      color="success"
                    >
                      Yes
                    </Tag>
                  ) : (
                    <Tag
                      style={{ fontWeight: 600 }}
                      icon={<CloseCircleOutlined />}
                      color="error"
                    >
                      No
                    </Tag>
                  )}
                </Descriptions.Item>
              </Descriptions>

              {/* Options and Costs Table */}
              <Table
                dataSource={Object.keys(selectedOptions || {}).map(
                  (category) => {
                    const option = selectedOptions[category];
                    const cost = categoryCosts[category] || {};
                    const materialItems = option?.materialItems || [];

                    return {
                      key: category,
                      category,
                      moduleOption: option?.moduleOptionDescription || (
                        <StopOutlined style={{ color: "red" }} />
                      ),
                      materialCost:
                        cost.materialCost !== undefined ? (
                          <PriceTag amount={cost.materialCost} />
                        ) : (
                          <StopOutlined style={{ color: "red" }} />
                        ),
                      laborCost:
                        cost.laborCost !== undefined ? (
                          <PriceTag amount={cost.laborCost} />
                        ) : (
                          <StopOutlined style={{ color: "red" }} />
                        ),
                      totalCost:
                        cost.totalCost !== undefined ? (
                          <PriceTag amount={cost.totalCost} />
                        ) : (
                          <StopOutlined style={{ color: "red" }} />
                        ),
                      materials:
                        materialItems.length > 0 ? (
                          <div>
                            {materialItems.map((materialItem) => (
                              <span
                                key={materialItem.item}
                                style={{ marginRight: 8 }}
                              >
                                <Tag>
                                  {materialItem.name || materialItem.item}
                                </Tag>
                                <Tag color="blue" style={{ fontWeight: 600 }}>
                                  {materialItem.qtyToOrder}
                                </Tag>
                              </span>
                            ))}
                          </div>
                        ) : (
                          <StopOutlined style={{ color: "red" }} />
                        ),
                    };
                  }
                )}
                columns={[
                  {
                    title: "Category",
                    dataIndex: "category",
                    key: "category",
                  },
                  {
                    title: "Module Option",
                    dataIndex: "moduleOption",
                    key: "moduleOption",
                  },
                  {
                    title: "Material",
                    dataIndex: "materialCost",
                    key: "materialCost",
                  },
                  {
                    title: "Labor",
                    dataIndex: "laborCost",
                    key: "laborCost",
                  },
                  {
                    title: "Total",
                    dataIndex: "totalCost",
                    key: "totalCost",
                  },
                  {
                    title: "Items",
                    dataIndex: "materials",
                    key: "materials",
                  },
                ]}
                pagination={false}
                size="small"
                bordered
                style={{ marginTop: 16 }}
              />


            </Card>
          );
        })}
      </div>
    </>
  );
}

export default EstimateModules;
