import { useUsersData } from "./usersContext";

const useUsers = () => {
  const { data, loading, error, fetchUsers } = useUsersData();
  return {
    data: data || [],
    loading,
    error,
    refresh: fetchUsers,
  };
};

export default useUsers;
