import React, { useRef, useEffect, useState } from "react";
import { Drawer, Row, Col, Card, Tag, Button, Empty, Badge } from "antd";
import { formatMoney } from "../../Formatters/helpers";
import { FilePdfFilled } from "@ant-design/icons"; // Icon for viewing PDF
import { COLORS } from "../../constants";

const InvoiceDrawer = ({
  purchaseOrder,
  visible,
  onClose,
  specificInvoiceId,
}) => {
  const invoiceRefs = useRef([]); // References to invoice DOM elements
  const [activeInvoiceId, setActiveInvoiceId] = useState(null);
  const [drawerKey, setDrawerKey] = useState(0); // Key to force re-render
  const [isScrollingToInvoice, setIsScrollingToInvoice] = useState(false); // To lock manual scroll events while button click scroll is active

  // Force re-render when the drawer is opened
  useEffect(() => {
    if (visible) {
      setDrawerKey((prevKey) => prevKey + 1); // Update the key when drawer opens
    }
  }, [visible]);

  // Scroll to specific invoice when the drawer opens or when a specific button is clicked
  const handleScrollToInvoice = (invoiceId) => {
    setIsScrollingToInvoice(true); // Lock manual scroll handling
    const targetIndex = purchaseOrder.invoices.findIndex(
      (invoice) => invoice.id === invoiceId
    );
    if (invoiceRefs.current[targetIndex]) {
      invoiceRefs.current[targetIndex].scrollIntoView({
        behavior: "smooth",
      });
      setActiveInvoiceId(invoiceId); // Highlight the clicked button immediately
      setTimeout(() => {
        setIsScrollingToInvoice(false); // Unlock manual scroll handling after a delay
      }, 800); // Allow time for smooth scrolling
    }
  };

  // Reset the state when the drawer is closed
  useEffect(() => {
    if (visible && specificInvoiceId) {
      setTimeout(() => {
        handleScrollToInvoice(specificInvoiceId);
      }, 0); // Delays the scroll to the next tick of the event loop
    }
  }, [visible, specificInvoiceId, purchaseOrder.invoices]);

  // Use IntersectionObserver to track which invoice is visible and highlight the corresponding button
  useEffect(() => {
    const observerOptions = {
      root: null, // Observe within the viewport
      rootMargin: "0px",
      threshold: 0.6, // Trigger when 60% of the invoice is visible
    };

    const observerCallback = (entries) => {
      if (!isScrollingToInvoice) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetIndex = invoiceRefs.current.findIndex(
              (ref) => ref === entry.target
            );
            if (targetIndex !== -1) {
              const visibleInvoice = purchaseOrder.invoices[targetIndex];
              setActiveInvoiceId(visibleInvoice.id); // Highlight the invoice in view
            }
          }
        });
      }
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Observe each invoice reference
    invoiceRefs.current.forEach((invoiceRef) => {
      if (invoiceRef) {
        observer.observe(invoiceRef);
      }
    });

    return () => {
      // Cleanup the observer when the component is unmounted
      invoiceRefs.current.forEach((invoiceRef) => {
        if (invoiceRef) {
          observer.unobserve(invoiceRef);
        }
      });
    };
  }, [purchaseOrder.invoices, isScrollingToInvoice]);

  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          Purchase Order - {purchaseOrder.poNumber} Invoices
          <Badge count={purchaseOrder?.invoices?.length} offset={[10, 0]} />
        </div>
      }
      width={1020}
      open={visible}
      onClose={onClose}
      styles={{ body: {paddingBottom: 80} }}
      key={drawerKey} // Force re-render by changing key
    >
      {purchaseOrder.invoices && purchaseOrder.invoices.length > 0 ? (
        <Row gutter={[16, 16]}>
          {/* Main content displaying the invoice details and PDF */}
          <Col span={purchaseOrder.invoices.length > 1 ? 20 : 24}>
            {purchaseOrder.invoices.map((invoice, index) => (
              <div
                key={invoice.id}
                ref={(el) => (invoiceRefs.current[index] = el)}
                style={{ marginBottom: "24px" }}
              >
                <Card
                  title={
                    <div>
                      <Tag
                        style={{ fontSize: 16, fontWeight: 600 }}
                        color="green"
                      >
                        {formatMoney(invoice.amount)}
                      </Tag>
                      {`Invoice ${invoice.invoiceNumber}`}
                      <span style={{ fontStyle: "italic", opacity: 0.8 }}>
                        {` - Due Date: ${new Date(
                          invoice.dueDate
                        ).toLocaleDateString()}`}
                      </span>
                    </div>
                  }
                  styles={{ body:  {padding: 0} }}
                  extra={
                    <>
                      <Button
                        icon={<FilePdfFilled />}
                        onClick={() => window.open(invoice.document, "_blank")} // Open PDF in a new tab
                      >
                        View PDF
                      </Button>
                    </>
                  }
                >
                  {/* Embed the PDF directly in the iframe */}
                  <iframe
                    src={invoice.document}
                    width="100%"
                    height="920px"
                    style={{ border: "none" }}
                    title={`Invoice ${invoice.invoiceNumber}`}
                  />
                </Card>
              </div>
            ))}
          </Col>

          {/* Buttons on the right to scroll to each invoice */}
          {purchaseOrder.invoices.length > 1 && (
            <Col span={4}>
              <div style={{ position: "sticky", top: 0 }}>
                {purchaseOrder.invoices.map((invoice, index) => (
                  <Button
                    key={invoice.id}
                    style={{
                      marginBottom: "16px",
                      width: "100%",
                      backgroundColor:
                        activeInvoiceId === invoice.id ? COLORS.PRIMARY : "",
                      color: activeInvoiceId === invoice.id ? "#fff" : "#000",
                    }}
                    onClick={() => handleScrollToInvoice(invoice.id)}
                  >
                    Invoice {index + 1}
                  </Button>
                ))}
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <Empty description="No invoices available" />
      )}
    </Drawer>
  );
};

export default InvoiceDrawer;
