import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/aria/addAria";

export async function addItemsToTable(tableName, item, id = null) {
  const url = id
    ? `${API_BASE_URL}/${tableName}/${id}`
    : `${API_BASE_URL}/${tableName}`;
  try {
    const response = await axios.post(url, item);
    return response.data.item; // Return the full item including ID
  } catch (error) {
    throw new Error(
      `Error ${id ? "updating" : "adding"} item in table ${tableName}: ${
        error.response ? error.response.statusText : error.message
      }`
    );
  }
}
