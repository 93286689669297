import React from "react";
import { Tag } from "antd";
import { formatMoney } from "../Formatters/helpers";
import { fonts } from "pdfmake/build/pdfmake";

const PriceTag = ({
  amount,
  bold = false,
  large = false,
  xl = false,
  medium = false,
  style,
  label = false,
  icon = false,
}) => {
  let fontSize;
  if (xl) {
    fontSize = 18;
  } else if (large) {
    fontSize = 16;
  } else if (medium) {
    fontSize = 14
  }
   else {
    fontSize = undefined; // Use default font size
  }

  let padding;
  if (xl) {
    padding = "4px 6px";
  } else if (large) {
    padding = "4px 6px";
  } else if (medium) {
    padding = "2px 6px";
  } else {
    padding = undefined; // Use default padding
  }

  // Determine the color based on the amount value
  let color;
  if (amount > 0) {
    color = "green";
  } else if (amount < 0) {
    color = "red";
  } else {
    color = undefined; // No color for 0
  }

  return (
    <Tag
      color={color}
 
      style={{
        fontWeight: bold ? 600 : undefined,
        fontSize: fontSize,
        padding: padding,
        ...style,
        
      }}
    >
      {icon && <span style={{ marginRight: 4 }}>{icon}</span>}
      {label && <span style={{ fontWeight: 600 }}>{label}: </span>}
      {formatMoney(amount)}
    </Tag>
  );
};

export default PriceTag;
