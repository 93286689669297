import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getEstimateNumber = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/estimates/getEstimateNumber`
    );
    return response.data.newEstimateNumber;
  } catch (error) {
    console.error("Error fetching estimate number:", error);
    throw error;
  }
};
