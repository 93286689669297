import React, { useEffect, useState } from "react";
import { Row, Col, Radio } from "antd";
import { DollarCircleOutlined, RetweetOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import ReceivePurchase from "../../Components/Receiving/ReceivePurchase";
import ReceiveReturn from "../../Components/Receiving/ReceiveReturns";
import { useMediaQueryContext } from "../../Contexts/MediaQueryContext";


function Receive() {
  const isDesktop = useMediaQueryContext();
  const navigate = useNavigate();
  const location = useLocation();
  const orderTypeFromUrl = location.pathname.includes("return-orders")
    ? "return"
    : "purchase";

  const [selectedOrderType, setSelectedOrderType] = useState(orderTypeFromUrl);

  useEffect(() => {
    if (selectedOrderType === "purchase") {
      navigate("/receiving/receive/purchase-orders");
    } else {
      navigate("/receiving/receive/return-orders");
    }
  }, [selectedOrderType, navigate]);

  useEffect(() => {
    // Set the document title based on the selected order type
    document.title =
      selectedOrderType === "purchase"
        ? "Receiving - Purchase Orders"
        : "Receiving - Return Orders";
  }, [selectedOrderType]);

  const handleOrderTypeChange = (e) => {
    setSelectedOrderType(e.target.value);
  };

  return (
    <div>
      <Row justify="center" style={{ marginBottom: "16px" }}>
        <Col>
          <Radio.Group
            value={selectedOrderType}
            onChange={handleOrderTypeChange}
          >
            <Radio.Button value="purchase">
              <DollarCircleOutlined style={{ marginRight: 4 }} /> Purchase Order
            </Radio.Button>
            <Radio.Button value="return">
              <RetweetOutlined style={{ marginRight: 4 }} /> Return Order
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={24}>
          {selectedOrderType === "purchase" ? (
            <ReceivePurchase />
          ) : (
            <ReceiveReturn />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Receive;
