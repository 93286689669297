import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Table, Tag, Typography, Row, Col } from "antd";
import { useItems } from "../../../Contexts/useSpecificData";
import PriceTag from "../../../Styled/PriceTag";
import { SearchInput } from "../../../Styled/SearchInput";
import { debounce } from "lodash";
import { getDefaultPriceItem } from "../../../utils/itemsHelpers";

const { Title } = Typography;

const normalizeType = (type) =>
  type
    .trim()
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());

const TYPE_COLOR_MAP = {
  "Custom Item": "blue",
  "Aria Item": "purple",
  Assembly: "red",
  Module: "orange",
  default: "default",
};

const BOM = ({ estimate, onLineItemCountUpdate }) => {
  const customItems = estimate.customItems || [];
  const modules = estimate.modules || [];
  const assemblies = estimate.assemblies || [];
  const ariaItems = estimate.ariaItems || [];

  const { data: items } = useItems();

  const parsePrice = (price) =>
    typeof price === "string"
      ? parseFloat(price.replace(/[^0-9.-]+/g, "")) || 0
      : price || 0;

  const [searchText, setSearchText] = useState("");

  const handleSearch = useCallback(
    debounce((e) => {
      setSearchText(e.target.value.toLowerCase());
    }, 50),
    []
  );

  const assemblyItems = assemblies.flatMap((assemblyItem) =>
    (assemblyItem.assemblyItems || []).map((item) => ({
      ...item,
      price: parsePrice(item.price),
      quantity: item.quantity * assemblyItem.quantity,
      type: normalizeType("Assembly"),
    }))
  );

  const moduleItems = modules.flatMap((module) => {
    const moduleItemList = [];

    if (module.container) {
      moduleItemList.push({
        ...module.container,
        price: parsePrice(module.container.price),
        quantity: parseInt(module.container.quantity, 10),
        type: normalizeType("Module"),
      });
    }

    const materialItems = Object.values(module.selectedOptions || {}).flatMap(
      (option) =>
        (option.materialItems || []).map((materialItem) => {
          const matchedItem = items.find((itm) => itm.id === materialItem.item);
          const defaultPrice = parsePrice(
            getDefaultPriceItem(matchedItem, items) || materialItem.price
          );

          return {
            ...materialItem,
            price: defaultPrice,
            quantity: materialItem.qtyToOrder || 0,
            type: normalizeType("Module"),
            category: matchedItem?.category || materialItem.category || "",
            itemNumber:
              matchedItem?.itemNumber || materialItem.itemNumber || "",
            itemDescription:
              matchedItem?.itemDescription || materialItem.name || "",
          };
        })
    );

    moduleItemList.push(...materialItems);

    if (module.rainProtection) {
      const matchedItem = items.find(
        (item) => item.itemNumber === module.rainProtection.itemNumber
      );
      const defaultPrice = parsePrice(
        getDefaultPriceItem(matchedItem, items) || module.rainProtection.price
      );

      moduleItemList.push({
        ...module.rainProtection,
        price: defaultPrice,
        quantity: module.rainProtection.quantity || 0,
        type: normalizeType("Module"),
        category: matchedItem?.category || module.rainProtection.category || "",
        itemDescription:
          matchedItem?.itemDescription ||
          module.rainProtection.itemDescription ||
          "",
      });
    }

    return moduleItemList;
  });

  const ariaItemsData = ariaItems.map((item) => ({
    ...item,
    price: parsePrice(item.price),
    quantity: parseInt(item.quantity, 10),
    type: normalizeType("Aria Item"),
  }));

  const customItemsData = customItems.map((item) => ({
    ...item,
    price: parsePrice(item.price),
    quantity: parseInt(item.quantity, 10),
    type: normalizeType("Custom Item"),
  }));

  const allItems = [
    ...customItemsData,
    ...ariaItemsData,
    ...assemblyItems,
    ...moduleItems,
  ];

  const aggregatedItems = useMemo(() => {
    const itemMap = new Map();

    allItems.forEach((item) => {
      const key = item.itemNumber || item.id;

      if (itemMap.has(key)) {
        const existingItem = itemMap.get(key);
        existingItem.quantity += item.quantity || 0;
      } else {
        itemMap.set(key, { ...item });
      }
    });

    return Array.from(itemMap.values());
  }, [allItems]);

  useEffect(() => {
    // Notify parent of the total count of line items (aggregated items)
    if (onLineItemCountUpdate) {
      onLineItemCountUpdate(aggregatedItems.length);
    }
  }, [aggregatedItems, onLineItemCountUpdate]);

  const filteredItems = useMemo(() => {
    if (!searchText) return aggregatedItems;

    return aggregatedItems.filter((item) => {
      const fields = [
        item.category,
        item.itemDescription,
        item.itemNumber,
        item.type,
      ];
      return fields.some((field) => field?.toLowerCase().includes(searchText));
    });
  }, [aggregatedItems, searchText]);

  const totalCost = useMemo(() => {
    return filteredItems.reduce(
      (sum, item) => sum + parsePrice(item.price) * (item.quantity || 0),
      0
    );
  }, [filteredItems]);

  const columns = useMemo(
    () => [
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: (a, b) =>
          (a.category || "").localeCompare(b.category || "", undefined, {
            sensitivity: "base",
          }),
      },
      {
        title: "Item Description",
        dataIndex: "itemDescription",
        key: "itemDescription",
        sorter: (a, b) =>
          (a.itemDescription || "").localeCompare(
            b.itemDescription || "",
            undefined,
            {
              sensitivity: "base",
            }
          ),
        render: (text, record) => (
          <div>
            {record.itemNumber && <Tag>{record.itemNumber}</Tag>}
            <Typography.Text>{text}</Typography.Text>
          </div>
        ),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) =>
          a.type.localeCompare(b.type, undefined, { sensitivity: "base" }),
        render: (type) => (
          <Tag color={TYPE_COLOR_MAP[type] || TYPE_COLOR_MAP.default}>
            {type}
          </Tag>
        ),
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => <PriceTag amount={price} />,
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Total Cost",
        key: "totalCost",
        render: (text, record) => (
          <PriceTag
            amount={parsePrice(record.price) * (record.quantity || 0)}
          />
        ),
      },
    ],
    []
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 18, marginRight: 12, fontWeight: 500 }}>
            Total BOM Cost:
          </span>
          <PriceTag large bold amount={totalCost} />
        </div>
      </Col>
      <Col span={24}>
        <SearchInput
          placeholder="Search BOM"
          resultsLength={filteredItems.length} // Number of items displayed in the table
          onChange={handleSearch}
        />
      </Col>
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={filteredItems}
          rowKey={(record) => record.itemNumber || record.id}
          pagination={false}
          footer={() => (
            <div style={{ textAlign: "right", fontWeight: "bold" }}>
              Total Cost of Materials:{" "}
              <PriceTag large bold amount={totalCost} />
            </div>
          )}
        />
      </Col>
    </Row>
  );
};

export default BOM;
