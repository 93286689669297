// src/Components/Estimating/ModuleOptions/formulaFormatters.js

/**
 * Formats the Labor Formula.
 *
 * @param {number} laborCost - The labor cost input by the user.
 * @param {Array<string>} laborApplicableUOM - The selected UOMs applicable to labor.
 * @returns {Object} - An object containing the message and a flag indicating if it's a warning.
 */
export const formatLaborFormula = (laborCost, laborApplicableUOM) => {
  if (!laborCost && laborApplicableUOM.length === 0) {
    return {
      message: "Select UOMs and Enter Labor Cost",
      isWarning: true,
    };
  } else if (!laborCost) {
    return {
      message: "Enter Labor Cost",
      isWarning: true,
    };
  } else if (laborApplicableUOM.length === 0) {
    return {
      message: "Select Labor Applicable UOMs",
      isWarning: true,
    };
  } else {
    const formula = `(${laborApplicableUOM.join(" + ")}) * $${laborCost.toFixed(
      2
    )}`;
    return {
      message: formula,
      isWarning: false,
    };
  }
};

/**
 * Formats the Material Formula.
 *
 * @param {Array<string>} uom - The selected UOMs for the material.
 * @param {number} [multiplier] - The multiplier input by the user (optional).
 * @param {number} coverageArea - The coverage area input by the user.
 * @returns {Object} - An object containing the message and a flag indicating if it's a warning.
 */
export const formatMaterialFormula = (uom, multiplier, coverageArea) => {
  if (coverageArea === undefined || coverageArea <= 0) {
    if (uom.length === 0) {
      return {
        message: "Select UOMs and Enter Coverage Area",
        isWarning: true,
      };
    } else {
      return {
        message: "Enter Coverage Area",
        isWarning: true,
      };
    }
  } else if (uom.length === 0) {
    return {
      message: "Select UOMs",
      isWarning: true,
    };
  } else {
    if (multiplier && multiplier > 0) {
      return {
        message: `(${uom.join(" + ")}) * ${multiplier} / ${coverageArea}`,
        isWarning: false,
      };
    } else {
      return {
        message: `(${uom.join(" + ")}) / ${coverageArea}`,
        isWarning: false,
      };
    }
  }
};
