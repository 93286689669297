import axios from "axios";

const SLACK_API_URL =
  process.env.REACT_APP_API_BASE_URL +"/slack/sendSlackMessage";

/**
 * Sends a message to a Slack channel using a Lambda function.
 *
 * @param {string} channel - The ID of the Slack channel to send the message to.
 * @param {string} text - The text of the message to send.
 * @param {Array} [blocks] - Optional. An array of Slack block objects to structure the message.
 * @param {Array} [attachments] - Optional. An array of Slack attachment objects.
 *
 * @returns {Promise<object>} - The result of the Slack API call.
 */
export async function sendSlackMessage({
  channel,
  text,
  blocks = [],
  attachments = [],
}) {
  console.log("Sending message to Slack:", {
    channel,
    text,
    blocks,
    attachments,
  });


  const payload = {
    channel: channel,
    text: text,
    blocks: blocks,
    attachments: attachments,
  };

try {
  const response = await axios.post(SLACK_API_URL, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.status === 200 || response.status === 204) {
    console.log("Message sent successfully:", response.data);
    return response.data;
  } else {
    console.warn(`Unexpected status code: ${response.status}`);
    console.log("Full response object:", response);
    throw new Error(`Unexpected status code: ${response.status}`);
  }
} catch (error) {
  // Log the entire error object for better debugging
  console.error("Error sending message:", error);

  // Log more specific details if available
  if (error.response) {
    console.error("Error response data:", error.response.data);
    console.error("Error response status:", error.response.status);
    console.error("Error response headers:", error.response.headers);
  }

  throw error; // Rethrow the error after logging
}

}
