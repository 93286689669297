import React, { useEffect } from "react";
import { SearchInput } from "../../Styled/SearchInput";
import { Col, Row, Button, Table, Tag, Select } from "antd";
import { CopyOutlined, PlusOutlined, RetweetOutlined, StopOutlined } from "@ant-design/icons";
import { formatDate, formatMoney } from "../../Formatters/helpers";
import AddEstimateModal from "../../Components/Estimating/AddEstimateModal";
import { COLORS, ESTIMATE_STATUS, TABLES } from "../../constants";
import { updateById } from "../../Functions/updateById";
import { Link } from "react-router-dom";
import { useEstimates, useItems } from "../../Contexts/useSpecificData"; // Adjust the import path as necessary
import { calculateEstimateCosts } from "../../Formatters/calculateEstimate";
import HeaderText from "../../Styled/HeaderText";
import PriceTag from "../../Styled/PriceTag";

const { Option } = Select;

const Estimates = () => {
  const { data: estimates, loading, error, refresh } = useEstimates();
  const { data: items } = useItems();

  useEffect(() => {
    if (estimates) {
      console.log("Estimates Data:", estimates);
      document.title = `Estimates`;
    }
  }, [estimates]);

  const updateStatus = async (id, newStatus) => {
    console.log("Updating status for ID:", id, "New Status:", newStatus);
    await updateById(TABLES.ESTIMATES, { estimateStatus: newStatus }, id);
    refresh();
  };

  const columns = [
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      render: (text) => formatDate(text, true),
    },
    {
      title: "id",
      dataIndex: "estimateNumber",
      key: "estimateNumber",
    },
    {
      title: "Estimate Name",
      dataIndex: "estimateName",
      key: "estimateName",
      render: (text, record) => (
        <Link to={`/estimating/estimates/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Quote By",
      key: "quoteBy",
      dataIndex: "quoteBy",
      render: (text) => formatDate(text),
    },
    // {
    //   title: "Status",
    //   dataIndex: "estimateStatus",
    //   key: "estimateStatus",
    //   render: (text, record) => (
    //     <Select
    //       defaultValue={text}
    //       style={{ width: 120 }}
    //       onChange={(newStatus) => updateStatus(record.id, newStatus)}
    //     >
    //       {ESTIMATE_STATUS.map((status) => (
    //         <Option key={status.value} value={status.value}>
    //           {status.label}
    //         </Option>
    //       ))}
    //     </Select>
    //   ),
    // },
    // {
    //   title: "Materials Cost",
    //   key: "materialsCost",
    //   render: (text, record) => {
    //     const { materialsCost } = calculateEstimateCosts(record, items);
    //     return formatMoney(materialsCost);
    //   },
    // },
    // {
    //   title: "Labor Cost",
    //   key: "laborPrice",
    //   render: (text, record) => {
    //     const { laborPrice } = calculateEstimateCosts(record, items);
    //     return formatMoney(laborPrice);
    //   },
    // },
    // {
    //   title: "Total Cost",
    //   key: "totalCost",
    //   render: (text, record) => {
    //     const { totalCost } = calculateEstimateCosts(record, items);
    //     return formatMoney(totalCost);
    //   },
    // },
{
  title: "Sale Amount",
  key: "saleAmount",
  render: (text, record) => {
    // Access saleAmount from estimate or record based on your data structure
    // Replace 'estimate' with 'record' if saleAmount is part of each record
    const saleAmount = record?.costs?.saleAmount; // or record?.costs?.saleAmount

    // Conditional rendering
    if (saleAmount != null) {
      return <PriceTag amount={saleAmount} />;
    } else {
      return <StopOutlined />;
    }

    // Alternatively, using a ternary operator:
    // return saleAmount != null ? formatMoney(saleAmount) : <StopOutlined />;
      },
    },
  ];

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={8}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

        }}
      >
      <HeaderText text="Estimates" />
      </Col>
      <Col
        span={8}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></Col>
      <Col align="right" span={8}>
        <Button style={{ marginRight: 16 }} icon={<CopyOutlined />}>
          Duplicate Estimate
        </Button>
        <AddEstimateModal onAddSuccess={refresh}>
          <Button type="primary" icon={<PlusOutlined />}>
            Add Estimate
          </Button>
        </AddEstimateModal>
      </Col>
      <Col span={24}>
        <SearchInput placeholder="Search Projects" />
      </Col>
      <Col span={24}>
        <Table columns={columns} dataSource={estimates} size="small" />
      </Col>
    </Row>
  );
};

export default Estimates;
