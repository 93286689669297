import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";

const USERS_API_URL = process.env.REACT_APP_API_BASE_URL + "/clerk/getUsers";

const UsersContext = createContext();

const useUsersData = () => useContext(UsersContext);

const UsersDataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(USERS_API_URL);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching users:", error); // Log the error
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <UsersContext.Provider value={{ data, loading, error, fetchUsers }}>
      {children}
    </UsersContext.Provider>
  );
};

export { useUsersData, UsersDataProvider };
