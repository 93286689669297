// HelpMenu.js
import React, { useState } from "react";
import {
  Dropdown,
  Modal,
  Button,
  Tag,
  Table,
  Card,
  Image,
  Col,
  Row,
  Badge,
  Space,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import HelpDrawer from "./HelpDrawer"; // Assuming you have this component
import { useMediaQueryContext } from "../../Contexts/MediaQueryContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faImage } from "@fortawesome/free-solid-svg-icons"; // Import faImage icon

// Function to get color based on ticket status
const getStatusColor = (status) => {
  switch (status) {
    case "Open":
      return "blue";
    case "Closed":
      return "red";
    default:
      return "gray";
  }
};

const HelpMenu = ({ userTickets, logs }) => {
  const [helpDrawerVisible, setHelpDrawerVisible] = useState(false);
  const [viewTicketsVisible, setViewTicketsVisible] = useState(false);
  const [screenshot, setScreenshot] = useState(null);

  // State and handlers for Resolution Images Modal
  const [resolutionModalVisible, setResolutionModalVisible] = useState(false);
  const [currentResolutionImages, setCurrentResolutionImages] = useState([]);

  // State and handlers for Issue Images Modal
  const [issueModalVisible, setIssueModalVisible] = useState(false);
  const [currentIssueImages, setCurrentIssueImages] = useState([]);

  const { isDesktop } = useMediaQueryContext();

  // Function to capture a screenshot using html2canvas
  const captureScreenshot = async () => {
    try {
      const canvas = await html2canvas(document.body);
      const screenshotDataUrl = canvas.toDataURL("image/png");
      setScreenshot(screenshotDataUrl);
      return screenshotDataUrl;
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };

  // Handlers for the dropdown options
  const showHelpDrawer = async () => {
    await captureScreenshot();
    setHelpDrawerVisible(true);
  };

  const showViewTicketsModal = () => {
    setViewTicketsVisible(true);
  };

  const closeHelpDrawer = () => {
    setHelpDrawerVisible(false);
  };

  const closeViewTicketsModal = () => {
    setViewTicketsVisible(false);
  };

  const openTicketsLength = userTickets.filter(
    (ticket) => ticket.status !== "Closed"
  ).length;

  // Define menu items as an array for antd v5
  const menuItems = [
    {
      key: "1",
      label: "Submit Ticket",
      onClick: showHelpDrawer,
    },
    {
      key: "2",
      label: (
        <Space>
          <span>View my Tickets</span>
          <Badge size="small" count={openTicketsLength} />
        </Space>
      ),
      onClick: showViewTicketsModal,
    },
  ];

  // Sorting tickets by open status first
  const sortedTickets = userTickets.sort((a, b) => {
    if (a.status !== "Closed" && b.status === "Closed") return -1;
    if (a.status === "Closed" && b.status !== "Closed") return 1;
    return 0;
  });

  const columns = [
    {
      title: "Date/Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp) => new Date(timestamp).toLocaleString(),
      sorter: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
    },
    {
      title: "Issue Description",
      dataIndex: "issueDescription",
      key: "issueDescription",
      render: (issueDescription, record) => (
        <Tooltip title={issueDescription}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{issueDescription.substring(0, 30)}...</span>
            {record.uploadedFiles && record.uploadedFiles.length > 0 && (
              <FontAwesomeIcon
                icon={faImage}
                style={{
                  marginLeft: 8,
                  cursor: "pointer",
                  color: "#1890ff",
                }}
                onClick={() => showIssueImagesModal(record.uploadedFiles)}
              />
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Closed" ? "red" : "blue"}>
          {status === "Closed" ? "Closed" : "Open"}
        </Tag>
      ),
      sorter: (a, b) => (a.status === "Closed" ? 1 : -1),
      filters: [
        { text: "Open", value: "Open" },
        { text: "Closed", value: "Closed" },
      ],
      onFilter: (value, record) => (record.status || "Open") === value,
    },
    {
      title: "Resolution",
      dataIndex: "resolution",
      key: "resolution",
      render: (resolution, record) =>
        resolution ? (
          <Tooltip title={resolution}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{resolution.substring(0, 30)}...</span>
              {record.resolutionImages &&
                record.resolutionImages.length > 0 && (
                  <FontAwesomeIcon
                    icon={faImage}
                    style={{
                      marginLeft: 8,
                      cursor: "pointer",
                      color: "#1890ff",
                    }}
                    onClick={() =>
                      showResolutionImagesModal(record.resolutionImages)
                    }
                  />
                )}
            </div>
          </Tooltip>
        ) : (
          <Tag color="red">Pending</Tag>
        ),
    },
    {
      title: "Resolved By",
      dataIndex: "resolvedBy",
      key: "resolvedBy",
      render: (resolvedBy) => resolvedBy || "Not resolved yet",
    },
  ];

  const renderTicketsAsCards = () => (
    <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
      {sortedTickets.map((ticket, index) => {
        const uniqueKey = `${ticket.id}-${index}`; // Ensure key is unique
        return (
          <Col span={24} key={uniqueKey}>
            <Card
              key={uniqueKey}
              className="box-shadow"
              title={
                <Row>
                  <Col span={18}>
                    <Badge
                      size="small"
                      count={index + 1}
                      style={{ marginRight: 8 }}
                    />
                    {new Date(ticket.timestamp).toLocaleString()}
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <Tag
                      size="small"
                      style={{ marginRight: 4 }}
                      color={getStatusColor(ticket.status || "Open")}
                    >
                      {ticket.status || "Open"}
                    </Tag>
                  </Col>
                </Row>
              }
              size="small"
              style={{ width: "100%" }}
            >
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <strong>Issue Description:</strong>{" "}
                  <Tooltip title={ticket.issueDescription}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>{ticket.issueDescription.substring(0, 30)}...</span>
                      {ticket.uploadedFiles &&
                        ticket.uploadedFiles.length > 0 && (
                          <FontAwesomeIcon
                            icon={faImage}
                            style={{
                              marginLeft: 8,
                              cursor: "pointer",
                              color: "#1890ff",
                            }}
                            onClick={() =>
                              showIssueImagesModal(ticket.uploadedFiles)
                            }
                          />
                        )}
                    </div>
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <strong>Status:</strong>{" "}
                  <Tag color={ticket.status === "Closed" ? "red" : "blue"}>
                    {ticket.status === "Closed" ? "Closed" : "Open"}
                  </Tag>
                </Col>

                <Col span={12}>
                  <strong>Resolution:</strong>{" "}
                  {ticket.resolution ? (
                    <Tooltip title={ticket.resolution}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{ticket.resolution.substring(0, 30)}...</span>
                        {ticket.resolutionImages &&
                          ticket.resolutionImages.length > 0 && (
                            <FontAwesomeIcon
                              icon={faImage}
                              style={{
                                marginLeft: 8,
                                cursor: "pointer",
                                color: "#1890ff",
                              }}
                              onClick={() =>
                                showResolutionImagesModal(
                                  ticket.resolutionImages
                                )
                              }
                            />
                          )}
                      </div>
                    </Tooltip>
                  ) : (
                    <Tag color="red">Pending</Tag>
                  )}
                </Col>
                <Col span={12}>
                  <strong>Resolved By:</strong>{" "}
                  {ticket.resolvedBy || "Not resolved yet"}
                </Col>
                <Col span={12}>
                  <strong>Current URL:</strong>{" "}
                  <a
                    href={ticket.currentURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ticket.currentURL}
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        );
      })}
    </Row>
  );

  /**
   * Function to display resolution images in a modal
   */
  const showResolutionImagesModal = (images) => {
    setCurrentResolutionImages(images);
    setResolutionModalVisible(true);
  };

  const handleResolutionModalCancel = () => {
    setResolutionModalVisible(false);
    setCurrentResolutionImages([]);
  };

  /**
   * Function to display issue images in a modal
   */
  const showIssueImagesModal = (images) => {
    setCurrentIssueImages(images);
    setIssueModalVisible(true);
  };

  const handleIssueModalCancel = () => {
    setIssueModalVisible(false);
    setCurrentIssueImages([]);
  };

  return (
    <>
      <Dropdown
        menu={{
          items: menuItems,
        }}
        trigger={["click"]}
      >
        <Badge size="small" count={openTicketsLength}>
          <QuestionCircleOutlined
            style={{ fontSize: 22, color: "white", cursor: "pointer" }}
          />
        </Badge>
      </Dropdown>

      {/* HelpDrawer for submitting tickets */}
      <HelpDrawer
        visible={helpDrawerVisible}
        onClose={closeHelpDrawer}
        screenshot={screenshot}
        logs={logs} // Pass the error logs to the HelpDrawer
      />

      {/* Modal to show user's tickets */}
      <Modal
        width={isDesktop ? "85%" : "95%"}
        title="My Tickets"
        open={viewTicketsVisible}
        onCancel={closeViewTicketsModal}
        footer={[
          <Button key="close" type="primary" onClick={closeViewTicketsModal}>
            Close
          </Button>,
        ]}
      >
        {isDesktop ? (
          <Table
            columns={columns}
            dataSource={sortedTickets}
            rowKey="id"
            pagination={false}
            size="small"
          />
        ) : (
          renderTicketsAsCards()
        )}
      </Modal>

      {/* Modal for Resolution Images Preview */}
      <Modal
        title="Resolution Images"
        open={resolutionModalVisible}
        onCancel={handleResolutionModalCancel}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={handleResolutionModalCancel}
          >
            Close
          </Button>,
        ]}
      >
        <Image.PreviewGroup>
          {currentResolutionImages.map((url, index) => (
            <Image key={index} width={200} src={url} />
          ))}
        </Image.PreviewGroup>
      </Modal>

      {/* Modal for Issue Images Preview */}
      <Modal
        title="Issue Images"
        open={issueModalVisible}
        onCancel={handleIssueModalCancel}
        footer={[
          <Button key="close" type="primary" onClick={handleIssueModalCancel}>
            Close
          </Button>,
        ]}
      >
        <Image.PreviewGroup>
          {currentIssueImages.map((url, index) => (
            <Image key={index} width={200} src={url} />
          ))}
        </Image.PreviewGroup>
      </Modal>
    </>
  );
};

export default HelpMenu;
