// Invoices.jsx

import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Table, Button, message, Badge, Tag } from "antd";
import { Link } from "react-router-dom";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  FilePdfFilled,
  InboxOutlined,
} from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { Loader } from "../../Styled/Loader";
import { SearchInput } from "../../Styled/SearchInput";
import HeaderText from "../../Styled/HeaderText";
import { getVendorName } from "../../Formatters/getVendorName";
import { getProjectName } from "../../Formatters/getProjectName";
import { getFirstNameFromEmail } from "../../Formatters/getNameFromEmail";
import { getTotalFromPurchaseOrder } from "../../utils/purchaseOrderUtils";
import { formatMoney } from "../../Formatters/helpers";
import InvoiceDrawer from "./InvoiceDrawer";
import AddInvoiceModal from "./AddInvoiceModal";

const Invoices = () => {
  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPO, setSelectedPO] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState(["Outstanding"]); // Default filter to 'Outstanding'

  const debounceTimeout = useRef(null);

  useEffect(() => {
    document.title = "Invoices - Purchasing";
  }, []);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [searchTerm]);

  if (poLoading) {
    return <Loader />;
  }

  const handleAddInvoice = (record) => {
    setSelectedPO(record);
    setModalVisible(true);
  };

  const handleInvoiceAdded = (updatedPO) => {
    // Update the local purchase orders data if necessary
    setModalVisible(false);
    message.success("Invoice added successfully!");
  };

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const filteredData = purchaseOrders.filter((record) => {
    const poTotal = formatMoney(
      getTotalFromPurchaseOrder(record)
    ).toLowerCase();

    // Filter based on search term
    const matchesSearchTerm =
      record.poNumber.toLowerCase().includes(debouncedSearchTerm) ||
      getVendorName(record.vendor, vendors)
        .toLowerCase()
        .includes(debouncedSearchTerm) ||
      getFirstNameFromEmail(record.buyer)
        .toLowerCase()
        .includes(debouncedSearchTerm) ||
      poTotal.includes(debouncedSearchTerm);

    // Calculate differential
    const purchaseOrderTotal = getTotalFromPurchaseOrder(record);
    const invoiceTotal = record.invoices
      ? record.invoices.reduce((acc, curr) => acc + curr.amount, 0)
      : 0;
    const differential = purchaseOrderTotal - invoiceTotal;

    // Determine status
    const status = differential <= 0 ? "Completed" : "Outstanding";

    // Filter based on status filter
    const matchesStatusFilter = statusFilter.length
      ? statusFilter.includes(status)
      : true;

    return matchesSearchTerm && matchesStatusFilter;
  });

  const columns = [
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      render: (text, record) => (
        <Link
          to={`/purchasing/invoices/${record.id}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <span style={{ fontSize: 14 }}>{record.poNumber}</span>{" "}
          <Badge
            style={{ marginLeft: 4 }}
            size="small"
            count={record.invoices?.length || 0}
          />
        </Link>
      ),
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text, record) => getVendorName(record.vendor, vendors),
      filters: vendors.map((vendor) => ({
        text: getVendorName(vendor, vendors),
        value: vendor.id,
      })),
      onFilter: (value, record) => record.vendor === value,
      sorter: (a, b) =>
        getVendorName(a.vendor, vendors).localeCompare(
          getVendorName(b.vendor, vendors)
        ),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      render: (text, record) => getProjectName(record.projectNumber, projects),
      filters: projects.map((project) => ({
        text: getProjectName(project.projectNumber, projects),
        value: project.projectNumber,
      })),
      onFilter: (value, record) => record.projectNumber === value,
      sorter: (a, b) =>
        getProjectName(a.projectNumber, projects).localeCompare(
          getProjectName(b.projectNumber, projects)
        ),
    },
    {
      title: "Date",
      dataIndex: "orderedDate",
      key: "orderedDate",
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(b.orderedDate) - new Date(a.orderedDate),
      defaultSortOrder: "ascend",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (text, record) => getFirstNameFromEmail(record.buyer),
      filters: Array.from(
        new Set(
          purchaseOrders.map((order) => getFirstNameFromEmail(order.buyer))
        )
      ).map((buyer) => ({
        text: buyer,
        value: buyer,
      })),
      onFilter: (value, record) =>
        getFirstNameFromEmail(record.buyer) === value,
    },
    {
      title: "PO Total",
      dataIndex: "poTotal",
      key: "poTotal",
      render: (text, record) => {
        const purchaseOrderTotal = getTotalFromPurchaseOrder(record);
        return (
          <span style={{ color: "green" }}>
            {formatMoney(purchaseOrderTotal)}
          </span>
        );
      },
      sorter: (a, b) =>
        getTotalFromPurchaseOrder(a) - getTotalFromPurchaseOrder(b),
    },
    {
      title: "Invoice Total",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      render: (text, record) => {
        const invoiceTotal = record.invoices
          ? record.invoices.reduce((acc, curr) => acc + curr.amount, 0)
          : 0;
        return (
          <span style={{ color: invoiceTotal > 0 ? "green" : "red" }}>
            {formatMoney(invoiceTotal)}
          </span>
        );
      },
      sorter: (a, b) => {
        const invoiceTotalA = a.invoices
          ? a.invoices.reduce((acc, curr) => acc + curr.amount, 0)
          : 0;
        const invoiceTotalB = b.invoices
          ? b.invoices.reduce((acc, curr) => acc + curr.amount, 0)
          : 0;
        return invoiceTotalA - invoiceTotalB;
      },
    },
    {
      title: "Differential",
      dataIndex: "differential",
      key: "differential",
      render: (text, record) => {
        const purchaseOrderTotal = getTotalFromPurchaseOrder(record);
        const invoiceTotal = record.invoices
          ? record.invoices.reduce((acc, curr) => acc + curr.amount, 0)
          : 0;
        const differential = purchaseOrderTotal - invoiceTotal;

        if (differential === 0) {
          return (
            <span style={{ color: "black" }}>
              {formatMoney(Math.abs(differential))}
            </span>
          );
        }

        return (
          <span style={{ color: differential > 0 ? "green" : "red" }}>
            {formatMoney(Math.abs(differential))}{" "}
            {differential > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          </span>
        );
      },
      sorter: (a, b) => {
        const diffA =
          getTotalFromPurchaseOrder(a) -
          (a.invoices
            ? a.invoices.reduce((acc, curr) => acc + curr.amount, 0)
            : 0);
        const diffB =
          getTotalFromPurchaseOrder(b) -
          (b.invoices
            ? b.invoices.reduce((acc, curr) => acc + curr.amount, 0)
            : 0);
        return diffA - diffB;
      },
    },
    {
      title: "Invoice Status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      filters: [
        { text: "Outstanding", value: "Outstanding" },
        { text: "Completed", value: "Completed" },
      ],
      filteredValue: statusFilter,
      onFilter: (value, record) => {
        const purchaseOrderTotal = getTotalFromPurchaseOrder(record);
        const invoiceTotal = record.invoices
          ? record.invoices.reduce((acc, curr) => acc + curr.amount, 0)
          : 0;
        const differential = purchaseOrderTotal - invoiceTotal;
        const status = differential <= 0 ? "Completed" : "Outstanding";
        return status === value;
      },
      render: (text, record) => {
        const purchaseOrderTotal = getTotalFromPurchaseOrder(record);
        const invoiceTotal = record.invoices
          ? record.invoices.reduce((acc, curr) => acc + curr.amount, 0)
          : 0;
        const differential = purchaseOrderTotal - invoiceTotal;
        const status = differential <= 0 ? "Completed" : "Outstanding";

        return (
          <Tag color={status === "Completed" ? "green" : "red"}>{status}</Tag>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            icon={<FilePdfFilled />}
            onClick={() => {
              setSelectedPO(record);
              setDrawerVisible(true);
            }}
            style={{ marginRight: 8 }}
            disabled={!record.invoices || !record.invoices.length}
          >
            View Invoices
          </Button>
          <Button
            type="primary"
            icon={<InboxOutlined />}
            onClick={() => handleAddInvoice(record)}
          >
            Add Invoice
          </Button>
        </>
      ),
    },
  ];

  const handleStatusFilterChange = (pagination, filters) => {
    setStatusFilter(filters.invoiceStatus || []);
  };

  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col span={24}>
          <HeaderText text={"Invoices"} />
        </Col>
        <Col span={24}>
          <SearchInput
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={
              "Search by PO Number, Vendor, Project, Buyer or PO Total"
            }
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            dataSource={filteredData}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            size="small"
            className="small-table"
            onChange={handleStatusFilterChange}
          />
        </Col>
      </Row>

      {/* Invoice Drawer */}
      {selectedPO && (
        <InvoiceDrawer
          purchaseOrder={selectedPO}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        />
      )}

      {/* Add Invoice Modal */}
      {selectedPO && (
        <AddInvoiceModal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          purchaseOrder={selectedPO}
          onInvoiceAdded={handleInvoiceAdded}
        />
      )}
    </div>
  );
};

export default Invoices;
