import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  Card,
  message, // Import message for user notifications
} from "antd";
import { addAssembly } from "../../Functions/assemblyHooks";
import { ITEM_CATEGORIES, EXPENSE_ACCOUNTS, TABLES } from "../../constants";
import { useUser } from "@clerk/clerk-react";
import { updateById } from "../../Functions/updateById";

const AddAssemblyModal = ({ children, assembly, onAddSuccess, title }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useUser();

  useEffect(() => {
    if (visible) {
      if (assembly) {
        form.setFieldsValue({
          assemblyName: assembly.assemblyName,
          description: assembly.description,
          laborPrice: assembly.laborPrice,
          category: assembly.category,
          expenseAccount: assembly.expenseAccount, // Existing expenseAccount
        });
      } else {
        form.resetFields();
      }
    }
  }, [assembly, form, visible]);

  const handleCategoryChange = (value) => {
    const correspondingExpenseAccount = ITEM_CATEGORIES[value]?.expenseAccount;

    if (
      correspondingExpenseAccount &&
      EXPENSE_ACCOUNTS.includes(correspondingExpenseAccount)
    ) {
      form.setFieldsValue({ expenseAccount: correspondingExpenseAccount });
    } else {
      form.setFieldsValue({ expenseAccount: undefined });
    }
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      console.log("Submitting with values: ", values);

      // Assign 'ExpenseId' as a copy of 'expenseAccount'
      const submissionData = {
        ...values,
        ExpenseId: values.expenseAccount, // Duplicate expenseAccount
        submittedBy: user?.user.id, // Ensure this is correctly assigned
      };

      const updatedAssembly = { ...assembly, ...submissionData };
      const operation = assembly
        ? updateById(TABLES.ASSEMBLIES, updatedAssembly, assembly.id)
        : addAssembly(submissionData);

      const result = await operation;
      onAddSuccess(result, !assembly);
      setVisible(false);
      form.resetFields();
      message.success(
        `Assembly ${assembly ? "updated" : "added"} successfully!`
      );
    } catch (error) {
      console.error("Error handling assembly:", error);
      message.error(
        error.message || "There was an error processing your request."
      );
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    resetForm();
  };

  const resetForm = () => {
    form.resetFields();
  };

  // Custom filter function using 'label'
  const customFilterOption = (input, option) =>
    option.label.toLowerCase().includes(input.toLowerCase());

  // Prepare options for Category Select
  const categoryOptions = Object.entries(ITEM_CATEGORIES).map(
    ([key, value]) => ({
      label: key, // Display name
      value: key, // Actual value
    })
  );

  // Prepare options for Expense Account Select
  const expenseAccountOptions = EXPENSE_ACCOUNTS.map((account) => ({
    label: account,
    value: account, // Using the full string as the value
  }));

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: showModal })
      )}
      <Modal
        title={title || (assembly ? "Edit Assembly" : "Add New Assembly")}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {assembly ? "Save Changes" : "Add Assembly"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="assemblyName"
            label="Assembly Name"
            rules={[
              {
                required: true,
                message: "Please input the name of the assembly!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Quote Description"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>

          <Card title="Labor">
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="laborPrice" label="Cost">
                  <InputNumber
                    prefix={"$"}
                    style={{ width: "100%" }}
                    type="number"
                    min={0}
                    step={0.01}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    { required: true, message: "Please select a category!" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select a category"
                    optionFilterProp="label" // Use 'label' for filtering
                    onChange={handleCategoryChange}
                    filterOption={customFilterOption}
                    options={categoryOptions} // Use options prop
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="expenseAccount"
                  label="Expense Account"
                  rules={[
                    {
                      required: true,
                      message: "Please select an expense account!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select an expense account"
                    optionFilterProp="label" // Use 'label' for filtering
                    filterOption={customFilterOption}
                    options={expenseAccountOptions} // Use options prop
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default AddAssemblyModal;
