import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Table, Row, Col, Button } from "antd";
import { usePurchaseOrders, useVendors } from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { Loader } from "../../Styled/Loader";
import { ArrowLeftOutlined } from "@ant-design/icons";

const ReceiveItemPurchase = () => {
  const { id } = useParams(); // Get PO ID from URL
  const navigate = useNavigate();
  const { data: purchaseOrders } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const [searchText, setSearchText] = useState("");
  const [selectedPO, setSelectedPO] = useState(null);

  useEffect(() => {
    // Find the selected purchase order after data is loaded
    if (purchaseOrders && vendors) {
      const foundPO = purchaseOrders.find((po) => po.id === id);
      setSelectedPO(foundPO);
    }
  }, [id, purchaseOrders, vendors]);

    useEffect(() => {
      document.title = `Recieving - ${selectedPO?.poNumber || ""} `;
    }, [selectedPO]);

  const handleRowClick = (itemNumber) => {
    navigate(`/receiving/receive/purchase-orders/${id}/item/${itemNumber}`);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
  ];

  const filteredItems = selectedPO?.lines
    .filter(
      (line) =>
        line.qtyOrdered !== line.qtyReceived &&
        line.qtyReceived < line.qtyOrdered
    )
    .filter(
      (line) =>
        line.itemDescription.toLowerCase().includes(searchText.toLowerCase()) ||
        line.category.toLowerCase().includes(searchText.toLowerCase())
    );

  return (
    <>
      {selectedPO ? (
        <>
          <Row justify="center" style={{ marginBottom: 16 }}>
            <Col span={24}>
                      <Button

            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(`/receiving/receive/purchase-orders`)}
          >Back</Button>
        </Col>
            <Col>
              <div
                style={{ fontWeight: 600, fontSize: 18, textAlign: "center" }}
              >
                {selectedPO.poNumber}
              </div>
              <div style={{ textAlign: "center" }}>
                {getVendorName(selectedPO.vendor, vendors)}
              </div>
            </Col>
          </Row>

          <Input.Search
            placeholder="Search items"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: 16 }}
          />
          <Table
            columns={columns}
            dataSource={filteredItems}
            rowClassName="clickable-row"
            rowKey="itemNumber"
            size="small"
            onRow={(record) => ({
              onClick: () => handleRowClick(record.itemNumber),
            })}
            pagination={false} // Remove pagination
          />
        </>
      ) : (
        <Loader /> // Loading state
      )}
    </>
  );
};

export default ReceiveItemPurchase;
