import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Button,
  Modal,
  Form,
  message,
  Tag,
  Collapse,
  Card,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DragOutlined,
} from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
import { generatePdf } from "../../../Functions/generatePdf";
import { quoteHelper } from "./quoteHelper";
import styles from "./QuotePDF.module.css";
import { formatMoney } from "../../../Formatters/helpers";
import { useUser } from "@clerk/clerk-react";
import { useCustomers } from "../../../Contexts/useSpecificData";
import { getCustomerName } from "../../../Formatters/getCustomerName";
import dayjs from "dayjs";
import AddressAutocomplete from "../../Google/AddressAutocomplete";

const { Option } = Select;
const { Panel } = Collapse;

const QuotePDF = ({ estimate }) => {
  // Fetching user and customers
  const { user } = useUser();
  const { data: customers } = useCustomers();

  // Getting user full name and customer name
  const userName = user?.fullName || "User";
  const customerName =
    getCustomerName(estimate.customerId, customers) || "Customer";

  // Form state variables
  const [leadTime, setLeadTime] = useState("10-12 Weeks");
  const [quoteValidity, setQuoteValidity] = useState(null);
  const [tax, setTax] = useState(0);
  const [taxType, setTaxType] = useState("%");
  const [transport, setTransport] = useState(0);
  const [address, setAddress] = useState("");

  // Process estimate data using quoteHelper
  const estimateData = useMemo(() => quoteHelper(estimate), [estimate]);

  // Initialize state with stable IDs
  const [groupedItems, setGroupedItems] = useState([]);
  const [singleItems, setSingleItems] = useState([]);

  // State for managing the Add Line Item Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addItemType, setAddItemType] = useState("Single"); // To track where to add the item
  const [form] = Form.useForm();

  // Handler for changing the tax type
  const handleTaxTypeChange = (value) => {
    setTaxType(value);
    if (value === "TBD") {
      setTax(null); // Reset tax value when "TBD" is selected
    }
  };

  // Effect to initialize or update groupedItems and singleItems when estimate changes
  useEffect(() => {
    if (estimate) {
      const initializeItems = (items, isInitial = true) =>
        items.map((item) => ({
          ...item,
          id: item.id || uuidv4(), // Use existing ID or generate a new one
          isInitial, // Flag to identify initial items
          visible: true, // Default visibility
        }));

      // Initialize groupedItems
      const newGroupedItems = [
        ...initializeItems(estimateData.modules, true),
        ...initializeItems(estimateData.assemblies, true),
        ...initializeItems(estimateData.ariaItems, true),
        ...initializeItems(estimateData.customItems, true),
      ];

      setGroupedItems(newGroupedItems);
      // Optionally, you can handle singleItems if they are part of the estimate
      // For now, we'll keep singleItems separate and only handle groupedItems
    }
  }, [
    estimate,
    estimateData.modules,
    estimateData.assemblies,
    estimateData.ariaItems,
    estimateData.customItems,
  ]);

  // Handlers for drag end
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    // If dropped in the same place, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Helper function to reorder items within a list
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    // Helper function to move items between lists
    const move = (sourceList, destList, sourceIndex, destIndex) => {
      const sourceClone = Array.from(sourceList);
      const destClone = Array.from(destList);
      const [movedItem] = sourceClone.splice(sourceIndex, 1);
      destClone.splice(destIndex, 0, movedItem);
      return {
        source: sourceClone,
        destination: destClone,
      };
    };

    // Determine source and destination lists
    let sourceList, destList, setSource, setDest;

    if (source.droppableId === "groupedItems") {
      sourceList = groupedItems;
      setSource = setGroupedItems;
    } else {
      sourceList = singleItems;
      setSource = setSingleItems;
    }

    if (destination.droppableId === "groupedItems") {
      destList = groupedItems;
      setDest = setGroupedItems;
    } else {
      destList = singleItems;
      setDest = setSingleItems;
    }

    const movedItem = sourceList[source.index];

    // Prevent moving initial items to singleItems
    if (
      source.droppableId === "groupedItems" &&
      destination.droppableId === "singleItems" &&
      movedItem.isInitial
    ) {
      message.error("Initial items cannot be moved to Single Items.");
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // Reordering within the same list
      const reordered = reorder(sourceList, source.index, destination.index);
      setSource(reordered);
    } else {
      // Moving between lists
      const moved = move(sourceList, destList, source.index, destination.index);

      // If moving to single items, set price to 0 and ensure it's not an initial item
      if (destination.droppableId === "singleItems") {
        moved.destination[destination.index] = {
          ...moved.destination[destination.index],
          price: 0,
          isInitial: false, // Allow inputs if needed
        };
      }

      setSource(moved.source);
      setDest(moved.destination);
    }
  };

  // Handlers for adding a new line item
  const showAddModal = (type) => {
    setAddItemType(type); // Set the type to determine where to add the item
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        const newItem = {
          id: uuidv4(),
          description: values.description,
          price:
            addItemType === "Single" ? parseFloat(values.price) : undefined,
          source: "Line Item",
          visible: true,
          isInitial: false, // Custom items are not initial
        };
        if (addItemType === "Grouped") {
          setGroupedItems([...groupedItems, newItem]);
        } else {
          setSingleItems([...singleItems, newItem]);
        }
        message.success("Line item added successfully!");
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Handler for deleting a single or grouped line item
  const handleDelete = (type, id) => {
    if (type === "Grouped") {
      setGroupedItems(groupedItems.filter((item) => item.id !== id));
    } else {
      setSingleItems(singleItems.filter((item) => item.id !== id));
    }
    message.success("Line item deleted successfully!");
  };

  // Handler for toggling visibility
  const handleToggleVisibility = (type, id) => {
    if (type === "Grouped") {
      setGroupedItems(
        groupedItems.map((item) =>
          item.id === id ? { ...item, visible: !item.visible } : item
        )
      );
    } else {
      setSingleItems(
        singleItems.map((item) =>
          item.id === id ? { ...item, visible: !item.visible } : item
        )
      );
    }
  };

const generateHtmlString = () => {
  // Subtotal is always estimate.costs.saleAmount
  const subtotal = estimate.costs.saleAmount || 0;

  // Filter visible items
  const visibleSingleItems = singleItems.filter((item) => item.visible);
  const visibleGroupedItems = groupedItems.filter((item) => item.visible);

  // Calculate Single Items Total
  const singleItemsTotal = visibleSingleItems.reduce((acc, item) => {
    return acc + (item.price || 0);
  }, 0);

  // Calculate Grouped Total (remainder after subtracting single items total from subtotal)
  const groupedTotal = subtotal - singleItemsTotal;

  // Calculate Tax Amount
  let taxAmount = 0;
  if (taxType === "%") {
    taxAmount = ((subtotal + transport) * (tax || 0)) / 100;
  } else if (taxType === "$") {
    taxAmount = tax || 0;
  } else if (taxType === "TBD") {
    taxAmount = "TBD";
  }

  // Calculate Grand Total
  let finalTotal = subtotal + transport;
  if (taxType !== "TBD") {
    finalTotal += taxAmount;
  }

  // Generate HTML rows for single items
  const singleItemsHtml = visibleSingleItems
    .map(
      (item) => `
        <tr>
          <td>${item.description}</td>
          <td class="amount-column">
            ${item.price !== undefined ? formatMoney(item.price) : ""}
          </td>
        </tr>
      `
    )
    .join("");

  // Generate HTML rows for grouped items with merged amount cell
  let groupedItemsHtml = "";

  if (visibleGroupedItems.length > 0) {
    const numGroupedItems = visibleGroupedItems.length;
    groupedItemsHtml += `
      <tr>
        <td>${visibleGroupedItems[0].description}</td>
        <td class="amount-column" rowspan="${numGroupedItems}">
          ${formatMoney(groupedTotal)}
        </td>
      </tr>
    `;
    for (let i = 1; i < numGroupedItems; i++) {
      groupedItemsHtml += `
        <tr>
          <td>${visibleGroupedItems[i].description}</td>
        </tr>
      `;
    }
  }

  // Get current date using dayjs for consistent formatting
  const today = dayjs().format("MM/DD/YYYY");
  const quoteValidityDate = quoteValidity
    ? dayjs(quoteValidity).format("MM/DD/YYYY")
    : "";
  const leadTimeFormatted = leadTime ? `${leadTime}` : "";

  // Assemble the complete HTML string
  return `
    <html>
      <head>
        <style>
          /* Import Calibri font */
          @import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;500;600&display=swap');

          /* Scoped styles under .pdf-preview */
          .pdf-preview {
            font-family: 'Calibri', sans-serif;
            margin: 0;
            padding: 0;
            position: relative;
            counter-reset: page;
          }
          .pdf-preview .quote-body {
            font-size: 12px;
            position: relative;
            z-index: 1;
            padding: 20px;
          }
          .pdf-preview .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 16px;
            page-break-after: avoid;
          }
          .pdf-preview .divider{
            width: 100%;
            height: 2px;
            background-color: #ED8293;
            margin: 16px 0; /* Increased margin for better spacing */
          }

          .pdf-preview .left-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
          }
          .pdf-preview .left-header .company-name {
            /* Removed font-size */
            margin-top: 8px; /* Add some spacing between logo and company name */
          }
          .pdf-preview .right-header {
            display: flex;
            flex-direction: column;
            align-items: flex-end; /* Align items to the right */
            width: 50%;
          }
          .pdf-preview .logo img {
            max-width: 280px;
            height: auto;
          }
          .pdf-preview .quote-title {
            font-size: 22px;
            font-weight: 600;
            text-align: right;
            margin-bottom: 8px;
          }
          .pdf-preview .quote-details {
            width: 100%;
          }
          .pdf-preview .details-table {
            text-align: right;
            border-collapse: collapse;
          }
          .pdf-preview .details-table td {
            font-size: 12px;
            padding: 2px 0px;
          }

          .pdf-preview .label-column {
            text-align: right;
            width: 40%;
            font-weight: 500; /* Apply font-weight */
          }
          .pdf-preview .value-column {
            text-align: right;
            width: 5%; 
          }
          .pdf-preview .main-content {
            width: 100%;
            display: flex;
            flex-direction: column; /* Change to column for stacking */
            align-items: center; /* Center the content */
          }
          .pdf-preview .highlight {
            background-color: #FFFF00; /* Highlight color */
            padding: 4px 8px;
            border-radius: 4px;
          }
          .pdf-preview .lead-time {
            text-align: left;
            font-size: 12px;
          }
          .pdf-preview .prepared-customer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 4px;
          }
          .pdf-preview .prepared-customer .left, .pdf-preview .prepared-customer .right {
            font-size: 12px;
          }

          .pdf-preview .bold{
            font-weight: 600;
          }
          .pdf-preview .quote-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
            /* Removed table-layout: fixed; */
          }
          .pdf-preview .quote-table th,
          .pdf-preview .quote-table td {
            border: 2px solid black;
            padding: 8px 16px;
            font-size: 12px;
          }
          .pdf-preview .quote-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-align: left;
            text-transform: uppercase;
          }
          .pdf-preview .quote-table .amount-column {
            text-align: center; /* Center align the Amount column */
            width: 110px; /* Fixed width */
          }

          /* Common class for fixed-width columns */
          .fixed-width-110 {
            width: 110px;
            text-align: center; /* Center align the text */
          }

          .pdf-preview .totals-table {
            width: 100%;
            max-width: 300px;
            border-collapse: collapse;
            margin-left: auto;
            /* Removed table-layout: fixed; */
          }
          .pdf-preview .totals-table th,
          .pdf-preview .totals-table td {
            border: 2px solid black;
            padding: 8px 16px;
            text-align: center; /* Center align the Price column */
            font-size: 12px;
          }
          .pdf-preview .totals-table td {
            background-color: #f9f9f9;
            text-transform: uppercase;
          }
          .pdf-preview .totals-table .price-column,
          .fixed-width-110 {
            width: 110px;
            text-align: center; /* Center align the text */
          }
          .pdf-preview .totals-table tr:last-child td {
            font-weight: 600;
            background-color: #f0f0f0;
          }
          /* Watermark style */
          .pdf-preview .watermark {
            position: absolute; /* Changed from fixed to absolute */
            top: 50%;
            left: 50%;
            width: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.1;
            z-index: 0;
          }
          .pdf-preview .watermark img {
            width: 100%;
            height: auto;
          }
          /* Footer style */
          .pdf-preview .footer {
            position: absolute; /* Changed from fixed to absolute */
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #666;
            z-index: 2;
          }
          .pdf-preview .footer-content {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .pdf-preview .footer-content img {
            height: 20px;
            margin-left: 5px;
          }
          .pdf-preview .page-number {
            position: absolute;
            right: 20px;
            bottom: 10px;
            font-size: 12px;
            color: #666;
          }

          /* New Styles for Company and Lead Time */
          .pdf-preview .company-lead-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .pdf-preview .company-lead-time .lead-time {
            font-size: 12px;
          }
        </style>
      </head>
      <body>
        <div class="pdf-preview">
          <!-- Content -->
          <div class="quote-body">
            <!-- Header Section -->
            <div class="header">
              <div class="left-header">
                <div class="logo">
                  <img src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png" alt="Logo" />
                </div>
                <!-- Removed company-name from here -->
              </div>
              <div class="right-header">
                <div class="quote-title">Quote</div>
                <div class="quote-details">
                  <table class="details-table">
                    <tr>
                      <td class="label-column"><span class="bold">Date:</span></td>
                      <td class="value-column">${today}</td>
                    </tr>
                    <tr>
                      <td class="label-column"><span class="bold">Quote Validity:</span></td>
                      <td class="value-column">${quoteValidityDate}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!-- Company Name and Lead Time on the Same Line -->
            <div class="company-lead-time">
              <div class="company-name">BMarko Structures, LLC</div>
              <div class="lead-time"><span class="bold">Lead Time:</span> ${leadTimeFormatted}</div>
            </div>
            <!-- Divider -->
            <div class="divider"></div>
            <!-- Prepared By and Customer Section -->
            <div class="prepared-customer">
              <div class="right"><span class="bold">Customer:</span> ${customerName}</div>
              <div class="left"><span class="bold">Prepared By:</span> ${userName}</div>
            </div>
            <div><span class="bold">Transportation to:</span> ${
              address.split(", USA")[0]
            }</div> 
            <!-- Divider -->
            <div class="divider"></div>
            <!-- Quote Items Table -->
            <table class="quote-table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th class="fixed-width-110">Amount</th>
                </tr>
              </thead>
              <tbody>
                ${singleItemsHtml}
                ${groupedItemsHtml}
              </tbody>
            </table>
            <!-- Totals Table -->
            <table class="totals-table">
              <tbody>
                <tr>
                  <td>Subtotal</td>
                  <td class="fixed-width-110">${formatMoney(subtotal)}</td>
                </tr>
                <tr>
                  <td>Transport</td>
                  <td class="fixed-width-110">${formatMoney(transport)}</td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td class="fixed-width-110">${
                    taxType === "TBD" ? "TBD" : formatMoney(taxAmount)
                  }</td>
                </tr>
                <tr>
                  <td>Grand Total</td>
                  <td class="fixed-width-110">${formatMoney(finalTotal)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Footer -->
          <div class="footer">
            <div class="footer-content">
              <!-- Add any footer content here if needed -->
            </div>
          </div>
        </div>
      </body>
    </html>
  `;
};


  // Memoize htmlString to optimize performance
  const htmlString = useMemo(
    () => generateHtmlString(),
    [
      groupedItems,
      singleItems,
      leadTime,
      quoteValidity,
      tax,
      taxType,
      transport,
      address,
      customerName,
      userName,
      estimate,
    ]
  );

  return (
    <Row gutter={[16, 16]} className={styles.container}>
      {/* Left Column: Form and Line Items */}
      <Col xs={24} lg={12}>
        {/* Quote Inputs Section */}
        <Collapse
          defaultActiveKey={["quoteInputs"]}
          className={styles.collapse}
          style={{ marginTop: 0, marginBottom: 8 }}
          size="small"
        >
          <Panel header="Quote Inputs" key="quoteInputs">
            {/* Form Fields */}
            <Row gutter={[16, 16]}>
              {/* Lead Time Field */}
              <Col xs={24} sm={12}>
                <label htmlFor="leadTime">Lead Time</label>
                <Input
                  id="leadTime"
                  placeholder="Enter Lead Time"
                  value={leadTime}
                  onChange={(e) => setLeadTime(e.target.value)}
                />
              </Col>

              {/* Quote Validity Field */}
              <Col xs={24} sm={12}>
                <label htmlFor="quoteValidity">Quote Validity</label>
                <DatePicker
                  id="quoteValidity"
                  value={quoteValidity}
                  onChange={(date) => setQuoteValidity(date)}
                  style={{ width: "100%" }}
                />
              </Col>

              {/* Sales Tax Field */}
              <Col xs={24} sm={12}>
                <label>Sales Tax</label>
                <Row gutter={8}>
                  <Col span={6}>
                    <Select
                      value={taxType}
                      onChange={handleTaxTypeChange}
                      style={{ width: "100%" }}
                    >
                      <Option value="%">%</Option>
                      <Option value="$">$</Option>
                      <Option value="TBD">TBD</Option>
                    </Select>
                  </Col>
                  <Col span={18}>
                    {taxType !== "TBD" ? (
                      <InputNumber
                        type="number"
                        placeholder={taxType === "%" ? "Tax (%)" : "Tax ($)"}
                        value={tax}
                        style={{ width: "100%" }}
                        onChange={(value) => setTax(parseFloat(value))}
                      />
                    ) : (
                      <Input
                        placeholder="TBD"
                        value="TBD"
                        style={{ width: "100%" }}
                        disabled
                      />
                    )}
                  </Col>
                </Row>
              </Col>

              {/* Transport Field */}
              <Col xs={24} sm={12}>
                <label htmlFor="transport">Transport</label>
                <InputNumber
                  id="transport"
                  type="number"
                  placeholder="Enter Transport Cost"
                  prefix="$"
                  style={{ width: "100%" }}
                  value={transport}
                  onChange={(value) => setTransport(parseFloat(value))}
                />
              </Col>
              {/* Address Autocomplete Field */}
              <Col xs={24}>
                <label htmlFor="transportAddress">Transportation Address</label>
                <AddressAutocomplete
                  form={form}
                  value={address}
                  onChange={(value) => {
                    setAddress(value); // Update the state with the selected address
                  }}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>

        {/* Line Items Section */}
        <Collapse
          size="small"
          defaultActiveKey={["lineItems"]}
          className={styles.collapse}
          style={{ marginTop: 0 }}
        >
          <Panel header="Line Items" key="lineItems">
            {/* Drag and Drop Context */}
            <DragDropContext onDragEnd={onDragEnd}>
              {/* Grouped Items Section */}
              <Card
                title="Grouped Items"
                size="small"
                style={{ marginBottom: 16 }}
                extra={
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => showAddModal("Grouped")}
                    size="small"
                  >
                    Add Line Item
                  </Button>
                }
              >
                <Droppable droppableId="groupedItems">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={styles.droppableArea}
                      style={{
                        background: snapshot.isDraggingOver
                          ? "#e6f7ff"
                          : "#f0f0f0",
                        padding: "8px",
                        minHeight: "100px",
                      }}
                    >
                      {groupedItems.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`${styles.draggableItem} ${
                                snapshot.isDragging ? styles.dragging : ""
                              }`}
                              style={{
                                userSelect: "none",
                                padding: 8,
                                marginBottom: 8,
                                background: "#fff",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <div
                                {...provided.dragHandleProps}
                                style={{ marginRight: 8, cursor: "move" }}
                              >
                                <DragOutlined />
                              </div>
                              {item.source && (
                                <Tag
                                  color="geekblue"
                                  style={{ marginRight: 8 }}
                                >
                                  {item.source}
                                </Tag>
                              )}
                              <Input.TextArea
                                value={item.description}
                                autoSize
                                onChange={(e) => {
                                  const newDescription = e.target.value;
                                  setGroupedItems((prev) =>
                                    prev.map((groupedItem) =>
                                      groupedItem.id === item.id
                                        ? {
                                            ...groupedItem,
                                            description: newDescription,
                                          }
                                        : groupedItem
                                    )
                                  );
                                }}
                                placeholder="Description"
                                style={{ flex: 1, marginRight: 8 }}
                                disabled={item.isInitial} // Disable if initial
                              />
                              {/* No price input for grouped items */}
                              {/* Eye and Trash Icons */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: 4,
                                }}
                              >
                                <Button
                                  type="text"
                                  size="small"
                                  icon={
                                    item.visible ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )
                                  }
                                  onClick={() =>
                                    handleToggleVisibility("Grouped", item.id)
                                  }
                                />
                                {item.isInitial ? null : (
                                  <Button
                                    type="text"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                      handleDelete("Grouped", item.id)
                                    }
                                    danger
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Card>

              {/* Single Items Section */}
              <Card
                title="Single Items"
                size="small"
                style={{ marginBottom: 16 }}
                extra={
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => showAddModal("Single")}
                    size="small"
                  >
                    Add Line Item
                  </Button>
                }
              >
                <Droppable droppableId="singleItems">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={styles.droppableArea}
                      style={{
                        background: snapshot.isDraggingOver
                          ? "#e6f7ff"
                          : "#f0f0f0",
                        padding: "8px",
                        minHeight: "100px",
                      }}
                    >
                      {singleItems.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`${styles.draggableItem} ${
                                snapshot.isDragging ? styles.dragging : ""
                              }`}
                              style={{
                                userSelect: "none",
                                padding: 8,
                                marginBottom: 8,
                                background:
                                  item.price === 0 ? "#ffd6d6" : "#fff",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <div
                                {...provided.dragHandleProps}
                                style={{ marginRight: 8, cursor: "move" }}
                              >
                                <DragOutlined />
                              </div>
                              {item.source && (
                                <Tag
                                  color="geekblue"
                                  style={{ marginRight: 8 }}
                                >
                                  {item.source}
                                </Tag>
                              )}
                              <Input.TextArea
                                autoSize
                                value={item.description}
                                onChange={(e) => {
                                  const newDescription = e.target.value;
                                  setSingleItems((prev) =>
                                    prev.map((single) =>
                                      single.id === item.id
                                        ? {
                                            ...single,
                                            description: newDescription,
                                          }
                                        : single
                                    )
                                  );
                                }}
                                placeholder="Description"
                                style={{ flex: 1, marginRight: 8 }}
                                disabled={item.isInitial} // Disable if initial
                              />
                              <InputNumber
                                value={item.price}
                                onChange={(value) => {
                                  setSingleItems((prev) =>
                                    prev.map((single) =>
                                      single.id === item.id
                                        ? {
                                            ...single,
                                            price: parseFloat(value),
                                          }
                                        : single
                                    )
                                  );
                                }}
                                formatter={(value) =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                style={{ width: "100px", marginRight: 8 }}
                                disabled={item.isInitial} // Disable if initial
                              />
                              {/* Eye and Trash Icons */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: 4,
                                }}
                              >
                                <Button
                                  type="text"
                                  size="small"
                                  icon={
                                    item.visible ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )
                                  }
                                  onClick={() =>
                                    handleToggleVisibility("Single", item.id)
                                  }
                                />
                                {item.isInitial ? null : (
                                  <Button
                                    type="text"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                      handleDelete("Single", item.id)
                                    }
                                    danger
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Card>
            </DragDropContext>
          </Panel>
        </Collapse>

        {/* Generate PDF Button */}
        <Row style={{ marginTop: "16px" }}>
          <Col>
            <Button
              type="primary"
              onClick={async () => {
                try {
                  const result = await generatePdf(htmlString, "quote.pdf");
                  // Handle the result as needed, e.g., display a success message or open the PDF URL
                  message.success("PDF generated successfully!");
                  window.open(result.location, "_blank"); // Opens the PDF in a new tab
                } catch (error) {
                  console.error("Error generating PDF:", error);
                  message.error("Failed to generate PDF.");
                }
              }}
            >
              Generate PDF
            </Button>
          </Col>
        </Row>
      </Col>

      {/* Right Column: HTML Preview */}
      <Col xs={24} lg={12} className={styles.stickyPreview}>
        <div
          className={styles.previewContainer}
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      </Col>

      {/* Add Line Item Modal */}
      <Modal
        title="Add Line Item"
        visible={isModalVisible}
        onOk={handleAdd}
        onCancel={handleCancel}
        okText="Add"
      >
        <Form form={form} layout="vertical" name="add_line_item">
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the description!" },
            ]}
          >
            <Input placeholder="Enter Description" />
          </Form.Item>
          {addItemType === "Single" && (
            <Form.Item
              name="price"
              label="Price"
              rules={[
                { required: true, message: "Please enter the price!" },
                {
                  type: "number",
                  min: 0,
                  message: "Price cannot be negative",
                },
              ]}
            >
              <InputNumber
                placeholder="Enter Price"
                prefix="$"
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </Row>
  );
};

export default QuotePDF;
