import React, { useState } from "react";
import { Table, Row, Col, Tag, Badge } from "antd";
import { usePurchaseOrders, useVendors } from "../../Contexts/useSpecificData";
import { formatMoney } from "../../Formatters/helpers";
import dayjs from "dayjs";
import { getVendorName } from "../../Formatters/getVendorName";
import { SearchInput } from "../../Styled/SearchInput";
import { Link } from "react-router-dom";
import { COLORS } from "../../constants";
import { Loader } from "../../Styled/Loader";

const ReturnTable = () => {
  const { data: purchaseOrders, loading } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  // Filter to get only the purchase orders that have a returnOrder and are not closed
  const returnOrders = purchaseOrders
    .filter((po) => po.returnOrder)
    .filter((po) => {
      // Check if any of the lines are still open (qtyShipped !== qtyReturn)
      return po.lines.some((line) => line.qtyShipped !== line.qtyReturn);
    });

  // Filter and search logic
  const filteredData = returnOrders
    .filter(
      (order) =>
        ((filteredInfo.vendor
          ? filteredInfo.vendor.includes(order.vendor)
          : true) &&
          order.roNumber &&
          order.roNumber.toLowerCase().includes(searchText.toLowerCase())) ||
        order.poNumber.toLowerCase().includes(searchText.toLowerCase()) ||
        getVendorName(order.vendor, vendors)
          .toLowerCase()
          .includes(searchText.toLowerCase())
    )
    .sort(
      (a, b) =>
        new Date(b.returnOrder.orderedDate) -
        new Date(a.returnOrder.orderedDate)
    );

  const uniqueVendors = [...new Set(filteredData.map((order) => order.vendor))];

  const vendorFilters = uniqueVendors.map((vendorId) => ({
    text: getVendorName(vendorId, vendors),
    value: vendorId,
  }));

  // Handle row expansion on double click
  const handleRowDoubleClick = (record) => {
    const key = record.id;
    setExpandedRowKeys((prevExpandedRowKeys) =>
      prevExpandedRowKeys.includes(key)
        ? prevExpandedRowKeys.filter((k) => k !== key)
        : [...prevExpandedRowKeys, key]
    );
  };

  // Define columns for the table
  const columns = [
    {
      title: "RO Number",
      dataIndex: "roNumber",
      key: "roNumber",
      sorter: (a, b) => (a.roNumber || "").localeCompare(b.roNumber || ""),
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Badge
            count={record.lines.filter((line) => line.qtyReturn > 0).length}
            style={{ backgroundColor: COLORS.PRIMARY, marginRight: 8 }}
          />
          <Link to={`/purchasing/returns/edit-return-orders/${record.id}`}>
            {text}
          </Link>
        </div>
      ),
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (vendorId) => getVendorName(vendorId, vendors),
      filters: vendorFilters,
      filteredValue: filteredInfo.vendor || null,
      onFilter: (value, record) => record.vendor === value,
    },
    {
      title: "Ordered Date",
      dataIndex: "orderedDate",
      key: "orderedDate",
      render: (_, record) =>
        record.returnOrder?.orderedDate
          ? dayjs(record.returnOrder.orderedDate).format("MM/DD/YYYY")
          : "N/A",
      sorter: (a, b) =>
        new Date(b.returnOrder.orderedDate) -
        new Date(a.returnOrder.orderedDate),
      sortOrder: sortedInfo.columnKey === "orderedDate" && sortedInfo.order,
    },
    {
      title: "Required Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      render: (_, record) =>
        record.returnOrder?.requiredDate
          ? dayjs(record.returnOrder.requiredDate).format("MM/DD/YYYY")
          : "N/A",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, record) => {
        const totalReturn = record.lines
          .filter((line) => line.qtyReturn > 0)
          .reduce((acc, line) => acc + line.qtyReturn * line.itemPrice, 0);
        return formatMoney(totalReturn);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const allShippedEqualReturned = record.lines.every(
          (line) => line.qtyShipped === line.qtyReturn
        );
        return (
          <Tag color={allShippedEqualReturned ? "green" : "blue"}>
            {allShippedEqualReturned ? "Closed" : "Open"}
          </Tag>
        );
      },
    },
  ];

  // Define columns for the expanded rows (lines with qtyReturn)
  const expandedRowRender = (record) => {
    const lineColumns = [
      {
        title: "Line Number",
        dataIndex: "lineNumber",
        key: "lineNumber",
        className: "expanded-row-header",
      },
      {
        title: "Item Description",
        dataIndex: "itemDescription",
        key: "itemDescription",
        className: "expanded-row-header",
      },
      {
        title: "Qty Ordered",
        dataIndex: "qtyOrdered",
        key: "qtyOrdered",
        className: "expanded-row-header",
      },
      {
        title: "Qty to Return",
        dataIndex: "qtyReturn",
        key: "qtyReturn",
        className: "expanded-row-header",
      },
      {
        title: "Qty Shipped",
        dataIndex: "qtyShipped",
        key: "qtyShipped",
        className: "expanded-row-header",
      },
      {
        title: "Item Price",
        dataIndex: "itemPrice",
        key: "itemPrice",
        render: (price) => formatMoney(price),
        className: "expanded-row-header",
      },
      {
        title: "Return Total",
        dataIndex: "returnTotal",
        key: "returnTotal",
        render: (_, line) => formatMoney(line.qtyReturn * line.itemPrice),
        className: "expanded-row-header",
      },
    ];

    const sortedLines = [...record.lines]
      .filter((line) => line.qtyReturn > 0)
      .sort((a, b) => {
        const numA = parseInt(a.lineNumber, 10);
        const numB = parseInt(b.lineNumber, 10);
        return numA - numB;
      });

    return (
      <Table
        columns={lineColumns}
        dataSource={sortedLines}
        pagination={false}
        rowKey="lineNumber"
        size="small"
        rowClassName={"expanded-row"}
      />
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <SearchInput
          placeholder="Search PO Number, Vendor, or RO Number"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: 16 }}
        />
      </Col>
      <Col span={24}>
        {loading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            expandable={{
              expandedRowRender,
              expandedRowKeys,
              onExpand: (expanded, record) => {
                const keys = expanded
                  ? [...expandedRowKeys, record.id]
                  : expandedRowKeys.filter((key) => key !== record.id);
                setExpandedRowKeys(keys);
              },
            }}
            dataSource={filteredData}
            rowKey="id"
            pagination={{ defaultPageSize: 10 }}
            onChange={handleChange}
            size="small"
            className="small-table"
            rowClassName={
              (record, index) =>
                expandedRowKeys.includes(record.id) ? "expanded-header" : "" // Add class when row is expanded
            }
            onRow={(record) => ({
              onDoubleClick: () => handleRowDoubleClick(record),
            })}
          />
        )}
      </Col>
    </Row>
  );
};

export default ReturnTable;
