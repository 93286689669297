import React, { useState } from "react";
import { Modal, Button, Table, Checkbox, Select } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { getVendorName } from "../../Formatters/getVendorName";

const { Option } = Select;

const DuplicatePOModal = ({
  isVisible,
  onClose,
  purchaseOrders,
  onDuplicate,
  vendors,
}) => {
  const [selectedPO, setSelectedPO] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const handlePOChange = (poId) => {
    const po = purchaseOrders.find((po) => po.id === poId);
    setSelectedPO(po);
    setSelectedItems(po ? po.lines.map((line) => line.itemId) : []);
  };

  const itemColumns = [
    {
      title: "Select",
      dataIndex: "itemId", // Use itemId here for the key
      render: (itemId) => (
        <Checkbox
          checked={selectedItems.includes(itemId)}
          onChange={() => handleSelectItem(itemId)}
        />
      ),
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "Qty",
      dataIndex: "qtyOrdered",
      key: "qtyOrdered",
    },
  ];

  const handleSelectItem = (itemId) => {
    setSelectedItems((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId]
    );
  };

  const handleDuplicate = () => {
    if (!selectedPO) return;

    // Filter lines to include only those selected
    const filteredLines = selectedPO.lines.filter((line) =>
      selectedItems.includes(line.itemId)
    );
    onDuplicate(filteredLines, selectedPO.vendor, selectedPO);
    onClose();
  };

  const sortedPurchaseOrders = [...purchaseOrders].sort((a, b) =>
    b.poNumber.localeCompare(a.poNumber)
  );

  return (
    <Modal
      title="Duplicate Purchase Order"
      open={isVisible}
      onCancel={onClose}
      width="80%"
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleDuplicate}
          disabled={!selectedItems.length}
        >
          Duplicate
        </Button>,
      ]}
    >
      <Select
        style={{ width: "100%", marginBottom: 16 }}
        placeholder="Select a purchase order"
        onChange={handlePOChange}
        value={selectedPO ? selectedPO.id : null}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {sortedPurchaseOrders.map((po) => (
          <Option key={po.id} value={po.id}>
            {`${po.poNumber} - ${getVendorName(po.vendor, vendors)}`}
          </Option>
        ))}
      </Select>
      {selectedPO && (
        <Table
          dataSource={selectedPO.lines}
          columns={itemColumns}
          rowKey="itemId" // Ensure rowKey is set to itemId for unique identification
          pagination={false}
        />
      )}
    </Modal>
  );
};

export default DuplicatePOModal;
