export const getPoPrice = (purchaseOrder) => {
  const lineTotal = purchaseOrder.lines.reduce((sum, line) => {
    return sum + line.itemPrice * line.qtyOrdered;
  }, 0);

  const shippingCosts = parseFloat(purchaseOrder.shippingCosts) || 0;
  const otherCosts = parseFloat(purchaseOrder.other) || 0;
  const discount = parseFloat(purchaseOrder.discount) || 0;

  return lineTotal + shippingCosts + otherCosts - discount;
};
