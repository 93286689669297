import { theme } from "antd";

export const antdTheme = {
  token: {
    colorPrimary: "#557FCB",
    colorLink: "#557FCB",
    colorLinkActive: "#557FCB",
    colorLinkHover: "#557FCB",

    colorIcon: "#557FCB",
    colorIconHover: "#557FCB",
    colorInfo: "#557FCB",
  },
};
