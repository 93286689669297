import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/aria/s3Upload";

export async function s3Upload(file, fileName) {
  console.log("Uploading file:", fileName);
  console.log("API_BASE_URL:", API_BASE_URL);
  try {
    // Convert the file content to a base64 string
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        const base64data = reader.result.split(",")[1];

        // Get the file type (MIME type)
        const fileType = file.type;

        // Prepare the payload for the API request
        const payload = {
          fileContent: base64data,
          fileName: fileName, // Use the fileName passed as a parameter
          fileType: fileType, // Include the file type in the payload
        };

        console.log("Payload:", payload);

        try {
          // Send a POST request to your Lambda function
          const response = await axios.post(API_BASE_URL, payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          // Handle the response
          if (response.status === 200) {
            const { fileUrl } = response.data;
            resolve(fileUrl); // Return the URL of the uploaded file
          } else {
            reject(`Upload failed with status code: ${response.status}`);
          }
        } catch (error) {
          reject(
            `Error uploading file: ${
              error.response ? error.response.statusText : error.message
            }`
          );
        }
      };
      reader.onerror = (error) => reject(error);
    });
  } catch (error) {
    throw new Error("File upload failed");
  }
}
