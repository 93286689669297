import React, { createContext, useContext } from "react";
import { useMediaQuery } from "react-responsive";

// Create the context
const MediaQueryContext = createContext();

export const MediaQueryProvider = ({ children }) => {
  // Define your media queries using react-responsive
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });

  // Pass down these values in the context
  return (
    <MediaQueryContext.Provider value={{ isMobile, isTablet, isDesktop }}>
      {children}
    </MediaQueryContext.Provider>
  );
};

// Custom hook to use the MediaQuery context
export const useMediaQueryContext = () => {
  return useContext(MediaQueryContext);
};
