import React, { useState, useEffect } from "react";
import { Card, Select, Spin, Button } from "antd";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import dayjs from "dayjs";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getProjectName } from "../../Formatters/getProjectName";
import { StopOutlined } from "@ant-design/icons";
import { COLORS } from "../../constants";
import { Loader } from "../../Styled/Loader";

const { Option } = Select;

function ReceiveReturn() {
  const [filteredPurchaseOrders, setFilteredPurchaseOrders] = useState([]);
  const [selectedPO, setSelectedPO] = useState(null);
  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors, loading: vendorsLoading } = useVendors();
  const { data: projects } = useProjects();

  useEffect(() => {
    if (!poLoading && !vendorsLoading && purchaseOrders.length > 0) {
      const filteredPOs = purchaseOrders
        .filter((po) =>
          po.lines.some((line) => line.qtyShipped < line.qtyReturn)
        )
        .sort((a, b) => {
          const dateA = a.returnOrder
            ? a.returnOrder.orderedDate
            : a.orderedDate;
          const dateB = b.returnOrder
            ? b.returnOrder.orderedDate
            : b.orderedDate;
          return dayjs(dateB).diff(dayjs(dateA));
        });

      setFilteredPurchaseOrders(filteredPOs);
    }
  }, [purchaseOrders, vendors, poLoading, vendorsLoading]);

  const handleSelect = (value) => {
    const po = filteredPurchaseOrders.find((po) => po.id === value);
    setSelectedPO(po);
  };

  if (poLoading || vendorsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Select a Return Order"
        optionFilterProp="children"
        allowClear
        dropdownStyle={{ maxHeight: 400, overflowY: "auto" }} // Prevents page scrolling on mobile
        getPopupContainer={(triggerNode) => triggerNode.parentNode} // Ensures dropdown scrolls independently
        onSelect={handleSelect} // Handle selection
      >
        {filteredPurchaseOrders.map((po) => (
          <Option key={po.id} value={po.id}>
            {`${po.roNumber} - ${getVendorName(po.vendor, vendors)}`}
          </Option>
        ))}
      </Select>

      {selectedPO && (
        <>
          <Card style={{ marginTop: 16 }}>
            <p>
              <strong>Project:</strong>{" "}
              {getProjectName(selectedPO.projectNumber, projects)}
            </p>
            <p>
              <strong>Buyer:</strong> {selectedPO.buyer}
            </p>
            <p>
              <strong>Required Date:</strong>{" "}
              {dayjs(selectedPO.returnOrder.requiredDate).format("MM/DD/YYYY")}
            </p>
            <p>
              <strong>Notes:</strong>{" "}
              {selectedPO.returnOrder.poNotes || (
                <StopOutlined
                  style={{ color: COLORS.WARNING, marginLeft: 8 }}
                />
              )}
            </p>
          </Card>
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            href={`/receiving/receive/return-orders/${selectedPO.id}`}
            style={{ marginTop: 16, width: "100%" }}
          >
            Next
          </Button>
        </>
      )}
    </>
  );
}

export default ReceiveReturn;
