import React, {useEffect} from "react";
import { Row, Col } from "antd";
import ExpectedTableCard from "../../Components/Dashboard/ExpectedTableCard";
import LateTableCard from "../../Components/Dashboard/LateTableCard";
import ReceivedTableCard from "../../Components/Dashboard/ReceivedTableCard";
import { usePurchaseOrders, useVendors, useProjects } from "../../Contexts/useSpecificData";
import { Loader } from "../../Styled/Loader";

const Dashboard = () => {
  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();  

      useEffect(() => {
      document.title = "Dashboard - Purchasing"; // Set the tab title
    }, []);

  if (poLoading) {
    return <Loader />;
  }



  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col span={6} style={{ fontSize: 24, fontWeight: 600 }}>
          <div>Dashboard</div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ExpectedTableCard
            purchaseOrders={purchaseOrders}
            vendors={vendors}
            projects={projects}
          />
        </Col>
        <Col span={24}>
          <LateTableCard purchaseOrders={purchaseOrders} vendors={vendors} projects={projects} />
        </Col>
        <Col span={24}>
          <ReceivedTableCard
            purchaseOrders={purchaseOrders}
            vendors={vendors}
            projects={projects}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
