import { addItemsToTable } from "./addAria";
import { TABLES } from "../constants";
import { updateById } from "./updateById";

/**
 * Adds a new assembly to the 'aria-assemblies' table.
 * @param {Object} assemblyData The assembly details to be added.
 * @returns {Promise<any>}
 */
export const addAssembly = async (assemblyData) => {
  try {
    const response = await addItemsToTable(TABLES.ASSEMBLIES, assemblyData);
    return response;
  } catch (error) {
    console.error("Failed to add assembly:", error);
    throw error;
  }
};
