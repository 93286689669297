// src/Components/YourPath/AddEstimateModal.jsx

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Switch,
  message,
  Tag,
  Card,
} from "antd";
import dayjs from "dayjs"; // Replace moment with dayjs
import { updateById } from "../../Functions/updateById";
import { addItemsToTable } from "../../Functions/addAria";
import { COLORS, TABLES } from "../../constants";
import { getEstimateNumber } from "../../Functions/getEstimateNumber";
import { ESTIMATE_STATUS } from "../../constants";
import { useCustomers } from "../../Contexts/useSpecificData";
import { getCustomerName } from "../../Formatters/getCustomerName";

const { Option } = Select;

const AddEstimateModal = ({ children, estimate, onAddSuccess, title }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);

  // Fetch existing customers
  const { data: customers } = useCustomers();

  useEffect(() => {
    if (visible) {
      if (estimate) {
        form.setFieldsValue({
          estimateName: estimate.estimateName,
          estimateDescription: estimate.estimateDescription,
          estimateStatus: estimate.estimateStatus || "Pre-Hand off",
          handedOff: estimate.handedOff,
          estimated: estimate.estimated,
          quoteBy: estimate.quoteBy ? dayjs(estimate.quoteBy) : null, // Use dayjs here
          assignedTo: estimate.assignedTo,
          customerId: estimate.customerId || estimate.customer?.id || null,
        });
        setIsNewCustomer(false);
      } else {
        form.resetFields();
        setIsNewCustomer(false);
      }
    }
  }, [estimate, form, visible]);

  const handleOk = async () => {
    setLoading(true);
    try {
      // Validate form fields
      const values = await form.validateFields();
      console.log("Submitted Values:", values);

      let customerId = values.customerId;

      // If 'New' customer is selected, create a new customer
      if (isNewCustomer) {
        const newCustomer = {
          companyName: values.companyName,
          contactEmail: values.contactEmail,
          contactFirstName: values.contactFirstName,
          contactLastName: values.contactLastName,
          contactPhoneNumber: values.contactPhoneNumber,
        };

        const customerResponse = await addItemsToTable(
          TABLES.CUSTOMERS,
          newCustomer
        );
        customerId = customerResponse.id;
      }

      // Prepare estimate data
      const estimateData = {
        estimateName: values.estimateName,
        estimateDescription: values.estimateDescription,
        estimateStatus: values.estimateStatus || "Pre-Hand off",
        quoteBy: values.quoteBy ? values.quoteBy.toISOString() : null, // dayjs has toISOString
        assignedTo: values.assignedTo,
        customerId, // Save customerId instead of customer object
      };

      if (estimate && estimate.id) {
        // Update existing estimate
        await updateById(TABLES.ESTIMATES, estimateData, estimate.id);
        estimateData.id = estimate.id; // Ensure the ID is set for onAddSuccess
      } else {
        // Create new estimate

        // **Initialize Base Costs Here**
        const baseCosts = {
          contingency: 5, // 5%
          profitMargin: 50, // 50%
          saleAmount: 0, // $0
          profit: 0, // $0
        };

        const newEstimateNumber = await getEstimateNumber();
        estimateData.estimateNumber = newEstimateNumber;

        estimateData.costs = baseCosts;

        const newEstimate = await addItemsToTable(
          TABLES.ESTIMATES,
          estimateData
        );
        estimateData.id = newEstimate.id;
      }

      setVisible(false);
      onAddSuccess(estimateData);
      setLoading(false);
    } catch (error) {
      console.error("Validation Failed:", error);
      message.error("Please fill in all required fields.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setIsNewCustomer(false);
  };

  // Sort customers A-Z by companyName
  const sortedCustomers = customers
    ? [...customers].sort((a, b) => a.companyName.localeCompare(b.companyName))
    : [];

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: () => setVisible(true) })
      )}
      <Modal
        title={title || (estimate ? "Edit Estimate" : "Add New Estimate")}
        visible={visible}
        onOk={handleOk}
        width={700}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {estimate ? "Save Changes" : "Add Estimate"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item
                name="estimateName"
                label="Estimate Name"
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              {/* Removed Estimate Status Field if not needed */}
              <Form.Item name="quoteBy" label="Quote By">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name="assignedTo" label="Assign To">
                <Select style={{ width: "100%" }}>
                  <Option value="Matt McConnell">Matt McConnell</Option>
                  <Option value="Scott Tippins">Scott Tippins</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estimateDescription"
                label="Description"
                rules={[
                  { required: true, message: "Please enter a description" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          {/* Customer Selection */}
          <Card
            title="Customer"
            extra={
              <>
                <span
                  style={{
                    marginRight: 8,
                    fontStyle: "italic",
                    opacity: !isNewCustomer ? 1 : 0.7,
                    color: !isNewCustomer ? COLORS.PRIMARY : "black",
                  }}
                >
                  Existing Customer
                </span>
                <Switch
                  checked={isNewCustomer}
                  onChange={(checked) => setIsNewCustomer(checked)}
                />
                <span
                  style={{
                    marginLeft: 8,
                    fontStyle: "italic",
                    opacity: isNewCustomer ? 1 : 0.7,
                    color: isNewCustomer ? COLORS.PRIMARY : "black",
                  }}
                >
                  New Customer
                </span>
              </>
            }
            style={{ marginBottom: 16 }}
          >
            {!isNewCustomer ? (
              <Form.Item
                name="customerId"
                label="Company Name"
                rules={[
                  { required: true, message: "Please select a customer" },
                ]}
              >
                <Select
                  placeholder="Select a customer"
                  showSearch
                  optionFilterProp="children"
                >
                  {sortedCustomers.map((customer) => (
                    <Option key={customer.id} value={customer.id}>
                      {customer.companyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <>
                {/* New Customer Fields */}
                <Form.Item
                  name="companyName"
                  label="Company Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="contactFirstName"
                  label="Contact First Name"
                  rules={[
                    { required: true, message: "Please enter first name" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="contactLastName"
                  label="Contact Last Name"
                  rules={[
                    { required: true, message: "Please enter last name" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="contactEmail"
                  label="Contact Email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    { type: "email", message: "Enter a valid email" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="contactPhoneNumber"
                  label="Contact Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
          </Card>

          <Row gutter={16}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddEstimateModal;
