import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Button, Select, Row, Col } from "antd";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES, ITEM_CATEGORIES, EXPENSE_ACCOUNTS } from "../../constants";
import { getVendorName } from "../../Formatters/getVendorName";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

const AddItemModal = ({
  children,
  onAddSuccess,
  item,
  visible,
  setVisible,
  items,
  vendors = [], // Default to an empty array if vendors is not provided
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [expenseId, setExpenseId] = useState("");
  const [uom, setUom] = useState("EA");

  useEffect(() => {
    if (visible) {
      if (item) {
        form.setFieldsValue({
          category: item.category,
          itemDescription: item.itemDescription,
          manufacturer: item.manufacturer,
          manufacturerId: item.manufacturerId,
          orderBatchSize: item.orderBatchSize,
          uom: item.uom,
          expenseId: item.ExpenseId,
        });
        setCategory(item.category);
        setItemNumber(item.itemNumber);
        setExpenseId(item.ExpenseId);
        setUom(item.uom);
        console.log(item);
      } else {
        resetForm();
      }
    }
  }, [visible, item, form]);

  const generateItemNumber = (category) => {
    const prefix = ITEM_CATEGORIES[category]?.prefix || "";
    const categoryItems = items.filter(
      (item) => item.itemNumber && item.itemNumber.startsWith(prefix)
    );
    const numbers = categoryItems.map((item) =>
      parseInt(item.itemNumber.split("-")[1], 10)
    );
    const maxNumber = Math.max(...numbers, 0);
    const newItemNumberStr = String(maxNumber + 1).padStart(4, "0");
    setItemNumber(`${prefix}${newItemNumberStr}`);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
    generateItemNumber(value);
    const expenseAcc = ITEM_CATEGORIES[value]?.expenseAccount;
    if (EXPENSE_ACCOUNTS.includes(expenseAcc)) {
      form.setFieldsValue({ expenseId: expenseAcc });
      setExpenseId(expenseAcc);
    } else {
      form.setFieldsValue({ expenseId: undefined });
      setExpenseId("");
    }
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // Convert specific fields to uppercase
      values.itemDescription = values.itemDescription.toUpperCase();
      values.manufacturer = values.manufacturer.toUpperCase();
      values.manufacturerId = values.manufacturerId.toUpperCase();

      values.itemNumber = itemNumber;
      values.ExpenseId = expenseId;
      values.uom = uom;

      if (!item) {
        const vendor = {
          id: values.vendor,
          price: parseFloat(values.price),
          itemId: uuidv4(),
        };
        values.vendors = [vendor];
        delete values.price;
        delete values.vendor;
      }

      if (item) {
        await updateById(TABLES.ITEMS, values, item.id);
      } else {
        await addItemsToTable(TABLES.ITEMS, values);
      }
      setLoading(false);
      setVisible(false);
      onAddSuccess(values);
    } catch (error) {
      setLoading(false);
      console.error("Validation Failed:", error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    resetForm();
  };

  const resetForm = () => {
    form.resetFields();
    setCategory("");
    setItemNumber("");
    setExpenseId("");
    setUom("EA");
  };

  const handleBatchSizeChange = (value) => {
    form.setFieldsValue({ uom: value === 1 ? "EA" : "PCS" });
    setUom(value === 1 ? "EA" : "PCS");
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: () => setVisible(true) })
      )}
      <Modal
        title={item ? "Edit Item" : "Add New Item"}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {item ? "Save Changes" : "Add Item"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Please select a category" }]}
          >
            <Select
              placeholder="Select Category"
              onChange={handleCategoryChange}
              disabled={!!item}
              showSearch
            >
              {Object.keys(ITEM_CATEGORIES).map((cat) => (
                <Option key={cat} value={cat}>
                  {cat}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Item Number">
                <Input value={itemNumber} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="expenseId"
                label="Expense Account"
                rules={[
                  {
                    required: true,
                    message: "Please select an expense account",
                  },
                ]}
              >
                <Select
                  placeholder="Select Expense Account"
                  value={expenseId}
                  onChange={(value) => setExpenseId(value)}
                  allowClear
                  showSearch
                >
                  {EXPENSE_ACCOUNTS.map((account) => (
                    <Option key={account} value={account}>
                      {account}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="itemDescription"
            label="Item Description"
            rules={[
              { required: true, message: "Please enter the item description" },
            ]}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="manufacturer"
            label="Manufacturer"
            rules={[
              { required: true, message: "Please enter the manufacturer" },
            ]}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Form.Item
            name="manufacturerId"
            label="Manufacturer ID"
            rules={[
              { required: true, message: "Please enter the manufacturer ID" },
            ]}
          >
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="orderBatchSize"
                label="Order Batch Size"
                rules={[
                  {
                    required: true,
                    message: "Please enter the order batch size",
                  },
                ]}
              >
                <Input
                  type="number"
                  onChange={(e) =>
                    handleBatchSizeChange(parseInt(e.target.value))
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="uom"
                label="Unit of Measure"
                rules={[
                  {
                    required: true,
                    message: "Please enter the unit of measure",
                  },
                ]}
              >
                <Input value={uom} disabled />
              </Form.Item>
            </Col>
          </Row>
          {!item && (
            <>
              <Form.Item
                name="vendor"
                label="Vendor"
                rules={[{ required: true, message: "Please select a vendor" }]}
              >
                <Select
                  placeholder="Select Vendor"
                  allowClear
                  showSearch
                  optionFilterProp="children" // Allows search to work based on the option's children (the displayed vendor name)
                  filterOption={
                    (input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) // Custom filter for searching
                  }
                >
                  {vendors
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) =>
                      getVendorName(a.id, vendors).localeCompare(
                        getVendorName(b.id, vendors)
                      )
                    ) // Sort vendors alphabetically by name
                    .map((vendor) => (
                      <Option key={vendor.id} value={vendor.id}>
                        {getVendorName(vendor.id, vendors)}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  {
                    required: true,
                    message: "Please enter the price",
                  },
                ]}
              >
                <Input type="number" prefix="$" />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddItemModal;
