import dayjs from "dayjs";

/**
 * Retrieves the last purchased date and price for a given item.
 * Searches in the provided 'items' array for a matching item by 'id'.
 * @param {Object} item - The item object to search by.
 * @param {Array} items - Array of items to search for the matching item by 'id'.
 * @returns {Object} - Contains lastPurchasedDate and lastPurchasedPrice.
 */
export const getLastPurchasedItem = (item, items) => {
  // Find the matching item in the items array
  const matchingItem = items.find((itm) => itm.id === item.id);

  if (!matchingItem) {
    // If no matching item is found, return null for both properties
    return {
      lastPurchasedDate: null,
      lastPurchasedPrice: null,
    };
  }

  // Get lastPurchasedPrice directly from the item
  const lastPurchasedPrice = matchingItem.lastPurchasedPrice ?? null;

  // Get lastPurchasedDate directly from the item, formatted if present
  const lastPurchasedDate = matchingItem.lastPurchasedDate
    ? dayjs(matchingItem.lastPurchasedDate).format("MM/DD/YYYY")
    : null;

  return {
    lastPurchasedDate,
    lastPurchasedPrice,
  };
};

/**
 * Determines the default price for a given item.
 * If 'lastPurchasedPrice' is not available, it returns the highest price from the item's vendors.
 * Searches in the provided 'items' array for a matching item by 'id'.
 * @param {Object} item - The item object to search by.
 * @param {Array} items - Array of items to search for the matching item by 'id'.
 * @returns {number|null} - The default price or null if not available.
 */
export const getDefaultPriceItem = (item, items) => {
  // Find the matching item in the items array
  const matchingItem = items.find((itm) => itm?.id === item?.id);

  if (!matchingItem) return null;

  // Get lastPurchasedPrice directly or find the highest vendor price if not available
  const lastPurchasedPrice =
    matchingItem.lastPurchasedPrice ??
    Math.max(...(matchingItem.vendors.map((vendor) => vendor.price) || [null]));

  return lastPurchasedPrice;
};

export const getExpenseIDFromItem = (item, items) => {
  const matchingItem = items.find((itm) => itm.id === item.id);

  if (!matchingItem) return null;

  return matchingItem.ExpenseId;
};
