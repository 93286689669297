export function getPoRevisionNumber(poNumber, documents) {

    const revisionNumber = documents.length;
    
    if(revisionNumber === 0) {
      return poNumber;
    }

  return `${poNumber}_R${revisionNumber}`;
}
