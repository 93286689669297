import { parsePhoneNumberFromString } from "libphonenumber-js";

export const formatPhoneNumber = (phoneNumber) => {
  try {
    // Default to US if no country code is provided
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, "US");

    if (!parsedNumber) {
      console.error("Failed to parse phone number:", phoneNumber);
      return phoneNumber; // returns the original number if parsing fails
    }
    return parsedNumber.formatInternational();
  } catch (error) {
    console.error("Failed to parse phone number:", error);
    return phoneNumber; // in case of any error, return the original number
  }
};

// Example usage in a React component or function
console.log(formatPhoneNumber("17707299682")); // Should now format correctly
