import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Tabs,
  Table,
  message,
  Button,
  Modal,
  Tag,
  Row,
  Col,
  Typography,
  Space,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { useModuleOptions, useItems } from "../../../Contexts/useSpecificData"; // Adjust the import path as needed
import HeaderText from "../../../Styled/HeaderText";
import { updateById } from "../../../Functions/updateById";
import { TABLES } from "../../../constants";
import debounce from "lodash.debounce";
import { useNavigate, useParams } from "react-router-dom"; // Import React Router hooks
import { Loader } from "../../../Styled/Loader";
import AddModuleOptionModal from "./AddModuleOptionModal";

const { TabPane } = Tabs;
const { Text } = Typography;

function ModuleOptions() {
  // Fetching module options and items using custom hooks
  const { data: moduleOptions, refresh: refreshModules } = useModuleOptions();
  const { data: items } = useItems(); // Assuming useItems provides the available items

  const navigate = useNavigate(); // Hook to navigate programmatically
  const { category: urlCategory } = useParams(); // Extract category from URL

  // Define the available categories dynamically based on moduleOptions
  const availableCategories = useMemo(() => {
    if (!moduleOptions) return [];
    const categoriesSet = new Set(
      moduleOptions.map((option) => option.category)
    );
    return Array.from(categoriesSet);
  }, [moduleOptions]);

  // Organize moduleOptions by category, excluding deleted ones
  const categorizedOptions = useMemo(() => {
    if (!moduleOptions) return {};

    return moduleOptions.reduce((acc, item) => {
      if (item.deleted) return acc; // Exclude deleted module options

      const { category } = item;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
  }, [moduleOptions]);

  // Get all categories that have non-deleted module options
  const categories = useMemo(
    () => availableCategories.filter((cat) => categorizedOptions[cat]),
    [availableCategories, categorizedOptions]
  );

  // Determine the active category based on URL or default to first category
  const [activeCategory, setActiveCategory] = useState(() => {
    return categories.includes(urlCategory)
      ? urlCategory
      : categories[0] || null;
  });

  // Update activeCategory when URL or categories change
  useEffect(() => {
    if (categories.length === 0) {
      setActiveCategory(null);
      return;
    }

    if (categories.includes(urlCategory)) {
      setActiveCategory(urlCategory);
    } else {
      setActiveCategory(categories[0]);
      if (urlCategory) {
        navigate(
          `/estimating/module-options/${encodeURIComponent(categories[0])}`,
          { replace: true }
        );
      }
    }
  }, [urlCategory, categories, navigate]);

  // State to manage loading states for updates
  const [updatingIds, setUpdatingIds] = useState([]);

  // Modal state for adding/editing module options
  const [isModuleModalVisible, setIsModuleModalVisible] = useState(false);
  const [moduleModalInitialData, setModuleModalInitialData] = useState(null); // To hold data when editing

  // Modal state for confirming module option deletion
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [moduleOptionToDelete, setModuleOptionToDelete] = useState(null);

  // Loading state for Confirm Remove Modal
  const [confirmRemoveLoading, setConfirmRemoveLoading] = useState(false);

  // Define the debounced update function using useCallback to maintain reference
  const debouncedUpdate = useCallback(
    debounce(async (value, record) => {
      const { id } = record;
      setUpdatingIds((prev) => [...prev, id]);

      try {
        await updateById(TABLES.MODULE_OPTIONS, { laborCost: value }, id);
      } catch (err) {
        console.error(err);
        message.error(
          `Failed to update labor cost for "${record.moduleOptionDescription}".`
        );
      } finally {
        setUpdatingIds((prev) => prev.filter((itemId) => itemId !== id));
      }
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  const handleLaborCostChange = (value, record) => {
    debouncedUpdate(value, record);
  };

  const showEditModuleModal = (record) => {
    setModuleModalInitialData(record);
    setIsModuleModalVisible(true);
  };

  const showDeleteModuleModal = (record) => {
    setModuleOptionToDelete({
      moduleOptionId: record.id,
      moduleOptionName: record.moduleOptionDescription,
    });
    setIsConfirmVisible(true);
  };

  const handleModuleModalOk = async () => {
    refreshModules();
    setIsModuleModalVisible(false);
    setModuleModalInitialData(null);
  };

  const handleModuleModalCancel = () => {
    setIsModuleModalVisible(false);
    setModuleModalInitialData(null);
  };

  const handleDeleteModalOk = async () => {
    if (!moduleOptionToDelete) {
      message.error("No module option selected for deletion.");
      return;
    }

    const { moduleOptionId, moduleOptionName } = moduleOptionToDelete;

    setConfirmRemoveLoading(true);
    setUpdatingIds((prev) => [...prev, moduleOptionId]);

    try {
      await updateById(
        TABLES.MODULE_OPTIONS,
        { deleted: true },
        moduleOptionId
      );
      message.success(
        `Module Option "${moduleOptionName}" deleted successfully.`
      );
      refreshModules();
    } catch (err) {
      console.error(err);
      message.error("Failed to delete module option.");
    } finally {
      setUpdatingIds((prev) => prev.filter((id) => id !== moduleOptionId));
      setIsConfirmVisible(false);
      setModuleOptionToDelete(null);
      setConfirmRemoveLoading(false);
    }
  };

  const handleDeleteModalCancel = () => {
    setIsConfirmVisible(false);
    setModuleOptionToDelete(null);
  };

  const handleTabChange = (key) => {
    if (key === activeCategory) return;
    navigate(`/estimating/module-options/${encodeURIComponent(key)}`);
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Module Option Description",
        dataIndex: "moduleOptionDescription",
        key: "moduleOptionDescription",
        sorter: (a, b) =>
          a.moduleOptionDescription.localeCompare(b.moduleOptionDescription),
        defaultSortOrder: "ascend",
      },
      {
        title: "Expense ID",
        dataIndex: "ExpenseId",
        key: "ExpenseId",
      },
      {
        title: "Labor Formula",
        dataIndex: "laborFormula",
        key: "laborFormula",
        render: (laborFormula) => laborFormula || "Please select UOMs",
      },
      {
        title: "Attached Material Items",
        dataIndex: "materialItems",
        key: "materialItems",
        render: (materialItems) =>
          Array.isArray(materialItems) && materialItems.length > 0 ? (
            materialItems.map((item, index) => {
              const selectedItem = items.find((itm) => itm.id === item.item);
              return (
                <Row
                  key={item.id}
                  gutter={[16, 8]}
                  style={{
                    marginBottom: "8px",
                    alignItems: "center",
                    border: "1px solid #f0f0f0",
                    padding: 8,
                  }}
                >
                  <Col span={4}>
                    <Typography.Text>{item.name || "N/A"}</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Tag
                      style={{
                        cursor: "default",
                        backgroundColor: "#fafafa",
                        borderColor: "#d9d9d9",
                      }}
                    >
                      {selectedItem ? selectedItem.itemNumber : "N/A"}
                    </Tag>
                  </Col>
                  <Col span={8}>
                    <span style={{ wordBreak: "break-word" }}>
                      {selectedItem ? selectedItem.itemDescription : "N/A"}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Tooltip title="Pre-saved material formula.">
                      <Typography.Text strong>
                        {item.formula || "N/A"}
                      </Typography.Text>
                    </Tooltip>
                  </Col>
                </Row>
              );
            })
          ) : (
            <>
              <StopOutlined style={{ color: "red", marginRight: "8px" }} />
              <span style={{ opacity: 0.7, fontStyle: "italic" }}>
                No material items attached
              </span>
            </>
          ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => (
          <Space>
            <Button
              type="primary"
              onClick={() => showEditModuleModal(record)}
              disabled={updatingIds.includes(record.id)}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              danger
              onClick={() => showDeleteModuleModal(record)}
              disabled={updatingIds.includes(record.id)}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        ),
      },
    ];
  }, [items, updatingIds]);

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: "16px", alignItems: "center" }}>
        <Col span={12}>
          <HeaderText text={"Module Options"} />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={() => setIsModuleModalVisible(true)}>
            Add Module Option
          </Button>
        </Col>
      </Row>

      {categories.length > 0 ? (
        <Tabs activeKey={activeCategory} onChange={handleTabChange} centered>
          {categories.map((category) => (
            <TabPane tab={category} key={category}>
              <Table
                dataSource={categorizedOptions[category]}
                columns={columns}
                rowKey="id"
                pagination={{ pageSize: 10 }}
              />
            </TabPane>
          ))}
        </Tabs>
      ) : (
        <Loader />
      )}

      <AddModuleOptionModal
        visible={isModuleModalVisible}
        onOk={handleModuleModalOk}
        onCancel={handleModuleModalCancel}
        initialData={moduleModalInitialData}
      />

      <Modal
        title="Confirm Delete"
        visible={isConfirmVisible}
        onOk={handleDeleteModalOk}
        onCancel={handleDeleteModalCancel}
        okText="Delete"
        cancelText="Cancel"
        confirmLoading={confirmRemoveLoading}
      >
        <p>
          Are you sure you want to delete the module option "
          {moduleOptionToDelete?.moduleOptionName}"?
        </p>
      </Modal>
    </div>
  );
}

export default ModuleOptions;
