import React from "react";
import { Spin } from "antd";
import { COLORS } from "../constants";

export const Loader = (size = "small", color = COLORS.PRIMARY) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80vh", // Use full viewport height to center vertically
      width: "100%",
    }}
  >
    <Spin size={size} style={{ color: color }} />
  </div>
);
