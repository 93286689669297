import React, { useState, useEffect } from "react";
import { Table, Tag, Badge } from "antd";
import { StopOutlined } from "@ant-design/icons";
import {
  useVendors,
  usePurchaseOrders,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { formatMoney } from "../../Formatters/helpers";
import dayjs from "dayjs";
import { formatBuyerName } from "../../Formatters/formatBuyerName";
import TableHeader from "../../Styled/TableHeader";
import { COLORS } from "../../constants";
import ReturnOrderModal from "./ReturnOrderModal";
import { getProjectName } from "../../Formatters/getProjectName";

const ReturnsTable = ({ onFilterChange }) => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    vendors: [],
    buyers: [],
    projects: [],
    status: [],
    addresses: [], // Added for shipFromAddress
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const {
    data: vendors,
    loading: vendorsLoading,
    error: vendorsError,
  } = useVendors();
  const {
    data: purchaseOrders,
    loading: poLoading,
    error: poError,
  } = usePurchaseOrders();

  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
  } = useProjects();

  const returnOrders = purchaseOrders.filter((po) => po.returnOrder);

  console.log("returnOrders:", returnOrders);

  // Aggregate and structure the data
  const aggregateData = (data) => {
    const aggregated = {};
    data.forEach((item) => {
      if (!aggregated[item.roNumber]) {
        aggregated[item.roNumber] = {
          ...item,
          lines: item.lines || [],
          totalQty: 0,
          totalAmount: 0,
          receivedDate: null,
          roStatus: "Closed",
          vendorName: getVendorName(item.vendor, vendors), // Added vendorName
          shipFromAddress: item.returnOrder.shipFromAddress, // Added shipFromAddress
        };
      }

      // Include all lines (not just those with qtyReturn > 0)
      item.lines.forEach((line) => {
        aggregated[item.roNumber].totalQty += parseFloat(line.qtyReturn || "0");
        aggregated[item.roNumber].totalAmount +=
          parseFloat(line.itemPrice || "0") * parseFloat(line.qtyReturn || "0");

        if (line.shippedDate) {
          const lineReceivedDate = dayjs(line.shippedDate);
          if (
            !aggregated[item.roNumber].receivedDate ||
            lineReceivedDate.isAfter(
              dayjs(aggregated[item.roNumber].receivedDate)
            )
          ) {
            aggregated[item.roNumber].receivedDate = line.shippedDate;
          }
        }

        // Check if all qtyReturn === qtyShipped for roStatus "Closed"
        if (line.qtyReturn !== line.qtyShipped) {
          aggregated[item.roNumber].roStatus = "Open";
        }
      });

      // Determine Time Status
      const calculateLateStatus = (record) => {
        if (!record.returnOrder.requiredDate) return false;
        const requiredDateMidnight = dayjs(
          record.returnOrder.requiredDate
        ).startOf("day");
        const latestShippedDate = record.lines
          .filter((line) => line.shippedDate)
          .map((line) => dayjs(line.shippedDate))
          .sort((a, b) => b - a)[0];
        if (latestShippedDate) {
          return latestShippedDate.isAfter(requiredDateMidnight);
        }
        return (
          dayjs().isAfter(requiredDateMidnight) && record.roStatus === "Open"
        );
      };

      aggregated[item.roNumber].isLate = calculateLateStatus(
        aggregated[item.roNumber]
      );
    });

    return Object.values(aggregated);
  };

  useEffect(() => {
    if (!poLoading && !poError && purchaseOrders.length > 0) {
      const aggregatedResult = aggregateData(returnOrders);
      setOriginalData(aggregatedResult);
      setLoading(false);
    }
  }, [
    poLoading,
    poError,
    purchaseOrders,
    vendorsLoading,
    projectsLoading,
    vendors,
    projects,
  ]);

  useEffect(() => {
    applyFilters();
  }, [searchTerm, filteredInfo, originalData]);

  const applyFilters = () => {
    let filteredData = originalData;

    // Apply table filters
    if (Object.keys(filteredInfo).length > 0) {
      Object.keys(filteredInfo).forEach((key) => {
        const filterValues = filteredInfo[key];
        if (filterValues && filterValues.length > 0) {
          filteredData = filteredData.filter((record) => {
            let recordValue = "";
            switch (key) {
              case "vendor":
                recordValue = record.vendorName || "";
                break;
              case "buyer":
                recordValue = formatBuyerName(record.buyer) || "";
                break;
              case "projectNumber":
                recordValue =
                  getProjectName(record.projectNumber, projects) || "";
                break;
              case "roStatus":
                recordValue = record.roStatus || "";
                break;
              case "shipFromAddress":
                recordValue = record.shipFromAddress || "";
                break;
              default:
                recordValue = record[key] || "";
            }
            return filterValues.includes(recordValue);
          });
        }
      });
    }

    // Apply search filter
    if (searchTerm.trim() !== "") {
      const searchTerms = searchTerm.split(/\s+/).filter((term) => term);
      filteredData = filteredData.filter((record) =>
        [
          "roNumber",
          "vendorName",
          "buyerName",
          "projectName",
          "shipFromAddress",
        ].some((key) => {
          let fieldValue = "";
          switch (key) {
            case "roNumber":
              fieldValue = record.roNumber?.toString().toLowerCase() || "";
              break;
            case "vendorName":
              fieldValue = (record.vendorName || "").toString().toLowerCase();
              break;
            case "buyerName":
              fieldValue = formatBuyerName(record.buyer)?.toLowerCase() || "";
              break;
            case "projectName":
              fieldValue =
                getProjectName(record.projectNumber, projects)?.toLowerCase() ||
                "";
              break;
            case "shipFromAddress":
              fieldValue = (record.shipFromAddress || "")
                .toString()
                .toLowerCase();
              break;
            default:
              fieldValue = "";
          }
          return searchTerms.every((term) => fieldValue.includes(term));
        })
      );
    }

    setData(filteredData);
    onFilterChange(filteredData);
    updateFilterOptions(filteredData); // Update the available filter options after search and filter
  };

  const getUniqueFilters = (dataSource, dataIndex) => {
    return Array.from(
      new Set(
        dataSource.map((item) =>
          dataIndex === "vendor"
            ? item.vendorName
            : dataIndex === "buyer"
            ? formatBuyerName(item.buyer)
            : dataIndex === "projectNumber"
            ? getProjectName(item.projectNumber, projects)
            : dataIndex === "shipFromAddress"
            ? item.shipFromAddress
            : item[dataIndex]
        )
      )
    )
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b))
      .map((value) => ({
        text: value,
        value: value,
      }));
  };

  const updateFilterOptions = (filteredData) => {
    setFilterOptions({
      vendors: getUniqueFilters(filteredData, "vendor"),
      buyers: getUniqueFilters(filteredData, "buyer"),
      projects: getUniqueFilters(filteredData, "projectNumber"),
      status: getUniqueFilters(filteredData, "roStatus"),
      addresses: getUniqueFilters(filteredData, "shipFromAddress"), // Added for shipFromAddress
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
  };

  const handleFilterChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const removeFilters = () => {
    setFilteredInfo({});
    setSearchTerm("");
  };

  const columns = [
    {
      title: "Address",
      dataIndex: "shipFromAddress",
      key: "shipFromAddress",
      sorter: (a, b) =>
        (a.shipFromAddress || "").localeCompare(b.shipFromAddress || ""),
      filters: filterOptions.addresses, // Added filters for Address
      filteredValue: filteredInfo.shipFromAddress || null,
      filterSearch: true,
      onFilter: (value, record) =>
        record.shipFromAddress
          ? record.shipFromAddress.toLowerCase().includes(value.toLowerCase())
          : false,
      render: (text) => {
        if (!text) return null; // Handle null or undefined addresses

        // Split the address at the first comma
        const [firstPart, ...rest] = text.split(",");

        // Join the remaining parts back together (in case there are multiple commas)
        const secondPart = rest.join(",").trim();

        return (
          <div>
            <div>{firstPart.trim()}</div>
            {secondPart && <div>{secondPart}</div>}
          </div>
        );
      },
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      filteredValue: filteredInfo.poNumber || null,
      onFilter: (value, record) =>
        record.poNumber.toLowerCase().includes(value.toLowerCase()),
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{text}</span>
          <Badge
            count={record.lines.length}
            style={{ background: "#6BA1E2", fontWeight: "bold" }}
          />
        </div>
      ),
    },
    {
      title: "RO Number",
      dataIndex: "roNumber",
      key: "roNumber",
      sorter: (a, b) => extractNumber(a.roNumber) - extractNumber(b.roNumber),
      defaultSortOrder: "descend",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{text}</span>
          <Badge
            count={record.lines.filter((line) => line.qtyReturn > 0).length}
            style={{ fontWeight: "bold" }}
          />
        </div>
      ),
    },

    {
      title: "Vendor",
      dataIndex: "vendorName", // Use vendorName for display and filtering
      key: "vendor",
      sorter: (a, b) => (a.vendorName || "").localeCompare(b.vendorName || ""),
      filters: filterOptions.vendors,
      filteredValue: filteredInfo.vendor || null,
      filterSearch: true,
      onFilter: (value, record) =>
        record.vendorName?.toLowerCase().includes(value.toLowerCase()),
      render: (text) => (vendorsLoading ? "Loading..." : text || "N/A"),
    },

    {
      title: "Date Submitted",
      dataIndex: ["returnOrder", "orderedDate"],
      key: "orderedDate",
      sorter: (a, b) =>
        dayjs(a.returnOrder.orderedDate).diff(dayjs(b.returnOrder.orderedDate)),
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "Required Date",
      dataIndex: ["returnOrder", "requiredDate"],
      key: "requiredDate",
      sorter: (a, b) =>
        dayjs(a.returnOrder.requiredDate).diff(
          dayjs(b.returnOrder.requiredDate)
        ),
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "Buyer",
      dataIndex: ["returnOrder", "submittedBy"],
      key: "submittedBy",
      sorter: (a, b) =>
        formatBuyerName(a.returnOrder?.submittedBy).localeCompare(
          formatBuyerName(b.returnOrder?.submittedBy)
        ),
      filters: filterOptions.buyers,
      filteredValue: filteredInfo.buyer || null,
      filterSearch: true,
      onFilter: (value, record) =>
        record.returnOrder?.submittedBy
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (text) => (text ? formatBuyerName(text) : "N/A"),
    },

    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      sorter: (a, b) => {
        let aProjName = getProjectName(a.projectNumber, projects) || "";
        let bProjName = getProjectName(b.projectNumber, projects) || "";
        return aProjName.localeCompare(bProjName);
      },
      filters: filterOptions.projects,
      filteredValue: filteredInfo.projectNumber || null,
      filterSearch: true,
      onFilter: (value, record) => {
        let projName = getProjectName(record.projectNumber, projects) || "";
        return projName.toLowerCase().includes(value.toLowerCase());
      },
      render: (text) => getProjectName(text, projects) || "N/A",
    },

    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      render: (text) => formatMoney(text),
    },
    {
      title: "RO Status",
      dataIndex: "roStatus",
      key: "roStatus",
      sorter: (a, b) => a.roStatus.localeCompare(b.roStatus),
      filters: filterOptions.status,
      filteredValue: filteredInfo.roStatus || null,
      filterSearch: true,
      onFilter: (value, record) => record.roStatus.includes(value),
      render: (status) => (
        <Tag
          style={{ fontSize: 11 }}
          color={status === "Closed" ? "green" : "blue"}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Time Status",
      key: "timeStatus",
      filters: [
        { text: "On Time", value: "On Time" },
        { text: "Late", value: "Late" },
      ],
      filteredValue: filteredInfo.timeStatus || null,
      filterSearch: true,
      onFilter: (value, record) =>
        (record.isLate ? "Late" : "On Time") === value,
      render: (text, record) => {
        const isLate = record.isLate;
        const color = isLate ? "red" : "green";
        const statusText = isLate ? "Late" : "On Time";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {statusText}
          </Tag>
        );
      },
    },
    {
      title: "Date Shipped",
      dataIndex: "receivedDate",
      key: "receivedDate",
      sorter: (a, b) => {
        if (!a.receivedDate && !b.receivedDate) return 0;
        if (!a.receivedDate) return -1; // `a` is null, so it comes after `b`
        if (!b.receivedDate) return 1; // `b` is null, so it comes after `a`
        return dayjs(a.receivedDate).diff(dayjs(b.receivedDate));
      },
      render: (text, record) =>
        record.roStatus !== "Closed" ? (
          <StopOutlined style={{ color: "#d3d3d3" }} />
        ) : (
          dayjs(text).format("MM/DD/YYYY")
        ),
    },
  ];

  const onRowClick = (record) => {
    console.log("Row clicked:", record);
    setSelectedRecord(record);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const extractNumber = (roNumber) => {
    const match = roNumber.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  return (
    <>
      <div>
        <TableHeader
          title="Returns"
          placeholder="Search Return Orders..."
          searchValue={searchTerm}
          onSearchChange={handleSearch}
          removeFilters={removeFilters}
          totalItems={originalData.length}
          totalResults={data.length}
          showRemoveFilters={true} // Ensure this matches your requirements
        />
        <Table
          className="small-table"
          rowClassName="clickable-row"
          dataSource={data}
          columns={columns}
          rowKey={(record) => `${record.roNumber}-${record.id}`} // Ensure unique rowKey
          loading={loading || poLoading || vendorsLoading || projectsLoading}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
          })}
          onChange={handleFilterChange}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100", "200"],
          }}
          locale={{
            emptyText: searchTerm
              ? "No return orders match your search."
              : "No data available.",
          }}
        />
      </div>
      <ReturnOrderModal
        purchaseOrder={selectedRecord}
        visible={modalVisible}
        onClose={handleModalClose}
        vendors={vendors}
        projects={projects} // Add projects data if needed
      />
    </>
  );
};

export default ReturnsTable;
