import React from "react";
import { Table, InputNumber, Button, Row, Col, Form, Tag, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { formatMoney } from "../../Formatters/helpers";

function AdditionalTable({ tableData, setTableData, index }) {
  const handleQtyChange = (key, value) => {
    const newPoLines = tableData.poLines.map((line) =>
      line.key === key
        ? { ...line, qty: value, total: value * line.price }
        : line
    );
    setTableData({ ...tableData, poLines: newPoLines });
  };

  const handleRemoveItem = (key) => {
    const newPoLines = tableData.poLines.filter((line) => line.key !== key);
    setTableData({ ...tableData, poLines: newPoLines });
  };

  const handleShippingChange = (value) => {
    setTableData({ ...tableData, shipping: value });
  };

  const handleOtherChange = (value) => {
    setTableData({ ...tableData, other: value });
  };

  const handleDiscountChange = (value) => {
    setTableData({ ...tableData, discount: value });
  };

  const handleNotesChange = (e) => {
    setTableData({ ...tableData, notes: e.target.value });
  };

  const subtotal = tableData.poLines.reduce((sum, line) => sum + line.total, 0);
  const totalPO =
    subtotal + tableData.shipping + tableData.other - tableData.discount;

  const columns = [
    {
      title: "Line",
      dataIndex: "key",
      key: "line",
      render: (text, record, index) => index + 1,
      width: 40,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    { title: "Manufacturer", dataIndex: "manufacturer", key: "manufacturer" },
    { title: "Mfg ID", dataIndex: "manufacturerId", key: "manufacturerId" },
    {
      title: "UOM",
      dataIndex: "orderBatchSize",
      key: "orderBatchSize",
      render: (text, record) => `${text} ${record.uom}`,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          style={{ width: 80 }}
          onChange={(value) => handleQtyChange(record.key, value)}
          className={text === 0 || text === null ? "zero-qty" : ""}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => formatMoney(text),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => formatMoney(text),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button

          danger
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveItem(record.key)}
        />
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={tableData.poLines}
        columns={columns}
        pagination={false}
        rowKey="key"
        size="small"
        className="small-table"
      />
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col span={4}/>
        <Col span={4}>
          <Form.Item label="Shipping">
            <InputNumber
              min={0}
              value={tableData.shipping}
              onChange={handleShippingChange}
              type="number"
              prefix="$"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Other">
            <InputNumber
              min={0}
              value={tableData.other}
              onChange={handleOtherChange}
              type="number"
              prefix="$"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Discount">
            <InputNumber
              min={0}
              value={tableData.discount}
              onChange={handleDiscountChange}
              type="number"
              className="red-input"
              prefix="- $"
              style={{ width: "100%", color: "#f10000" }}
   
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Subtotal" style={{ display: "none" }}>
            {/* Original InputNumber is hidden */}
            <InputNumber
              disabled
              value={subtotal.toFixed(2)}
              type="number"
              prefix="$"
              style={{ width: "100%" }}
            />
          </Form.Item>

          {/* Add the Subtotal as a Tag outside the hidden Form.Item */}
          <Form.Item label="Subtotal" style={{ marginBottom: 0 }}>
            <Tag
              color="green"
              style={{
                fontSize: "16px",
                width: "100%",
                padding: 6,
                textAlign: "center",
                opacity: 0.9,
              }}
            >
              {formatMoney(subtotal)}
            </Tag>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total" style={{ fontWeight: 600 }}>
            <Tag
              color="green"
              style={{
                textAlign: "center",
                fontSize: 16,
                padding: 6,
                width: "100%",
              }}
            >
              <strong>{formatMoney(totalPO)}</strong>
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Form.Item label="Notes">
            <Input.TextArea
              value={tableData.notes}
              onChange={handleNotesChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default AdditionalTable;
