// src/utils/purchaseOrderUtils.js

/**
 * Calculates the subtotal of a purchase order.
 * Subtotal is the sum of (itemPrice * qtyOrdered) for all lines.
 *
 * @param {Object} purchaseOrder - The purchase order object.
 * @returns {number} The subtotal amount.
 */
export const getSubtotal = (purchaseOrder) => {
  if (!purchaseOrder || !purchaseOrder.lines) return 0;

  return purchaseOrder.lines.reduce((subtotal, line) => {
    const itemPrice = parseFloat(line.itemPrice) || 0;
    const qtyOrdered = parseFloat(line.qtyOrdered) || 0;
    return subtotal + itemPrice * qtyOrdered;
  }, 0);
};

/**
 * Calculates the total amount of a purchase order.
 * Total = Subtotal + Shipping Costs + Other Costs - Discount
 *
 * @param {Object} purchaseOrder - The purchase order object.
 * @param {number} subtotal - The subtotal amount.
 * @returns {number} The total amount.
 */
export const getTotalAmount = (purchaseOrder, subtotal) => {
  if (!purchaseOrder) return 0;

  const shippingCosts = parseFloat(purchaseOrder.shippingCosts) || 0;
  const otherCosts = parseFloat(purchaseOrder.other) || 0;
  const discount = parseFloat(purchaseOrder.discount) || 0;

  return subtotal + shippingCosts + otherCosts - discount;
};

export const getTotalFromPurchaseOrder = (purchaseOrder) => {
  const subtotal = getSubtotal(purchaseOrder);
  return getTotalAmount(purchaseOrder, subtotal);
}
