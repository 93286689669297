export function getFirstNameFromEmail(input) {
  if (!input) return null;

  // Check if the input contains a space (not an email)
  if (input.includes(" ")) {
    // Split by space and return the first word
    const firstName = input.split(" ")[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  }

  // If no space, assume it's an email and proceed as before
  const namePart = input.split("@")[0];
  const firstName = namePart.split(".")[0];

  // Capitalize the first letter of the first name
  return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
}
