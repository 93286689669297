import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  CalculatorOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { UserButton, useUser } from "@clerk/clerk-react";
import IconOnly from "./images/iconOnly.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faMoneyBill1,
  faTag,
  faFilter,
  faChartLine,
  faPersonWalkingArrowLoopLeft,
  faBoxesPacking,
  faTicket,
  faClockRotateLeft,
  faFileInvoice,
  faFileInvoiceDollar,
  faCommentDollar,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { useTickets } from "./Contexts/useSpecificData";
import HelpMenu from "./Components/Support/HelpMenu";
import { useMediaQueryContext } from "./Contexts/MediaQueryContext";
import { FaHandshake, FaRegHandshake } from "react-icons/fa";

const { Header } = Layout;

function NavigationHeader({ roles = [] }) {
  const location = useLocation();
  const path = location.pathname.replace(/\/$/, ""); // Normalize path by removing trailing slash
  const currentModule = path.split("/")[1] || ""; // Ensure there's a module
  const { data: tickets = [] } = useTickets(); // Default to empty array
  const { user } = useUser(); // Get the current logged-in user
  const userEmail = user?.primaryEmailAddress?.emailAddress || ""; // Default to empty string

  const [errorLogs, setErrorLogs] = useState([]); // State for capturing logs

  const { isDesktop } = useMediaQueryContext();

  // List of users who can view preview items
  const previewUsers = [
    "cade.slayton@bmarko.com",
    "antony.kountouris@bmarko.com",
  ];
  const canViewPreview = previewUsers.includes(userEmail);

  // Filter tickets for the current logged-in user
  const userTickets = useMemo(() => {
    return Array.isArray(tickets)
      ? tickets.filter((ticket) => ticket.userEmail === userEmail)
      : [];
  }, [tickets, userEmail]);

  // Define dynamic menu items based on current module, roles, and preview access
  const menuItems = useMemo(() => {
    const items = [];

    if (currentModule === "admin" && roles.includes("admin")) {
      items.push(
        {
          key: "/admin/users",
          label: "Users",
          icon: <UserOutlined />,
        },
        {
          key: "/admin/tickets",
          label: "Tickets",
          icon: (
            <FontAwesomeIcon
              style={{ marginRight: 4 }}
              icon={faTicket}
            />
          ),
        }
      );
    } else if (currentModule === "estimating") {
      if (roles.includes("estimator") || roles.includes("admin")) {
        items.push(
          {
            key: "/estimating/estimates",
            label: "Estimates",
            icon: <CalculatorOutlined style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/assemblies",
            label: "Assemblies",
            icon: <SettingOutlined style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/customers",
            label: "Customers",
            icon: <FaRegHandshake style={{ marginRight: 4, fontSize: 16 }} />,
          },
          // {
          //   key: "/estimating/module-options/Framing",
          //   label: "Module Options",
          //   icon: <SettingOutlined style={{ marginRight: 4 }} />,
          // },
          {
            key: "/estimating/vendors",
            label: "Vendors",
            icon: <FontAwesomeIcon icon={faStore} style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/items",
            label: "Items",
            icon: <FontAwesomeIcon icon={faTag} style={{ marginRight: 4 }} />,
          }
        );
      }
    } else if (currentModule === "purchasing") {
      if (roles.includes("purchaser") || roles.includes("admin")) {
        items.push(
          {
            key: "/purchasing/purchase",
            label: "Purchase",
            icon: (
              <FontAwesomeIcon
                icon={faMoneyBill1}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/returns",
            label: "Returns",
            icon: (
              <FontAwesomeIcon
                icon={faPersonWalkingArrowLoopLeft}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/vendors",
            label: "Vendors",
            icon: (
              <FontAwesomeIcon
                icon={faStore}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/purchasing/items",
            label: "Items",
            icon: (
              <FontAwesomeIcon
                icon={faTag}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/purchasing/reporting",
            label: "Reporting",
            icon: (
              <FontAwesomeIcon
                icon={faFilter}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/purchasing/dashboard",
            label: "Dashboard",
            icon: (
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/purchasing/invoices",
            label: "Invoices",
            // preview: true, // Uncomment if needed
            icon: (
              <FontAwesomeIcon
                icon={faFileInvoice}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/transactions",
            label: "Transactions",
            preview: true, // Only visible to preview users
            icon: (
              <FontAwesomeIcon
                icon={faCommentDollar}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          }
        );
      }
    } else if (currentModule === "receiving") {
      if (roles.includes("receiver") || roles.includes("admin")) {
        items.push(
          {
            key: "/receiving/receive/purchase-orders",
            label: "Receive",
            icon: (
              <FontAwesomeIcon
                icon={faBoxesPacking}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/receiving/unreceive",
            label: "Unreceive",
            icon: (
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/receiving/dashboard",
            label: "Dashboard",
            icon: (
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          }
        );
      }
    }

    // Filter out preview items if the user is not allowed to view them
    return items.filter((item) => !item.preview || canViewPreview);
  }, [currentModule, roles, canViewPreview]);

  // Determine the selected key based on the current path
  const selectedKey = useMemo(() => {
    // Find the menu item key that is the longest matching prefix of the current path
    let matchedKey = "";
    menuItems.forEach((item) => {
      if (path.startsWith(item.key) && item.key.length > matchedKey.length) {
        matchedKey = item.key;
      }
    });
    return matchedKey;
  }, [path, menuItems]);

  // Debugging logs
  useEffect(() => {
    // console.log("Current Path:", path);
    // console.log("Current Module:", currentModule);
    // console.log("Roles:", roles);
    // console.log("Can View Preview:", canViewPreview);
    // console.log("Menu Items:", menuItems);
    // console.log("Selected Key:", selectedKey);
  }, [path, currentModule, roles, canViewPreview, menuItems, selectedKey]);

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0 24px",
        backgroundColor: "#001529",
      }}
      className="box-shadow"
    >
      {/* Left Section: Logo and Menu */}
      <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
        <div
          className="logo-wrapper"
          style={{ marginRight: 16, height: "100%" }}
        >
          <img
            src={IconOnly}
            alt="Logo"
            className="logo minimized"
            style={{ width: 40 }}
          />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{ flex: 1 }}
        >
          {menuItems.map((item) => (
            <Menu.Item
              key={item.key} // Ensure key matches the path exactly
              style={{ display: "flex", alignItems: "center" }}
            >
              <Link to={item.key}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {item.icon}
                  <span style={{ marginLeft: 4 }}>{item.label}</span>
                </div>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </div>

      {/* Right Section: HelpMenu and User Button */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: !isDesktop ? "16px" : "32px",
        }}
      >
        {path !== "/" && (
          <HelpMenu userTickets={userTickets} logs={errorLogs} />
        )}

        {path !== "/" && roles.length > 1 && (
          <Link to="/" style={{ display: "flex", alignItems: "center" }}>
            <HomeOutlined style={{ fontSize: 22, color: "white" }} />
          </Link>
        )}

        <UserButton
          appearance={{
            elements: {
              userButtonAvatarBox: { width: "32px", height: "32px" },
            },
          }}
        />
      </div>
    </Header>
  );
}

export default NavigationHeader;
