import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Input,
  Table,
  Row,
  Col,
  InputNumber,
  message,
  Form,
  Switch,
  Select,
  Tag,
  Badge,
  Typography,
  Card,
  Descriptions,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  useCustomItems,
  useEstimates,
} from "../../../Contexts/useSpecificData";
import { updateById } from "../../../Functions/updateById";
import { addItemsToTable } from "../../../Functions/addAria";
import { formatMoney } from "../../../Formatters/helpers";
import {
  TABLES,
  EXPENSE_ACCOUNTS,
  ITEM_CATEGORIES,
  COLORS,
} from "../../../constants";
import { SearchInput } from "../../../Styled/SearchInput";
import PriceTag from "../../../Styled/PriceTag";

const { Option } = Select;
const { Text } = Typography;

const CustomItems = ({ estimate }) => {
  // State Variables
  const [searchTerm, setSearchTerm] = useState("");
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [itemBeingRemoved, setItemBeingRemoved] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [customItems, setCustomItems] = useState([]);
  const [newCustomItem, setNewCustomItem] = useState({
    itemDescription: "",
    internalDescription: "",
    category: "",
    expenseAccount: "",
    laborPrice: 0,
    price: 0,
  });
  const form = Form.useForm();

  // New State for Selected Quantities in Modal's Table
  const [selectedQuantities, setSelectedQuantities] = useState({});

  // Loading States
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [addingItemIds, setAddingItemIds] = useState([]);

  // Edit Modal States
  const [editVisible, setEditVisible] = useState(false);
  const [itemBeingEdited, setItemBeingEdited] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // Custom Items Data and Methods
  const {
    data: availableCustomItems,
    loading: customItemsLoading,
    refresh: refreshCustomItems,
  } = useCustomItems();
  const { refresh: refetchEstimates } = useEstimates();

  // Initialize customItems when estimate changes
  useEffect(() => {
    if (estimate.customItems && Array.isArray(estimate.customItems)) {
      setCustomItems(estimate.customItems);
    } else {
      setCustomItems([]);
    }
  }, [estimate]);

  // Initialize selectedQuantities when modal opens or availableCustomItems change
  useEffect(() => {
    if (visible && availableCustomItems) {
      const initialQuantities = {};
      availableCustomItems.forEach((item) => {
        initialQuantities[item.id] = null;
      });
      setSelectedQuantities(initialQuantities);
    }
  }, [visible, availableCustomItems]);

  /**
   * Handles saving the updated custom items to the backend.
   */
  const handleSave = useCallback(
    async (updatedItems) => {
      try {
        await updateById(
          TABLES.ESTIMATES,
          { customItems: updatedItems },
          estimate.id
        );
        refetchEstimates();
      } catch (error) {
        message.error("Failed to update custom items: " + error.message);
      }
    },
    [estimate.id, refetchEstimates]
  );

  /**
   * Handles quantity changes for a specific custom item in the estimate's custom items table.
   */
  const handleQuantityChange = (value, id) => {
    if (value < 1) {
      message.error("Quantity must be at least 1.");
      return;
    }
    const updatedItems = customItems.map((ci) =>
      ci.id === id ? { ...ci, quantity: value } : ci
    );
    setCustomItems(updatedItems);
    handleSave(updatedItems);
  };

  /**
   * Handles adding a new custom item by creating it and adding it to the estimate.
   */
  const handleAddCustomItem = async () => {
    if (isCreatingNew) {
      const {
        itemDescription,
        internalDescription,
        category,
        expenseAccount,
        laborPrice,
        price,
      } = newCustomItem;

      if (
        !itemDescription ||
        !internalDescription ||
        !category ||
        !expenseAccount ||
        laborPrice <= 0 ||
        price <= 0
      ) {
        message.error("Please fill in all fields correctly.");
        return;
      }

      setIsAddingNew(true);
      try {
        // Add the new custom item to the backend (without quantity)
        const createdItem = await addItemsToTable(
          TABLES.ESTIMATING_CUSTOM_ITEMS,
          {
            itemDescription,
            internalDescription,
            category,
            expenseAccount,
            laborPrice,
            price,
          }
        );

        // Prepare the new item entry for the estimate
        const newItemEntry = {
          ...createdItem,
          quantity: 1,
          type: "custom",
          price: createdItem.price,
          dateAdded: new Date().toISOString(),
        };

        // Update the local customItems state
        const updatedItems = [...customItems, newItemEntry];
        setCustomItems(updatedItems);
        await handleSave(updatedItems);
        message.success(`Added custom item: ${createdItem.itemDescription}`);

        // Reset the form
        setNewCustomItem({
          itemDescription: "",
          internalDescription: "",
          category: "",
          expenseAccount: "",
          laborPrice: 0,
          price: 0,
        });
        setVisible(false);

        // Refresh available custom items to reflect the new addition
        await refreshCustomItems();
      } catch (error) {
        message.error("Failed to create custom item: " + error.message);
      } finally {
        setIsAddingNew(false);
      }
    }
  };

  /**
   * Handles removing a custom item from the estimate.
   */
  const handleRemove = async () => {
    if (!itemBeingRemoved) {
      message.error("No item selected for removal.");
      setConfirmVisible(false);
      return;
    }

    const updatedItems = customItems.filter(
      (item) => item.id !== itemBeingRemoved.id
    );
    setCustomItems(updatedItems);
    try {
      await handleSave(updatedItems);
      message.success(`Removed item: ${itemBeingRemoved.itemDescription}`);
    } catch (error) {
      // handleSave already shows message on error
    } finally {
      setConfirmVisible(false);
      setItemBeingRemoved(null);
    }
  };

  /**
   * Handles adding an existing custom item to the estimate with specified quantity.
   */
  const handleAddExistingCustomItem = async (itemId, quantity) => {
    const item = availableCustomItems.find((ci) => ci.id === itemId);
    if (!item) {
      message.error("Selected custom item not found.");
      return;
    }

    if (!quantity || quantity < 1) {
      message.error("Please specify a valid quantity.");
      return;
    }

    // Set loading state for this item
    setAddingItemIds((prev) => [...prev, itemId]);

    try {
      // Check if item already exists in customItems
      const existingItem = customItems.find((ci) => ci.id === itemId);
      let updatedItems;

      if (existingItem) {
        // If item exists, update its quantity
        updatedItems = customItems.map((ci) =>
          ci.id === itemId ? { ...ci, quantity: ci.quantity + quantity } : ci
        );
      } else {
        // If item does not exist, add it
        const newItemEntry = {
          ...item,
          quantity: quantity,
          type: "custom",
          price: item.price,
          dateAdded: new Date().toISOString(),
        };
        updatedItems = [...customItems, newItemEntry];
      }

      setCustomItems(updatedItems);
      await handleSave(updatedItems);
      message.success(`Added custom item: ${item.itemDescription}`);

      // Reset the selected quantity for this item
      setSelectedQuantities((prev) => ({
        ...prev,
        [itemId]: null,
      }));

      // Refresh available custom items to reflect the new addition
      await refreshCustomItems();
    } catch (error) {
      message.error("Failed to add custom item: " + error.message);
    } finally {
      // Remove loading state for this item
      setAddingItemIds((prev) => prev.filter((id) => id !== itemId));
    }
  };

  /**
   * Handles editing a custom item.
   */
  const handleEditCustomItem = async (values) => {
    if (!itemBeingEdited) {
      message.error("No item selected for editing.");
      return;
    }

    setIsUpdating(true);
    try {
      // Update the custom item in the ESTIMATING_CUSTOM_ITEMS table
      await updateById(
        TABLES.ESTIMATING_CUSTOM_ITEMS,
        values,
        itemBeingEdited.id
      );

      // Update the local customItems state
      const updatedItems = customItems.map((ci) =>
        ci.id === itemBeingEdited.id ? { ...ci, ...values } : ci
      );
      setCustomItems(updatedItems);
      await handleSave(updatedItems); // Save changes to backend

      message.success(`Updated custom item: ${values.itemDescription}`);

      // Close the edit modal
      setEditVisible(false);
      setItemBeingEdited(null);
    } catch (error) {
      message.error("Failed to update custom item: " + error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  /**
   * Filters available custom items based on search term and already added items.
   */
  const filteredCustomItems = availableCustomItems.filter((item) => {
    if (!item) return false;
    const {
      itemDescription = "",
      internalDescription = "",
      expenseAccount = "",
      category = "",
    } = item;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const matchesSearchTerm =
      !searchTerm ||
      itemDescription.toLowerCase().includes(lowerCaseSearchTerm) ||
      internalDescription.toLowerCase().includes(lowerCaseSearchTerm) ||
      expenseAccount.toLowerCase().includes(lowerCaseSearchTerm) ||
      category.toLowerCase().includes(lowerCaseSearchTerm); // Include category in search

    const notAlreadyAdded = !customItems.some(
      (customItem) => customItem.id === item.id
    );

    return matchesSearchTerm && notAlreadyAdded;
  });

  /**
   * Table Columns Definition for Custom Items in Estimate
   */
  const estimateColumns = [
    {
      title: "Date Added",
      dataIndex: "dateAdded",
      key: "dateAdded",
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(a.dateAdded) - new Date(b.dateAdded),
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "Internal Description",
      dataIndex: "internalDescription",
      key: "internalDescription",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: Object.keys(ITEM_CATEGORIES).map((cat) => ({
        text: cat,
        value: cat,
      })),
      onFilter: (value, record) => record.category === value,
    },
    {
      title: "Expense Account",
      dataIndex: "expenseAccount",
      key: "expenseAccount",
      filters: EXPENSE_ACCOUNTS.map((account) => ({
        text: account,
        value: account,
      })),
      onFilter: (value, record) => record.expenseAccount === value,
    },
    {
      title: "Labor Price",
      dataIndex: "laborPrice",
      key: "laborPrice",
      render: (text) => <PriceTag amount={text} />,
      sorter: (a, b) => a.laborPrice - b.laborPrice,
    },
    {
      title: "Material Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <PriceTag amount={text} />,
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Unit Price",
      render: (text, record) => (
        <PriceTag amount={record.laborPrice + record.price} />
      ),
      sorter: (a, b) => a.laborPrice + a.price - (b.laborPrice + b.price),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          value={record.quantity}
          onChange={(value) => handleQuantityChange(value, record.id)}
        />
      ),
    },
    {
      title: "Total",
      key: "total",
      render: (text, record) => (
        <PriceTag
          bold
          amount={(record.laborPrice + record.price) * record.quantity}
        />
      ),
      sorter: (a, b) =>
        (a.laborPrice + a.price) * a.quantity -
        (b.laborPrice + b.price) * b.quantity,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          {/* <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setItemBeingEdited(record);
              setEditVisible(true);
            }}
          >
            Edit
          </Button> */}
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              setItemBeingRemoved(record);
              setConfirmVisible(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  /**
   * Table Columns Definition for Adding Existing Custom Items
   */
  const addColumns = [
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "Internal Description",
      dataIndex: "internalDescription",
      key: "internalDescription",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: Object.keys(ITEM_CATEGORIES).map((cat) => ({
        text: cat,
        value: cat,
      })),
      onFilter: (value, record) => record.category === value,
    },
    {
      title: "Expense Account",
      dataIndex: "expenseAccount",
      key: "expenseAccount",
      filters: EXPENSE_ACCOUNTS.map((account) => ({
        text: account,
        value: account,
      })),
      onFilter: (value, record) => record.expenseAccount === value,
    },
    {
      title: "Labor Price",
      dataIndex: "laborPrice",
      key: "laborPrice",
      render: (text) => <Tag color="green">{formatMoney(text)}</Tag>,
      sorter: (a, b) => a.laborPrice - b.laborPrice,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <Tag color="green">{formatMoney(text)}</Tag>,
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          placeholder="Qty"
          style={{ width: "100%" }}
          value={selectedQuantities[record.id]}
          onChange={(value) =>
            setSelectedQuantities((prev) => ({
              ...prev,
              [record.id]: value,
            }))
          }
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            handleAddExistingCustomItem(
              record.id,
              selectedQuantities[record.id]
            )
          }
          loading={addingItemIds.includes(record.id)}
          disabled={
            addingItemIds.includes(record.id) ||
            !selectedQuantities[record.id] ||
            selectedQuantities[record.id] < 1
          }
        >
          Add
        </Button>
      ),
    },
  ];

  /**
   * Calculates the total cost of all custom items.
   */
  const totalMaterialCost = customItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const totalLaborCost = customItems.reduce(
    (total, item) => total + item.laborPrice * item.quantity,
    0
  );

  const totalCost = totalMaterialCost + totalLaborCost;

  return (
    <div>
      {/* Header Section with Three Labeled PriceTags */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span style={{ fontSize: 18, fontWeight: 500 }}>
              Total Custom Items Cost:
            </span>
            {/* Three Labeled PriceTags with large styling */}
            <PriceTag label="Material" amount={totalMaterialCost} bold large />
            <PriceTag label="Labor" amount={totalLaborCost} bold large />
            <PriceTag label="Total" amount={totalCost} bold large />
          </div>
        </Col>
        <Col>
          <Button href="/estimating/manage-custom-items" target="_blank"  style={{marginRight:16}} icon={<SettingOutlined/>}>Manage Custom Items</Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setVisible(true)}
          >
            Add Custom Items
          </Button>
        </Col>
      </Row>

      {/* Custom Items Display Section as Table */}
      {customItems.length > 0 && (
        <Card
          className="box-shadow"
          size="small"
          style={{ boxShadow: "0 0 5px 1px #E3ECF8", marginTop: 16 }}
        >
          <div
            className="box-title"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 16,
              fontWeight: 600,
              marginBottom: 12,
            }}
          >
            <Badge count={customItems.length} />
            <span style={{ marginLeft: 8 }}>Custom Items</span>
          </div>
          <Table
            columns={estimateColumns}
            dataSource={customItems}
            size="small"
            rowKey="id"
            pagination={false}
          />
        </Card>
      )}

      {/* Manage Custom Items Modal */}
      <Modal
        title="Add Custom Items"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width="80%"
      >
        {/* Switch Between Create New and Select Existing */}
        <Row justify="end" style={{ marginBottom: 16 }}>
          <Col>
            <span
              style={{
                marginRight: 8,
                fontStyle: "italic",
                opacity: !isCreatingNew ? 1 : 0.7,
                fontWeight: 600,
                color: !isCreatingNew ? COLORS.PRIMARY : "#000",
              }}
            >
              Select Existing
            </span>
            <Switch
              checked={isCreatingNew}
              onChange={() => setIsCreatingNew(!isCreatingNew)}
            />
            <span
              style={{
                marginLeft: 8,
                fontStyle: "italic",
                opacity: isCreatingNew ? 1 : 0.7,
                fontWeight: 600,
                color: isCreatingNew ? COLORS.PRIMARY : "#000",
              }}
            >
              Create New
            </span>
          </Col>
        </Row>

        {isCreatingNew ? (
          /* Create New Custom Item Form */
          <Card title="Create New Custom Item">
            <Form layout="vertical">
              <Row gutter={16}>
                {/* Internal Description with Helper Text */}
                <Col span={12}>
                  <Form.Item
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>Internal Description</span>
                        <Text italic style={{ opacity: 0.7, marginLeft: 8 }}>
                          (Internal Use Only)
                        </Text>
                      </div>
                    }
                    required
                  >
                    <Input
                      placeholder="Internal Description"
                      value={newCustomItem.internalDescription}
                      onChange={(e) =>
                        setNewCustomItem({
                          ...newCustomItem,
                          internalDescription: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                {/* Item Description */}
                <Col span={12}>
                  <Form.Item label="Item Description" required>
                    <Input
                      placeholder="Item Description"
                      value={newCustomItem.itemDescription}
                      onChange={(e) =>
                        setNewCustomItem({
                          ...newCustomItem,
                          itemDescription: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                {/* Category */}
                <Col span={6}>
                  <Form.Item label="Category" required>
                    <Select
                      placeholder="Select Category"
                      value={newCustomItem.category}
                      onChange={(value) => {
                        const correspondingExpenseAccount =
                          ITEM_CATEGORIES[value]?.expenseAccount || "";
                        setNewCustomItem({
                          ...newCustomItem,
                          category: value,
                          expenseAccount: correspondingExpenseAccount,
                        });
                      }}
                      allowClear
                    >
                      {Object.keys(ITEM_CATEGORIES).map((category) => (
                        <Option key={category} value={category}>
                          {category}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Expense Account (Predefined based on Category) */}
                <Col span={6}>
                  <Form.Item label="Expense Account" required>
                    <Select
                      placeholder="Expense Account"
                      value={newCustomItem.expenseAccount}
                      onChange={(value) =>
                        setNewCustomItem({
                          ...newCustomItem,
                          expenseAccount: value,
                        })
                      }
                      allowClear
                    >
                      {EXPENSE_ACCOUNTS.map((account) => (
                        <Option key={account} value={account}>
                          {account}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Labor Price and Material Price */}
                <Col span={6}>
                  <Form.Item label="Labor Price" required>
                    <InputNumber
                      placeholder="Labor Price"
                      type="number"
                      value={newCustomItem.laborPrice}
                      onChange={(value) =>
                        setNewCustomItem({
                          ...newCustomItem,
                          laborPrice: value,
                        })
                      }
                      style={{ width: "100%" }}
                      min={0}
                      addonBefore="$"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Price" required>
                    <InputNumber
                      placeholder="Price"
                      type="number"
                      value={newCustomItem.price}
                      onChange={(value) =>
                        setNewCustomItem({
                          ...newCustomItem,
                          price: value,
                        })
                      }
                      style={{ width: "100%" }}
                      min={0}
                      addonBefore="$"
                    />
                  </Form.Item>
                </Col>

                {/* Create and Add Button */}
                <Col span={24} align="right" style={{ marginTop: 16 }}>
                  <Button
                    type="primary"
                    onClick={handleAddCustomItem}
                    style={{ alignSelf: "flex-end" }}
                    loading={isAddingNew}
                    disabled={isAddingNew}
                  >
                    Create and Add Custom Item
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        ) : (
          /* Select Existing Custom Items */
          <div>
            <SearchInput
              placeholder="Search Custom Items"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "100%", marginBottom: 16 }}
            />
            <Table
              columns={addColumns}
              dataSource={filteredCustomItems}
              rowKey="id"
              pagination={{
                pageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "25", "50", "100"],
              }}
              loading={customItemsLoading}
              locale={{
                emptyText: searchTerm
                  ? "No items match your search."
                  : "No available custom items.",
              }}
            />
          </div>
        )}
      </Modal>

      {/* Edit Custom Item Modal */}
      <Modal
        title="Edit Custom Item"
        visible={editVisible}
        onCancel={() => {
          setEditVisible(false);
          setItemBeingEdited(null);
        }}
        footer={null}
        width="60%"
        destroyOnClose
      >
        {itemBeingEdited && (
          <Form
            layout="vertical"
            initialValues={{
              itemDescription: itemBeingEdited.itemDescription,
              internalDescription: itemBeingEdited.internalDescription,
              category: itemBeingEdited.category,
              expenseAccount: itemBeingEdited.expenseAccount,
              laborPrice: itemBeingEdited.laborPrice,
              price: itemBeingEdited.price,
            }}
            onFinish={handleEditCustomItem}
          >
            <Row gutter={16}>
              {/* Internal Description with Helper Text */}
              <Col span={12}>
                <Form.Item
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>Internal Description</span>
                      <Text italic style={{ opacity: 0.7, marginLeft: 8 }}>
                        (Internal Use Only)
                      </Text>
                    </div>
                  }
                  name="internalDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please input internal description!",
                    },
                  ]}
                >
                  <Input placeholder="Internal Description" />
                </Form.Item>
              </Col>

              {/* Item Description */}
              <Col span={12}>
                <Form.Item
                  label="Item Description"
                  name="itemDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please input item description!",
                    },
                  ]}
                >
                  <Input placeholder="Item Description" />
                </Form.Item>
              </Col>

              {/* Category */}
              <Col span={6}>
                <Form.Item
                  label="Category"
                  name="category"
                  rules={[
                    { required: true, message: "Please select a category!" },
                  ]}
                >
                  <Select
                    placeholder="Select Category"
                    onChange={(value) => {
                      const correspondingExpenseAccount =
                        ITEM_CATEGORIES[value]?.expenseAccount || "";
                      form.setFieldsValue({
                        expenseAccount: correspondingExpenseAccount,
                      });
                    }}
                    allowClear
                  >
                    {Object.keys(ITEM_CATEGORIES).map((category) => (
                      <Option key={category} value={category}>
                        {category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Expense Account */}
              <Col span={6}>
                <Form.Item
                  label="Expense Account"
                  name="expenseAccount"
                  rules={[
                    {
                      required: true,
                      message: "Please select an expense account!",
                    },
                  ]}
                >
                  <Select placeholder="Expense Account" allowClear>
                    {EXPENSE_ACCOUNTS.map((account) => (
                      <Option key={account} value={account}>
                        {account}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Labor Price */}
              <Col span={6}>
                <Form.Item
                  label="Labor Price"
                  name="laborPrice"
                  rules={[
                    { required: true, message: "Please input labor price!" },
                  ]}
                >
                  <InputNumber
                    placeholder="Labor Price"
                    type="number"
                    style={{ width: "100%" }}
                    min={0}
                    addonBefore="$"
                  />
                </Form.Item>
              </Col>

              {/* Material Price */}
              <Col span={6}>
                <Form.Item
                  label="Price"
                  name="price"
                  rules={[{ required: true, message: "Please input price!" }]}
                >
                  <InputNumber
                    placeholder="Price"
                    type="number"
                    style={{ width: "100%" }}
                    min={0}
                    addonBefore="$"
                  />
                </Form.Item>
              </Col>

              {/* Update Button */}
              <Col span={24} align="right" style={{ marginTop: 16 }}>
                <Button type="primary" htmlType="submit" loading={isUpdating}>
                  Update Custom Item
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>

      {/* Confirmation Modal for Removal */}
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <ExclamationCircleOutlined
              style={{ color: "#faad14", marginRight: 8 }}
            />
            <span>Confirm Removal</span>
          </div>
        }
        open={confirmVisible}
        onOk={handleRemove}
        onCancel={() => setConfirmVisible(false)}
        okText="Yes"
        cancelText="No"
        okButtonProps={{ danger: true }}
      >
        {itemBeingRemoved ? (
          <div>
            <Typography.Text strong>
              Are you sure you want to remove this item?
            </Typography.Text>

            {/* Updated to use Descriptions */}
            <Descriptions
              bordered
              column={1}
              size="small"
              style={{ marginTop: 16 }}
            >
              <Descriptions.Item label="Item Description">
                {itemBeingRemoved.itemDescription}
              </Descriptions.Item>
              <Descriptions.Item label="Category">
                {itemBeingRemoved.category}
              </Descriptions.Item>
              <Descriptions.Item label="Quantity">
                {itemBeingRemoved.quantity}
              </Descriptions.Item>
              <Descriptions.Item label="Labor Price">
                {formatMoney(itemBeingRemoved.laborPrice)}
              </Descriptions.Item>
              <Descriptions.Item label="Material Price">
                {formatMoney(itemBeingRemoved.price)}
              </Descriptions.Item>
              <Descriptions.Item label="Total">
                {formatMoney(
                  (itemBeingRemoved.laborPrice + itemBeingRemoved.price) *
                    itemBeingRemoved.quantity
                )}
              </Descriptions.Item>
            </Descriptions>
          </div>
        ) : (
          <Typography.Text>No item selected for removal.</Typography.Text>
        )}
      </Modal>
    </div>
  );
};

export default CustomItems;
