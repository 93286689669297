// getVendorName.js
export const getVendorName = (vendorId, vendors) => {
  const vendor = vendors.find((vendor) => vendor.id === vendorId);
  if (!vendor) return vendorId;

  let locationInfo = "";
  if (vendor.onlineOrder) {
    locationInfo = " - Online Order";
  } else if (vendor.googleAddress && vendor.googleAddress.address_components) {
    const addressComponents = vendor.googleAddress.address_components;
    const cityComponent = addressComponents.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents.find((component) =>
      component.types.includes("administrative_area_level_1")
    );

    const city = cityComponent ? cityComponent.long_name : "";
    const state = stateComponent ? stateComponent.short_name : "";

    locationInfo = ` - ${city}, ${state}`;
  }

  return `${vendor.companyName}${locationInfo}`;
};
