// AddressAutocomplete.jsx
import React, { useRef, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { Input } from "antd";

const libraries = ["places"];

const AddressAutocomplete = ({ form, value, onChange }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current.input,
        { types: ["geocode"] } // Restrict results to geographical location types
      );
      autocompleteRef.current.addListener("place_changed", onPlaceChanged);
    }

    // Cleanup listener on unmount
    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(
          autocompleteRef.current
        );
      }
    };
  }, [isLoaded]);

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    console.log("Selected place:", place);
    const formattedAddress = place.formatted_address || "";

    // Notify parent component of the change
    if (onChange) {
      onChange(formattedAddress);
    }

    // Optionally, set form fields if needed
    form.setFieldsValue({
      googleAddress: place,
      address: formattedAddress,
    });
  };

  const handleChange = (e) => {
    const newAddress = e.target.value;
    // Notify parent component of the change
    if (onChange) {
      onChange(newAddress);
    }

    // Optionally, set form fields if needed
    form.setFieldsValue({ address: newAddress });
  };

  return (
    <Input
      ref={inputRef}
      value={value}
      onChange={handleChange}
      placeholder="Enter address"
    />
  );
};

export default AddressAutocomplete;
