import { parsePrice } from "./helpers";
import { getDefaultPriceItem } from "../utils/itemsHelpers";
// Make sure to import or access 'items' data where this function is called

export const calculateEstimateCosts = (estimate, items) => {

  // Calculate materials cost from customItems
  const customItemsMaterialsCost =
    estimate.customItems?.reduce(
      (total, item) => total + parsePrice(item.price) * item.quantity,
      0
    ) || 0;

  // Calculate labor cost from customItems
  const customItemsLaborCost =
    estimate.customItems?.reduce(
      (total, item) => total + parsePrice(item.laborPrice) * item.quantity,
      0
    ) || 0;

  // Calculate materials and labor costs from modules
  const modulesCosts = estimate.modules?.reduce(
    (total, module) => {
      // Calculate container materials cost
      const containerMaterialsCost =
        parsePrice(module.container.price) *
        parseInt(module.container.quantity, 10);

      // Calculate materials cost from module.categoryCosts
      const moduleMaterialsCost = Object.values(
        module.categoryCosts || {}
      ).reduce(
        (sum, categoryCost) => sum + parsePrice(categoryCost.materialCost || 0),
        0
      );

      // Calculate labor cost from module.categoryCosts
      let moduleLaborCost = Object.values(module.categoryCosts || {}).reduce(
        (sum, categoryCost) => sum + parsePrice(categoryCost.laborCost || 0),
        0
      );

      // Add rain protection labor cost
      if (module.rainProtection) {
        if (module.structureLength < 20) {
          moduleLaborCost += 80;
        } else {
          moduleLaborCost += 175;
        }
      }

      // Add permitted cost if applicable
      const permittedCost = module.permitted ? 4250 : 0;

      // Calculate rain protection material cost
      let rainMaterialCost = 0;
      if (module.rainProtection) {
        const rainItem = items.find((item) => item.itemNumber === "20-0012");
        const rainItemPrice = getDefaultPriceItem(rainItem, items) || 0;
        rainMaterialCost = rainItemPrice * 0.33;
      }

      return {
        materialsCost:
          total.materialsCost +
          containerMaterialsCost +
          moduleMaterialsCost +

          rainMaterialCost,
        laborCost: total.laborCost + moduleLaborCost + permittedCost,
      };
    },
    { materialsCost: 0, laborCost: 0 }
  ) || { materialsCost: 0, laborCost: 0 };

  // Calculate materials cost from ariaItems
  const ariaItemsMaterialsCost =
    estimate.ariaItems?.reduce(
      (total, item) => total + parsePrice(item.price) * item.quantity,
      0
    ) || 0;

  // Calculate materials and labor cost from assemblies
  const assembliesMaterialsCost =
    estimate.assemblies?.reduce(
      (total, assembly) =>
        total +
        (assembly.assemblyItems?.reduce(
          (itemTotal, item) =>
            itemTotal + parsePrice(item.price) * item.quantity,
          0
        ) || 0) *
          assembly.quantity,
      0
    ) || 0;

  const assembliesLaborCost =
    estimate.assemblies?.reduce(
      (total, assembly) =>
        total + parsePrice(assembly.laborPrice) * assembly.quantity,
      0
    ) || 0;

  // Sum up all materials and labor costs
  const materialsCost =
    customItemsMaterialsCost +
    modulesCosts.materialsCost +
    ariaItemsMaterialsCost +
    assembliesMaterialsCost;

  const laborPrice =
    customItemsLaborCost + modulesCosts.laborCost + assembliesLaborCost;

  const totalCost = materialsCost + laborPrice;

  return { materialsCost, laborPrice, totalCost };
};
