// src/Components/Estimating/ModuleOptions/AddModuleOptionModal.jsx

import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Select,
  InputNumber,
  Form,
  Typography,
  Col,
  Row,
  Input,
  Button,
  Card,
  message,
  Tooltip,
  Tag,
  Badge,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useItems } from "../../../Contexts/useSpecificData";
import { addItemsToTable } from "../../../Functions/addAria"; // Import addItemsToTable for adding
import { updateById } from "../../../Functions/updateById"; // Import updateById for editing
import { COLORS, TABLES } from "../../../constants";
import { formatLaborFormula, formatMaterialFormula } from "./formulaFormatters";

const { Text } = Typography;

/**
 * AddModuleOptionModal Component
 *
 * Props:
 * - visible: Boolean indicating if the modal is visible.
 * - onOk: Function to call when the modal is successfully submitted.
 * - onCancel: Function to call when the modal is cancelled.
 * - initialData: Object containing initial data for editing (optional).
 */
function AddModuleOptionModal({
  visible,
  onOk,
  onCancel,
  initialData = null, // Prop for editing
}) {
  const [form] = Form.useForm(); // Initialize Ant Design Form
  const [submitting, setSubmitting] = useState(false); // State to manage loading
  const [materialFormulas, setMaterialFormulas] = useState({}); // State to manage material formulas
  const [laborFormula, setLaborFormula] = useState({
    message: "",
    isWarning: false,
  }); // State to manage labor formula
  const [addOnAfter, setAddOnAfter] = useState([]);

  const { data: items } = useItems();

  // UOM Options including "Covebase LF"
  const uomOptions = [
    "Wall SF",
    "Ceiling SF",
    "Floor SF",
    "Framed LF",
    "Covebase LF",
  ];

  // Category Data with Expense IDs
  const categoryData = useMemo(
    () => ({
      "Wall Insulation": { ExpenseId: "5050113 · Insulation" },
      "Floor Insulation": { ExpenseId: "5050113 · Insulation" },
      "Ceiling Insulation": { ExpenseId: "5050113 · Insulation" },
      Framing: { ExpenseId: "5050112 · Framing" },
      "Wall Finish": { ExpenseId: "5050107 · Drywall" },
      "Ceiling Finish": { ExpenseId: "5050107 · Drywall" },
      "Floor Finish": { ExpenseId: "5050111 · Flooring" },
      "Interior Paint Finish": { ExpenseId: "5050115 · Paint" },
      "Exterior Paint Finish": { ExpenseId: "5050115 · Paint" },
    }),
    []
  );

  // Initialize form fields if editing or adding new
  useEffect(() => {
    if (initialData && visible) {
      form.setFieldsValue({
        category: initialData.category,
        moduleOptionDescription: initialData.moduleOptionDescription,
        laborCost: initialData.laborCost,
        laborUOM: initialData.laborUOM,
        ExpenseId: categoryData[initialData.category]?.ExpenseId, // Set ExpenseId
        materialItems: initialData.materialItems.map((item) => ({
          item: item.item,
          name: item.name,
          coverageArea: item.coverageArea,
          multiplier: item.multiplier || undefined, // Ensure undefined if null
          uom: item.uom || [],
        })),
      });

      // Initialize formulas based on initialData
      const initialFormulas = {};
      initialData.materialItems.forEach((item, index) => {
        const formula = formatMaterialFormula(
          item.uom || [],
          item.multiplier || 0,
          item.coverageArea || 0
        );
        initialFormulas[index] = {
          message: formula.message,
          isWarning: formula.isWarning,
        };
      });
      setMaterialFormulas(initialFormulas);

      // Initialize labor formula
      const laborFormulaResult = formatLaborFormula(
        initialData.laborCost,
        initialData.laborUOM || []
      );
      setLaborFormula(laborFormulaResult);
    } else if (!initialData && visible) {
      // Reset form fields when adding new
      form.resetFields();
      setMaterialFormulas({});
      setLaborFormula({
        message: "Select UOMs and Enter Labor Cost",
        isWarning: true,
      });
      // Remove default ExpenseId setting
      form.setFieldsValue({
        ExpenseId: undefined,
      });
    }
  }, [initialData, visible, form, categoryData]);

  // Handler for Category change
  const handleCategoryChange = (value) => {
    // Update ExpenseId when category changes
    form.setFieldsValue({
      ExpenseId: categoryData[value]?.ExpenseId || "",
    });
  };

  /**
   * Handle changes in Material Items fields to compute formulas.
   *
   * @param {Array} materialItems - Array of material items from the form.
   */
  const handleMaterialItemsChange = (materialItems) => {
    const newFormulas = {};
    const newAddOnAfter = [];

    materialItems.forEach((item, index) => {
      if (item && Array.isArray(item.uom) && item.uom.length > 0) {
        const uomValues = item.uom;

        // Determine addOnAfter value based on selected UOMs
        if (uomValues.every((uom) => uom.includes("SF"))) {
          newAddOnAfter[index] = "SF";
        } else if (uomValues.every((uom) => uom.includes("LF"))) {
          newAddOnAfter[index] = "LF";
        } else {
          newAddOnAfter[index] = "SF/LF";
        }

        const formula = formatMaterialFormula(
          uomValues,
          item.multiplier || 0,
          item.coverageArea
        );
        newFormulas[index] = {
          message: formula.message,
          isWarning: formula.isWarning,
        };
      } else {
        newAddOnAfter[index] = undefined; // Reset if UOM is not selected
        newFormulas[index] = {
          message: "Please enter required fields.",
          isWarning: true,
        };
      }
    });

    setAddOnAfter(newAddOnAfter);
    setMaterialFormulas(newFormulas);
  };

  /**
   * Handle changes in Labor Cost and Labor Applicable UOM to compute formula.
   *
   * @param {Object} changedValues - The changed form values.
   * @param {Object} allValues - All form values.
   */
  const handleLaborFormulaChange = (changedValues, allValues) => {
    const { laborCost, laborUOM } = allValues;
    let message = "";
    let isWarning = false;

    if (
      (laborCost === undefined || laborCost === null) &&
      (!laborUOM || laborUOM.length === 0)
    ) {
      message = "Select UOMs and Enter Labor Cost";
      isWarning = true;
    } else if (laborCost === undefined || laborCost === null) {
      message = "Enter Labor Cost";
      isWarning = true;
    } else if (!laborUOM || laborUOM.length === 0) {
      message = "Select Labor Applicable UOMs";
      isWarning = true;
    } else {
      const formula = formatLaborFormula(laborCost, laborUOM);
      message = formula.message;
      isWarning = formula.isWarning;
    }

    setLaborFormula({ message, isWarning });
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      // Validate all fields
      const values = await form.validateFields();

      setSubmitting(true); // Start loading

      // Prepare the module data
      const moduleData = {
        category: values.category,
        moduleOptionDescription: values.moduleOptionDescription,
        ExpenseId: categoryData[values.category]?.ExpenseId || "", // Include ExpenseId
        laborCost: values.laborCost,
        laborUOM: values.laborUOM,
        laborFormula: laborFormula.message, // Include labor formula message
        materialItems: values.materialItems.map((item, index) => ({
          item: item.item,
          name: item.name,
          coverageArea: item.coverageArea,
          multiplier: item.multiplier || 0, // Default to 0 if not provided
          uom: item.uom, // Include UOM for material items
          formula: materialFormulas[index]?.message || "", // Include the computed formula
        })),
      };

      if (initialData) {
        // If editing, use updateById
        await updateById(TABLES.MODULE_OPTIONS, moduleData, initialData.id);
        message.success(
          `Module Option "${values.moduleOptionDescription}" updated successfully.`
        );
      } else {
        // If adding, use addItemsToTable
        await addItemsToTable(TABLES.MODULE_OPTIONS, moduleData);
        message.success(
          `Module Option "${values.moduleOptionDescription}" added successfully.`
        );
      }

      setSubmitting(false); // End loading

      // Reset form fields only after successful submission
      form.resetFields();
      setMaterialFormulas({});
      setLaborFormula({
        message: "Select UOMs and Enter Labor Cost",
        isWarning: true,
      });

      // Notify parent component
      onOk();
    } catch (errorInfo) {
      if (errorInfo.errorFields) {
        message.error("Please fill in all required fields correctly.");
      } else {
        console.error(errorInfo);
        message.error("Failed to add/update Module Option. Please try again.");
      }
      setSubmitting(false); // End loading in case of error
    }
  };

  return (
    <Modal
      width={1000}
      title={initialData ? "Edit Module Option" : "Add Module Option"}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      okText={initialData ? "Update" : "Add"}
      cancelText="Cancel"
      confirmLoading={submitting} // Link loading state to submitting
      okButtonProps={{ disabled: submitting }} // Disable button while submitting
      cancelButtonProps={{ disabled: submitting }} // Disable Cancel button while submitting
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        name="add_module_option_form"
        initialValues={{
          // Removed default category
          laborCost: 0,
          laborUOM: [],
          materialItems: [],
        }}
        onValuesChange={(changedValues, allValues) => {
          // Handle changes for Labor Formula
          if ("laborCost" in changedValues || "laborUOM" in changedValues) {
            handleLaborFormulaChange(changedValues, allValues);
          }

          // Handle changes for Material Formulas
          if ("materialItems" in changedValues) {
            handleMaterialItemsChange(allValues.materialItems || []);
          }
        }}
      >
        {/* Top Section: Category, Module Option Description, Expense ID */}
        <Row gutter={16}>
          {/* Category Selector */}
          <Col span={8}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: "Category is required." }]}
            >
              <Select
                onChange={handleCategoryChange}
                placeholder="Select a category"
                style={{ width: "100%" }}
                allowClear
              >
                {Object.keys(categoryData).map((cat) => (
                  <Select.Option key={cat} value={cat}>
                    {cat}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* Module Option Description */}
          <Col span={8}>
            <Form.Item
              label="Module Option Description"
              name="moduleOptionDescription"
              rules={[
                {
                  required: true,
                  message: "Module Option Description is required.",
                },
                {
                  whitespace: true,
                  message: "Module Option Description cannot be empty.",
                },
              ]}
            >
              <Input placeholder="Enter module option description" />
            </Form.Item>
          </Col>

          {/* Expense ID */}
          <Col span={8}>
            <Form.Item label="Expense ID" name="ExpenseId">
              <Input
                disabled
                placeholder="Expense ID will be set based on category"
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Labor Costs Section Wrapped in a Card */}
        <Card
          size="small"
          title="Labor Costs"
          style={{ marginBottom: 16 }}
          bordered={false}
        >
          <Row gutter={16}>
            {/* Labor Applicable UOMs */}
            <Col span={8}>
              <Form.Item
                label="Labor Applicable UOM"
                name="laborUOM"
                rules={[
                  {
                    required: true,
                    message: "Select at least one Labor Applicable UOM.",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select applicable UOMs for labor"
                  style={{ width: "100%" }}
                >
                  {uomOptions.map((uom) => (
                    <Select.Option key={uom} value={uom}>
                      {uom}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Labor Cost Input */}
            <Col span={8}>
              <Form.Item
                label="Labor Cost ($)"
                name="laborCost"
                rules={[
                  { required: true, message: "Labor Cost is required." },
                  {
                    type: "number",
                    min: 0.01,
                    message: "Labor Cost must be greater than zero.",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  type="number"
                  placeholder="Enter labor cost"
                  style={{ width: "100%" }}
                  prefix="$"
                  step={0.01}
                />
              </Form.Item>
            </Col>

            {/* Labor Formula Preview */}
            <Col span={8}>
              <Form.Item label="Labor Formula">
                {laborFormula.isWarning ? (
                  <Text style={{ color: "orange" }}>
                    {laborFormula.message}
                  </Text>
                ) : (
                  <Tooltip title="This formula calculates labor cost based on selected UOMs and labor cost.">
                    <Text strong>{laborFormula.message}</Text>
                  </Tooltip>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {/* Material Costs */}
        <Row gutter={16}>
          <Col span={24}>
            <Card size="small" title="Material Costs" bordered={false}>
              {/* Material Items */}
              <Form.List name="materialItems">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        type="inner"
                        style={{ marginBottom: 16 }}
                        title={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Badge
                              count={index + 1}
                              style={{ backgroundColor: COLORS.PRIMARY }}
                            />
                            <span style={{ marginLeft: "8px" }}>
                              Material Item{" "}
                            </span>
                            <span
                              style={{
                                fontStyle: "italic",
                                opacity: 0.8,
                                marginLeft: "4px",
                              }}
                            >
                              {form.getFieldValue([
                                "materialItems",
                                field.name,
                                "name",
                              ])
                                ? ` - ${form.getFieldValue([
                                    "materialItems",
                                    field.name,
                                    "name",
                                  ])}`
                                : ""}
                            </span>
                          </div>
                        }
                        extra={
                          <Tooltip title="Remove this material item">
                            <MinusCircleOutlined
                              style={{ color: "red", fontSize: "16px" }}
                              onClick={() => remove(field.name)}
                            />
                          </Tooltip>
                        }
                        key={field.key}
                      >
                        {/* First Row: Item Selector and Custom Name */}
                        <Row gutter={16} align="middle">
                          {/* Item Selector */}
                          <Col span={16}>
                            <Form.Item
                              label="Item"
                              name={[field.name, "item"]}
                              fieldKey={[field.fieldKey, "item"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Item is required.",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select an item"
                                style={{ width: "100%" }}
                                optionLabelProp="label"
                                filterOption={(input, option) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {items
                                  .sort((a, b) =>
                                    a.itemNumber.localeCompare(b.itemNumber)
                                  )
                                  .map((itm) => (
                                    <Select.Option
                                      key={itm.id}
                                      value={itm.id}
                                      label={`${itm.itemNumber} ${itm.itemDescription}`}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        <Tag style={{ marginRight: 8 }}>
                                          {itm.itemNumber}
                                        </Tag>
                                        <span
                                          style={{
                                            display: "inline-block",
                                            wordBreak: "break-word",
                                            maxWidth: "400px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {itm.itemDescription}
                                        </span>
                                      </div>
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          {/* Custom Name as Regular Input */}
                          <Col span={8}>
                            <Form.Item
                              label="Custom Name"
                              name={[field.name, "name"]}
                              fieldKey={[field.fieldKey, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Custom Name is required.",
                                },
                                {
                                  whitespace: true,
                                  message: "Custom Name cannot be empty.",
                                },
                              ]}
                            >
                              <Input placeholder="Stud, Track, etc." />
                            </Form.Item>
                          </Col>
                        </Row>

                        {/* Second Row: UOM, Coverage Area, Multiplier, Material Formula */}
                        <Row gutter={16} align="middle">
                          {/* Material Applicable UOM */}
                          <Col span={8}>
                            <Form.Item
                              label="Material Applicable UOM"
                              name={[field.name, "uom"]}
                              fieldKey={[field.fieldKey, "uom"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Select at least one UOM.",
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                placeholder="Select UOMs for material"
                                style={{ width: "100%" }}
                              >
                                {uomOptions.map((uom) => (
                                  <Select.Option key={uom} value={uom}>
                                    {uom}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          {/* Coverage Area */}
                          <Col span={4}>
                            <Form.Item
                              label="Coverage Area"
                              name={[field.name, "coverageArea"]}
                              fieldKey={[field.fieldKey, "coverageArea"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Coverage Area is required.",
                                },
                                {
                                  type: "number",
                                  min: 0.01,
                                  message:
                                    "Coverage Area must be greater than zero.",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                type="number"
                                placeholder="Area"
                                style={{ width: "100%" }}
                                addonAfter={addOnAfter[field.name] || "Area"}
                              />
                            </Form.Item>
                          </Col>

                          {/* Multiplier (Optional) */}
                          <Col span={4}>
                            <Form.Item
                              label="Multiplier"
                              name={[field.name, "multiplier"]}
                              fieldKey={[field.fieldKey, "multiplier"]}
                              rules={[
                                {
                                  type: "number",
                                  min: 0,
                                  message: "Multiplier cannot be negative.",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                placeholder="0"
                                type="number"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>

                          {/* Material Formula */}
                          <Col span={8}>
                            <Form.Item label="Material Formula">
                              {materialFormulas[index]?.isWarning ? (
                                <Text style={{ color: "orange" }}>
                                  {materialFormulas[index].message}
                                </Text>
                              ) : (
                                <Tooltip title="Formula: (UOM1 + UOM2) * Multiplier / Coverage Area">
                                  <Text strong>
                                    {materialFormulas[index]?.message ||
                                      "Please select UOMs and enter Coverage Area."}
                                  </Text>
                                </Tooltip>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    ))}

                    {/* Button to Add More Material Items */}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            item: undefined,
                            name: "",
                            coverageArea: undefined,
                            multiplier: 0,
                            uom: [],
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Material Item
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddModuleOptionModal;
