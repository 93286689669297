// src/api/generatePdf.js

import axios from "axios";

// Define the API base URL and the specific endpoint for generating PDFs
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/puppeteer/generatePdf";

/**
 * Generates a PDF from provided HTML content and uploads it to S3.
 * @param {string} htmlContent - The HTML content to convert into a PDF.
 * @param {string} [filename] - Optional. The desired filename for the generated PDF.
 * @returns {Promise<Object>} - Returns an object containing a success message and the PDF location URL.
 * @throws {Error} - Throws an error if the PDF generation fails.
 */
export async function generatePdf(htmlContent, filename) {
  console.log("Generating PDF with the following details:");
  console.log("HTML Content:", htmlContent);
  console.log("Filename:", filename);

  try {
    // Prepare the payload for the POST request
    const payload = {
      html: htmlContent,
      filename: filename, // This can be undefined or null if not provided
    };

    // Make the POST request to the generatePdf endpoint
    const response = await axios.post(API_BASE_URL, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Log and return the successful response data
    console.log("PDF generation successful:", response.data);
    return response.data;
  } catch (error) {
    // Handle errors and provide meaningful messages
    console.error("Error generating PDF:", error);

    // Check if the error has a response from the server
    if (error.response) {
      throw new Error(
        `Error generating PDF: ${error.response.status} ${error.response.statusText}`
      );
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error("Error generating PDF: No response from the server.");
    } else {
      // Something happened in setting up the request
      throw new Error(`Error generating PDF: ${error.message}`);
    }
  }
}
