import React, { useState } from "react";
import { Table, Row, Col, Badge, Tag } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { usePurchaseOrders, useVendors } from "../../Contexts/useSpecificData";
import { COLORS } from "../../constants";
import { getVendorName } from "../../Formatters/getVendorName";
import { Link } from "react-router-dom";
import { SearchInput } from "../../Styled/SearchInput";

const EditPurchaseOrderTable = () => {
  const { data: purchaseOrders, loading: purchaseOrdersLoading } =
    usePurchaseOrders();
  const { data: vendors } = useVendors();
  const [filteredInfo, setFilteredInfo] = useState({});
  // Remove sortedInfo if not needed
  // const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    // Remove sortedInfo if not needed
    // setSortedInfo(sorter);
  };

  // Handle row expansion on double-click
  const handleRowDoubleClick = (record) => {
    const key = record.id;
    setExpandedRowKeys((prevExpandedRowKeys) =>
      prevExpandedRowKeys.includes(key)
        ? prevExpandedRowKeys.filter((k) => k !== key)
        : [...prevExpandedRowKeys, key]
    );
  };

  const filteredData = purchaseOrders
    .filter((order) => {
      const allLinesReceived = order.lines.every(
        (line) => line.qtyOrdered === line.qtyReceived
      );
      if (allLinesReceived) {
        order.poStatus = "Closed";
      }
      return order.poStatus !== "Closed"; // Exclude closed orders from the table
    })
    .filter(
      (order) =>
        (filteredInfo.buyer
          ? filteredInfo.buyer.includes(order.buyer)
          : true) &&
        (filteredInfo.poStatus
          ? filteredInfo.poStatus.includes(order.poStatus)
          : true) &&
        (filteredInfo.vendor
          ? filteredInfo.vendor.includes(order.vendor)
          : true) &&
        (order.poNumber.toLowerCase().includes(searchText.toLowerCase()) ||
          getVendorName(order.vendor, vendors)
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          (order.groupNumber &&
            (`group ${order.groupNumber}`
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
              order.groupNumber
                .toLowerCase()
                .includes(searchText.replace(/^group\s+/i, "").toLowerCase()) ||
              searchText.trim().toLowerCase() === "group")))
    );
  // Removed the .sort() method

  const uniqueBuyers = [...new Set(filteredData.map((order) => order.buyer))];
  const uniqueStatuses = [
    ...new Set(filteredData.map((order) => order.poStatus)),
  ];
  const uniqueVendors = [...new Set(filteredData.map((order) => order.vendor))];

  const buyerFilters = uniqueBuyers.map((buyer) => ({
    text: buyer,
    value: buyer,
  }));
  const statusFilters = uniqueStatuses.map((status) => ({
    text: status,
    value: status,
  }));
  const vendorFilters = uniqueVendors.map((vendorId) => ({
    text: getVendorName(vendorId, vendors),
    value: vendorId,
  }));

  // Helper function to extract numerical part from PO Number
  const extractPoNumber = (poNumber) => {
    const match = poNumber.match(/-(\d+)$/);
    return match ? parseInt(match[1], 10) : Number.MAX_SAFE_INTEGER; // Push malformed PO Numbers to the end
  };

  const columns = [
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) =>
        extractPoNumber(a.poNumber) - extractPoNumber(b.poNumber),
      defaultSortOrder: "descend", // Sets initial sort order to ascending
      // Remove sortOrder to allow defaultSortOrder to take effect
      // sortOrder: sortedInfo.columnKey === "poNumber" && sortedInfo.order,
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Badge
            count={record.lines.length}
            style={{ backgroundColor: COLORS.PRIMARY, marginRight: 8 }}
          />
          <Link to={`/purchasing/purchase/edit-purchase-orders/${record.id}`}>
            {text}
          </Link>
          {record.groupNumber && (
            <Tag color="blue" style={{ marginLeft: 8, fontSize: 10 }}>
              Group {record.groupNumber}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (vendorId) => getVendorName(vendorId, vendors),
      filters: vendorFilters,
      filteredValue: filteredInfo.vendor || null,
      onFilter: (value, record) => record.vendor === value,
    },
    {
      title: "Ordered Date",
      dataIndex: "orderedDate",
      key: "orderedDate",
      sorter: (a, b) => new Date(a.orderedDate) - new Date(b.orderedDate),
      // Remove sortOrder if not needed
      // sortOrder: sortedInfo.columnKey === "orderedDate" && sortedInfo.order,

    },
    {
      title: "Required Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      filters: buyerFilters,
      filteredValue: filteredInfo.buyer || null,
      onFilter: (value, record) => record.buyer === value,
    },
    {
      title: "PO Status",
      dataIndex: "poStatus",
      key: "poStatus",
      filters: statusFilters,
      filteredValue: filteredInfo.poStatus || null,
      onFilter: (value, record) => record.poStatus === value,
    },
  ];

  const expandedRowRender = (record) => {
    const lineColumns = [
      {
        title: "Line Number",
        dataIndex: "lineNumber",
        key: "lineNumber",
        className: "expanded-row-header", // Custom class for expanded row header
      },
      {
        title: "Item Description",
        dataIndex: "itemDescription",
        key: "itemDescription",
        className: "expanded-row-header", // Custom class for expanded row header
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        className: "expanded-row-header", // Custom class for expanded row header
      },
      {
        title: "Item Price",
        dataIndex: "itemPrice",
        key: "itemPrice",
        render: (price) => `$${price.toFixed(2)}`,
        className: "expanded-row-header", // Custom class for expanded row header
      },
      {
        title: "Qty Ordered",
        dataIndex: "qtyOrdered",
        key: "qtyOrdered",
        className: "expanded-row-header", // Custom class for expanded row header
      },
      {
        title: "Qty Received",
        dataIndex: "qtyReceived",
        key: "qtyReceived",
        className: "expanded-row-header", // Custom class for expanded row header
      },
      {
        title: "Received Date",
        dataIndex: "receivedDate",
        key: "receivedDate",
        render: (receivedDate) =>
          receivedDate ? (
            receivedDate
          ) : (
            <StopOutlined style={{ color: COLORS.ERROR }} />
          ),
        className: "expanded-row-header", // Custom class for expanded row header
      },
      {
        title: "Line Status",
        dataIndex: "lineStatus",
        key: "lineStatus",
        className: "expanded-row-header", // Custom class for expanded row header
      },
    ];

    const sortedLines = [...record.lines].sort((a, b) => {
      const numA = parseInt(a.lineNumber, 10);
      const numB = parseInt(b.lineNumber, 10);
      return numA - numB;
    });

    return (
      <Table
        columns={lineColumns}
        dataSource={sortedLines}
        pagination={false}
        rowKey="lineNumber"
        size="small"
        className="small-table"
        rowClassName="expanded-row" // Custom class for expanded row content
      />
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <SearchInput
          placeholder="Search PO Number or Vendor"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: 16 }}
        />
      </Col>
      <Col span={24}>
        <Table
          loading={purchaseOrdersLoading}
          columns={columns}
          expandable={{
            expandedRowRender,
            expandedRowKeys,
            onExpand: (expanded, record) => {
              const keys = expanded
                ? [...expandedRowKeys, record.id]
                : expandedRowKeys.filter((key) => key !== record.id);
              setExpandedRowKeys(keys);
            },
          }}
          dataSource={filteredData}
          rowKey="id"
          size="small"
          pagination={{ defaultPageSize: 10 }}
          onChange={handleChange}
          rowClassName={
            (record, index) =>
              expandedRowKeys.includes(record.id) ? "expanded-header" : "" // Add class when row is expanded
          }
          className="small-table"
          onRow={(record) => ({
            onDoubleClick: () => handleRowDoubleClick(record),
          })}
        />
      </Col>
    </Row>
  );
};

export default EditPurchaseOrderTable;
