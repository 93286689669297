// AddCustomerModal.js
import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Button, message } from "antd";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";

const AddCustomerModal = ({
  children,
  customer,
  onAddSuccess,
  title,
  visible: propVisible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  // Determine if the component is controlled externally
  const isControlled = propVisible !== undefined;
  const modalVisible = isControlled ? propVisible : visible;

  useEffect(() => {
    if (modalVisible) {
      if (customer) {
        // Set form fields with existing customer data for editing
        form.setFieldsValue({
          companyName: customer.companyName,
          contactFirstName: customer.contactFirstName,
          contactLastName: customer.contactLastName,
          contactEmail: customer.contactEmail,
          contactPhoneNumber: customer.contactPhoneNumber,
        });
      } else {
        // Reset form fields for adding a new customer
        form.resetFields();
      }
    }
  }, [customer, form, modalVisible]);

  const handleOk = async () => {
    setLoading(true);
    try {
      // Validate form fields
      const values = await form.validateFields();
      console.log("Submitted Values:", values);

      if (customer && customer.id) {
        // Update existing customer
        await updateById(TABLES.CUSTOMERS, values, customer.id);
        values.id = customer.id; // Ensure the ID is set for onAddSuccess
        message.success("Customer updated successfully!");
      } else {
        // Add new customer
        const newCustomer = await addItemsToTable(TABLES.CUSTOMERS, values);
        values.id = newCustomer.id;
        message.success("Customer added successfully!");
      }

      onAddSuccess(values);
      setLoading(false);
      if (!isControlled) {
        setVisible(false);
      }
    } catch (error) {
      console.error("Validation Failed:", error);
      message.error("Please fill in all required fields.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    if (onCancel) {
      onCancel();
    }
    if (!isControlled) {
      setVisible(false);
    }
  };

  const showModal = () => {
    if (!isControlled) {
      setVisible(true);
    }
  };

  return (
    <>
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, { onClick: showModal })
        )}
      <Modal
        title={title || (customer ? "Edit Customer" : "Add New Customer")}
        visible={modalVisible}
        onOk={handleOk}
        width={600}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {customer ? "Save Changes" : "Add Customer"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please enter the company name",
              },
            ]}
          >
            <Input disabled={!!customer} />
          </Form.Item>
          <Form.Item
            name="contactFirstName"
            label="Contact First Name"
            rules={[{ required: true, message: "Please enter the first name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactLastName"
            label="Contact Last Name"
            rules={[{ required: true, message: "Please enter the last name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactEmail"
            label="Contact Email"
            rules={[
              { required: true, message: "Please enter the email" },
              { type: "email", message: "Enter a valid email" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactPhoneNumber"
            label="Contact Phone Number"
            rules={[
              {
                required: true,
                message: "Please enter the phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
