import React, { useState, useEffect } from "react";
import { Card, Table, Input, Radio } from "antd";
import dayjs from "dayjs";
import { getVendorName } from "../../Formatters/getVendorName";
import PurchaseOrderModal from "../../Components/Dashboard/PurchaseOrderModal";
import { getProjectName } from "../../Formatters/getProjectName";
import { getFirstNameFromEmail } from "../../Formatters/getNameFromEmail";
import { SearchInput } from "../../Styled/SearchInput";

const LateTableCard = ({
  purchaseOrders = [],
  vendors = [],
  projects = [],
}) => {
  const [viewMode, setViewMode] = useState("PO");
  const [filteredPOs, setFilteredPOs] = useState([]);
  const [filteredLineItems, setFilteredLineItems] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // New state variables to hold the original unfiltered data
  const [allLatePOs, setAllLatePOs] = useState([]);
  const [allLateLineItems, setAllLateLineItems] = useState([]);

  // State variables to store search terms
  const [searchTermPOs, setSearchTermPOs] = useState("");
  const [searchTermLineItems, setSearchTermLineItems] = useState("");

  useEffect(() => {
    filterLatePOs();
  }, [purchaseOrders]);

  const filterLatePOs = () => {
    if (!Array.isArray(purchaseOrders) || purchaseOrders.length === 0) {
      setAllLatePOs([]);
      setAllLateLineItems([]);
      setFilteredPOs([]);
      setFilteredLineItems([]);
      return;
    }

    const now = dayjs();

    const latePOs = purchaseOrders.filter((po) => {
      if (!po.requiredDate) return false;

      const overdueDays = now.diff(dayjs(po.requiredDate), "day");
      return (
        po.lines &&
        po.lines.some(
          (line) => line.qtyOrdered > line.qtyReceived && overdueDays > 0
        )
      );
    });

    const sortedLatePOs = latePOs.sort((a, b) =>
      dayjs(a.requiredDate).diff(dayjs(b.requiredDate))
    );

    // Set the original datasets
    setAllLatePOs(sortedLatePOs);
    setAllLateLineItems(getLineItems(sortedLatePOs));

    // Initialize the filtered datasets with the original data
    setFilteredPOs(sortedLatePOs);
    setFilteredLineItems(getLineItems(sortedLatePOs));
  };

  // Apply search filter for POs
  useEffect(() => {
    const value = searchTermPOs.toLowerCase();
    if (value) {
      const filtered = allLatePOs.filter((po) =>
        [
          po.poNumber,
          getVendorName(po.vendor, vendors),
          getProjectName(po.projectNumber, projects),
          getFirstNameFromEmail(po.buyer),
        ]
          .filter(Boolean)
          .some((field) => field.toLowerCase().includes(value))
      );
      setFilteredPOs(filtered);
    } else {
      setFilteredPOs(allLatePOs);
    }
  }, [searchTermPOs, allLatePOs, vendors, projects]);

  // Apply search filter for LineItems
  useEffect(() => {
    const value = searchTermLineItems.toLowerCase();
    if (value) {
      const filtered = allLateLineItems.filter((item) =>
        [
          item.poNumber,
          item.item, // Item Description
          getVendorName(item.vendor, vendors),
          getProjectName(item.projectNumber, projects),
          getFirstNameFromEmail(item.buyer),
        ]
          .filter(Boolean)
          .some((field) => field.toLowerCase().includes(value))
      );
      setFilteredLineItems(filtered);
    } else {
      setFilteredLineItems(allLateLineItems);
    }
  }, [searchTermLineItems, allLateLineItems, vendors, projects]);

  const handleSearchPOs = (e) => {
    setSearchTermPOs(e.target.value);
  };

  const handleSearchLineItems = (e) => {
    setSearchTermLineItems(e.target.value);
  };

  const handleViewModeChange = (e) => {
    const newViewMode = e.target.value;
    setViewMode(newViewMode);

    // Reset the search term of the view you're switching from
    if (newViewMode === "PO") {
      setSearchTermLineItems("");
    } else {
      setSearchTermPOs("");
    }
  };

  const getLineItems = (pos) => {
    if (!Array.isArray(pos)) return [];
    return pos.reduce((acc, po) => {
      if (po.lines && Array.isArray(po.lines)) {
        po.lines.forEach((line, index) => {
          const overdueDays = dayjs().diff(dayjs(po.requiredDate), "day");
          if (line.qtyOrdered > line.qtyReceived && overdueDays > 0) {
            acc.push({
              key: `${po.poNumber}-${line.itemId}-${index}`, // Ensure unique key
              itemId: line.itemId,
              poNumber: po.poNumber,
              item: line.itemDescription,
              quantity: `${line.qtyReceived || 0} / ${line.qtyOrdered}`,
              vendor: po.vendor,
              requiredDate: po.requiredDate,
              projectNumber: po.projectNumber,
              lateBy: overdueDays,
              buyer: po.buyer,
            });
          }
        });
      }
      return acc;
    }, []);
  };

  const columnsPO = [
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
    },
    {
      title: "Late By",
      dataIndex: "lateBy",
      key: "lateBy",
      render: (_, record) => {
        const overdueDays = dayjs().diff(dayjs(record.requiredDate), "day");
        return overdueDays > 0
          ? `${overdueDays} ${overdueDays === 1 ? "Day" : "Days"}`
          : "On Time";
      },
      sorter: (a, b) => {
        const overdueDaysA = dayjs().diff(dayjs(a.requiredDate), "day");
        const overdueDaysB = dayjs().diff(dayjs(b.requiredDate), "day");
        return overdueDaysB - overdueDaysA;
      },
    },
    {
      title: "Due Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      render: (text) => (text ? dayjs(text).format("MM/DD/YYYY") : "N/A"),
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
      defaultSortOrder: "descend",
    },
    {
      title: "Line Items Received",
      dataIndex: "lineItemsReceived",
      key: "lineItemsReceived",
      render: (_, record) => {
        const totalItems = record.lines.length;
        const receivedItems = record.lines.filter(
          (line) => line.qtyReceived >= line.qtyOrdered
        ).length;
        return `${receivedItems} / ${totalItems}`;
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => getVendorName(text, vendors),
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      render: (text) => getProjectName(text, projects),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (text) => getFirstNameFromEmail(text),
    },
  ];

  const columnsLineItems = [
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
    },
    {
      title: "Item Description",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Late By",
      dataIndex: "lateBy",
      key: "lateBy",
      render: (_, record) => {
        const overdueDays = dayjs().diff(dayjs(record.requiredDate), "day");
        return overdueDays > 0
          ? `${overdueDays} ${overdueDays === 1 ? "Day" : "Days"}`
          : "On Time";
      },
      sorter: (a, b) => {
        const overdueDaysA = dayjs().diff(dayjs(a.requiredDate), "day");
        const overdueDaysB = dayjs().diff(dayjs(b.requiredDate), "day");
        return overdueDaysB - overdueDaysA;
      },
    },
    {
      title: "Due Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      render: (text) => (text ? dayjs(text).format("MM/DD/YYYY") : "N/A"),
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
      defaultSortOrder: "descend",
    },
    {
      title: "Qty Received",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => getVendorName(text, vendors),
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      render: (text) => getProjectName(text, projects),
    },

    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (text) => getFirstNameFromEmail(text),
    },
  ];

  const onRowClick = (record) => {
    // Find the full PO object
    const selectedPO = allLatePOs.find((po) => po.poNumber === record.poNumber);
    setSelectedRecord(selectedPO);
    setModalVisible(true);
  };

  return (
    <>
      <Card
        title={
          <>
            <div>
              <Radio.Group
                style={{ marginRight: 8 }}
                value={viewMode}
                onChange={handleViewModeChange}
              >
                <Radio.Button value="PO">{allLatePOs.length} POs</Radio.Button>
                <Radio.Button value="LineItems">
                  {allLateLineItems.length} Line Items
                </Radio.Button>
              </Radio.Group>
              Late
            </div>
          </>
        }
      >
        {viewMode === "PO" && (
          <>
            <SearchInput
              placeholder="Search POs"
              onChange={handleSearchPOs}
              value={searchTermPOs}
              style={{ marginBottom: 16 }}
              resultsLength={filteredPOs.length}
            />
            <Table
              dataSource={filteredPOs}
              columns={columnsPO}
              rowKey="poNumber"
              pagination={false}
              rowClassName="clickable-row"
              size="small"
              className="small-table"
              scroll={{ y: 300 }}
              onRow={(record) => ({
                onClick: () => onRowClick(record),
              })}
            />
          </>
        )}

        {viewMode === "LineItems" && (
          <>
            <SearchInput
              placeholder="Search"
              onChange={handleSearchLineItems}
              value={searchTermLineItems}
              style={{ marginBottom: 16 }}
              resultsLength={filteredLineItems.length}
            />
            <Table
              dataSource={filteredLineItems}
              columns={columnsLineItems}
              rowKey="key"
              pagination={false}
              size="small"
              className="small-table"
              scroll={{ y: 300 }}
            />
          </>
        )}
      </Card>

      {selectedRecord && (
        <PurchaseOrderModal
          purchaseOrder={selectedRecord}
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          vendors={vendors}
          projects={projects}
        />
      )}
    </>
  );
};

export default LateTableCard;
