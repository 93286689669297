export function incrementPONumber(poNumber, increment = 1) {
  const prefixMatch = poNumber.match(/^([A-Z]+-)/); // Match the prefix (e.g., "A-")
  const numericPartMatch = poNumber.match(/(\d+)$/); // Match the numeric part (e.g., "1234")

  if (!prefixMatch || !numericPartMatch) {
    throw new Error("Invalid PO Number format");
  }

  const prefix = prefixMatch[1]; // Extract the prefix
  const currentNumber = parseInt(numericPartMatch[1], 10); // Parse the numeric part

  const newNumber = currentNumber + increment; // Increment the numeric part
  const newPONumber = `${prefix}${newNumber}`; // Combine prefix and incremented number

  return newPONumber;
}

