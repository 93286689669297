import React from "react";
import { Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { COLORS } from "../constants";

export const SearchInput = ({
  onChange,
  placeholder,
  resultsLength,
  value,
  ...restProps
}) => {
  if (!placeholder) {
    placeholder = "Search";
  }

  if (resultsLength === undefined) {
    // Render Input without enterButton
    return (
      <Input
        onChange={onChange}
        style={{ width: "100%" }}
        prefix={<SearchOutlined style={{ marginRight: 8 }} />}
        placeholder={placeholder}
        value={value}
        {...restProps}
      />
    );
  } else {
    // Render Input.Search with enterButton
    return (
      <Input.Search
        enterButton={
          <Button
            style={{
              background: COLORS.PRIMARY,
              color: "white",
              fontStyle: "italic",
              opacity: 0.8,
            }}
          >
            {`${resultsLength} Results`}
          </Button>
        }
        onChange={onChange}
        style={{ width: "100%" }}
        prefix={<SearchOutlined style={{ marginRight: 8 }} />}
        placeholder={placeholder}
        value={value}
        {...restProps}
      />
    );
  }
};
