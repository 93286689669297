import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Table, Tag, Input, Modal, Button, Form } from "antd";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
  useTransactions,
} from "../../Contexts/useSpecificData";
import { Loader } from "../../Styled/Loader";
import { SearchInput } from "../../Styled/SearchInput";
import HeaderText from "../../Styled/HeaderText";
import { formatMoney } from "../../Formatters/helpers";
import { TABLES } from "../../constants";
import { updateById } from "../../Functions/updateById";

const Transactions = () => {
  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();
  const { data: transactions } = useTransactions();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [customDataValue, setCustomDataValue] = useState("");
  const [searchText, setSearchText] = useState("");

  // Compute dataSource using useMemo
  const dataSource = useMemo(() => {
    if (transactions) {
      return transactions.map((transaction) => ({
        ...transaction,
        key: transaction.id,
        date: transaction.user_transaction_time,
        name: `${transaction?.card_holder?.first_name} ${transaction?.card_holder?.last_name}`,
        amount: transaction.amount,
        merchant_name: transaction.merchant_name,
        sk_category_name: transaction.sk_category_name,
        synced_at: transaction.synced_at,
        customData: transaction.customData || "",
      }));
    }
    return [];
  }, [transactions]);

  // Filter dataSource based on searchText
  const filteredDataSource = useMemo(() => {
    if (!searchText) {
      return dataSource;
    }
    return dataSource.filter((item) => {
      const searchString = searchText.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchString) ||
        item.merchant_name.toLowerCase().includes(searchString) ||
        item.sk_category_name.toLowerCase().includes(searchString) ||
        item.customData.toLowerCase().includes(searchString)
      );
    });
  }, [dataSource, searchText]);

  if (poLoading) {
    return <Loader />;
  }

  // Function to show the modal
  const showModal = (record) => {
    setEditingTransaction(record);
    setCustomDataValue(record.customData || "");
    setIsModalVisible(true);
  };

  // Function to handle saving the custom data
  const handleOk = async () => {
    try {
      await updateById(
        TABLES.TRANSACTIONS,
        { customData: customDataValue },
        editingTransaction.id
      );
      // Optionally update local transactions data if possible
      setIsModalVisible(false);
      setEditingTransaction(null);
    } catch (err) {
      console.error("Update failed:", err);
    }
  };

  // Function to handle modal cancellation
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingTransaction(null);
  };

  // Define table columns
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (date) =>
        date ? new Date(date).toLocaleDateString("en-US") : "N/A",
    },
    {
      title: "Card Holder Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Merchant Name",
      dataIndex: "merchant_name",
      key: "merchant_name",
      sorter: (a, b) => a.merchant_name.localeCompare(b.merchant_name),
    },
    {
      title: "Category",
      dataIndex: "sk_category_name",
      key: "sk_category_name",
      sorter: (a, b) => a.sk_category_name.localeCompare(b.sk_category_name),
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (amount) => <Tag color="green">{formatMoney(amount)}</Tag>,
    },
    {
      title: "Synced At",
      dataIndex: "synced_at",
      key: "synced_at",
      sorter: (a, b) => new Date(a.synced_at) - new Date(b.synced_at),
      render: (synced_at) =>
        synced_at ? new Date(synced_at).toLocaleString("en-US") : "N/A",
    },
    {
      title: "Custom Data",
      dataIndex: "customData",
      key: "customData",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => showModal(record)}>Edit</Button>
      ),
    },
  ];

  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <HeaderText text={"Transactions"} />
        </Col>
        <Col span={8}></Col>
        <Col span={8}></Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <SearchInput
            placeholder="Search Transactions"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Table
            dataSource={filteredDataSource}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />
        </Col>
      </Row>

      {/* Modal for editing transaction */}
      <Modal
        title="Edit Transaction"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {editingTransaction && (
          <>
            <p>
              <strong>Date:</strong>{" "}
              {editingTransaction.date
                ? new Date(editingTransaction.date).toLocaleDateString("en-US")
                : "N/A"}
            </p>
            <p>
              <strong>Card Holder Name:</strong> {editingTransaction.name}
            </p>
            <p>
              <strong>Merchant Name:</strong> {editingTransaction.merchant_name}
            </p>
            <p>
              <strong>Category:</strong> {editingTransaction.sk_category_name}
            </p>
            <p>
              <strong>Amount ($):</strong>{" "}
              {formatMoney(editingTransaction.amount)}
            </p>
            <p>
              <strong>Synced At:</strong>{" "}
              {editingTransaction.synced_at
                ? new Date(editingTransaction.synced_at).toLocaleString("en-US")
                : "N/A"}
            </p>
            <Form>
              <Form.Item label="Custom Data">
                <Input
                  value={customDataValue}
                  onChange={(e) => setCustomDataValue(e.target.value)}
                />
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Transactions;
